import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import MultipleSelect from '../../components/multi-select/multi-select'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'
import useHttpInactive from '../../shared/hooks/useHttpInactive'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import {
  CATEGORIES_VARIETIES_QUERY,
  SIZE_QUERY,
  VARIETIES_COLORS_QUERY,
  VARIETIES_DEFECTS_QUERY,
  VARIETIES_SAMPLE_TYPE_QUERY,
  VARIETIES_SIZES_QUERY,
  VARIETIES_STEM_CONDITIONS_QUERY,
  VARIETY_QUERY,
} from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { OPERATOR_ROLE, SUPER_ADMIN_ROLE, QUALITY_CONTROL_MANAGER_ROLE } from '../../shared/constants/roles'
import useVerietyQuery from '../../shared/hooks/useVerietyQuery'
import useColorQuery from '../../shared/hooks/useColorQuery'
import useDefectQuery from '../../shared/hooks/useDefectQuery'
import useSampleTypeQuery from '../../shared/hooks/useSampleTypeQuery'
import useCategoryQuery from '../../shared/hooks/useCategoryQuery'
import useStemConditionQuery from '../../shared/hooks/useStemConditionQuery'

const Variety = ({ userEmail, role }) => {
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [openDefectsDialog, setDefectsOpen] = useState(false)
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false)
  const [openSizesDialog, setSizesOpen] = useState(false)
  const [openColorsDialog, setOpenColorsDialog] = useState(false)
  const [openStemDialog, setOpenStemDialog] = useState(false)
  const [openSampleTypeDialog, setOpenSampleTypeDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [showActive, setShowActive] = useState(true)
  const [selectedDefects, setSelectedDefects] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedSizes, setSelectedSizes] = useState([])
  const [sizes, setSizes] = useState([])
  const [selectedColors, setSelectedColors] = useState([])
  const [selectedStemConditions, setSelectedStemConditions] = useState([])
  const [selectedSampleTypes, setSelectedSampleTypes] = useState([])
  const { executeInactive, loadingInactive } = useHttpInactive(VARIETY_QUERY)
  const verietyQuery = useVerietyQuery({ showActive, startRowIndex: tableConf.page - 1, pageSize: 10000 })
  const colorQuery = useColorQuery()
  const defectQuery = useDefectQuery()
  const sampleTypeQuery = useSampleTypeQuery()
  const categoryQuery = useCategoryQuery()
  const stemConditionQuery = useStemConditionQuery()
  // columns definition for table
  const columns = [
    ...BASE_TABLE_COLUMNS,
    {
      field: 'defects',
      headerName: 'Defects',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setDefectsOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Defects
        </Button>
      ),
    },
    {
      field: 'sizes',
      headerName: 'Sizes',
      flex: 1,
      minWidth: 115,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setSizesOpen(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Sizes
        </Button>
      ),
    },
    {
      field: 'colors',
      headerName: 'Colors',
      flex: 1,
      minWidth: 140,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenColorsDialog(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Colors
        </Button>
      ),
    },
    {
      field: 'categories',
      headerName: 'Categories',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenCategoryDialog(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Categories
        </Button>
      ),
    },
    {
      field: 'stemcondition',
      headerName: 'Stem Condition',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenStemDialog(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Stem Condition
        </Button>
      ),
    },
    {
      field: 'sampletype',
      headerName: 'Sample Type',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id })
            setOpenSampleTypeDialog(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Sample Type
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      hide: role !== SUPER_ADMIN_ROLE && role !== QUALITY_CONTROL_MANAGER_ROLE,
      renderCell: (params) => {
        return ActionButtons(params.row, handleClickOpen, handleClickDelOpen)
      },
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_VARIETY_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setLoading(false)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      verietyQuery.refetch()

      defectQuery.refetch()

      const url = `${SIZE_QUERY}?startRowIndex=0&pageSize=10000&removeOverUnderSizeOptions=false`
      const response = await RequestService.Get(url, history)
      setSizes(response.data)

      colorQuery.refetch()

      stemConditionQuery.refetch()

      categoryQuery.refetch()

      sampleTypeQuery.refetch()

      setLoading(false)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)
    try {
      let url = `${VARIETY_QUERY}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

      const { id } = response.data

      const varietiesDefectsPayload = buildPayload(id, selectedDefects, null)
      url = `${VARIETIES_DEFECTS_QUERY}`
      await RequestService.Put(url, history, varietiesDefectsPayload, { isBlankInstance: true })

      const varietiesSizesPayload = buildPayload(id, selectedSizes, null)
      url = `${VARIETIES_SIZES_QUERY}`
      await RequestService.Put(url, history, varietiesSizesPayload, { isBlankInstance: true })

      const varietiesColorsPayload = buildPayload(id, selectedColors, null)
      url = `${VARIETIES_COLORS_QUERY}`
      await RequestService.Put(url, history, varietiesColorsPayload, { isBlankInstance: true })

      const varietiesStemConditionsPayload = buildPayload(id, selectedStemConditions, null)
      url = `${VARIETIES_STEM_CONDITIONS_QUERY}`
      await RequestService.Put(url, history, varietiesStemConditionsPayload, { isBlankInstance: true })

      const varietiesSampleTypesPayload = buildPayload(id, selectedSampleTypes, null)
      url = `${VARIETIES_SAMPLE_TYPE_QUERY}`
      await RequestService.Put(url, history, varietiesSampleTypesPayload, { isBlankInstance: true })

      const varietiesCategoriesPayload = buildPayload(
        id,
        selectedCategories,
        null,
        true,
        'id_category_type',
        'id_category_type'
      )
      url = `${CATEGORIES_VARIETIES_QUERY}?filterByFirst=false`
      await RequestService.Put(url, history, varietiesCategoriesPayload, { isBlankInstance: true })

      setTableConf({ ...tableConf })
      setSelectedDefects([])
      setSelectedCategories([])
      setSelectedSizes([])
      setSelectedColors([])
      setSelectedStemConditions([])
      setSelectedSampleTypes([])
      setLoading(false)
    } catch (error) {
      onError(error)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      try {
        const varietiesDefects = await getManyToManyData(
          `${VARIETIES_DEFECTS_QUERY}?id=${params.id}`,
          history,
          defectQuery.data,
          true
        )
        const varietiesSizes = await getManyToManyData(`${VARIETIES_SIZES_QUERY}?id=${params.id}`, history, sizes, true)
        const varietiesCategories = await getManyToManyData(
          `${CATEGORIES_VARIETIES_QUERY}?id=${params.id}&filterByFirst=false`,
          history,
          categoryQuery.data,
          false
        )
        const varietiesColors = await getManyToManyData(
          `${VARIETIES_COLORS_QUERY}?id=${params.id}`,
          history,
          colorQuery.data,
          true
        )
        const varietiesStemConditions = await getManyToManyData(
          `${VARIETIES_STEM_CONDITIONS_QUERY}?id=${params.id}`,
          history,
          stemConditionQuery.data,
          true
        )
        const varietiesSampleTypes = await getManyToManyData(
          `${VARIETIES_SAMPLE_TYPE_QUERY}?id=${params.id}`,
          history,
          stemConditionQuery.data,
          true
        )

        setSelectedDefects(varietiesDefects)
        setSelectedSizes(varietiesSizes)
        setSelectedCategories(varietiesCategories)
        setSelectedColors(varietiesColors)
        setSelectedStemConditions(varietiesStemConditions)
        setSelectedSampleTypes(varietiesSampleTypes)

        setRowData({
          id: params.id,
          name: params.name,
          description: params.description,
          active: params.active,
        })
        setModalType(type)
        setOpen(true)
        setLoading(false)
      } catch (error) {
        onError(error)
      }
    } else {
      setRowData(DEFAULT_ROW_DATA)
      setSelectedDefects([])
      setSelectedCategories([])
      setSelectedSizes([])
      setSelectedColors([])
      setSelectedStemConditions([])
      setSelectedSampleTypes([])
      setModalType(type)
      setOpen(true)
      setLoading(false)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Variety
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        disableNew={role !== SUPER_ADMIN_ROLE && role !== QUALITY_CONTROL_MANAGER_ROLE}
        columns={columns}
        rows={verietyQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
        role={role}
      />
      <MultipleSelect
        open={openDefectsDialog}
        onClose={() => {
          setDefectsOpen(false)
        }}
        title="Defects"
        value={defectQuery.data}
        getRelationUrl="varietiesdefects"
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openSizesDialog}
        onClose={() => {
          setSizesOpen(false)
        }}
        title="Sizes"
        value={sizes}
        getRelationUrl="varietiessizes"
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openColorsDialog}
        onClose={() => {
          setOpenColorsDialog(false)
        }}
        title="Colors"
        value={colorQuery.data}
        getRelationUrl="varietiescolors"
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openStemDialog}
        onClose={() => {
          setOpenStemDialog(false)
        }}
        title="Stem Condition"
        value={stemConditionQuery.data}
        getRelationUrl="varietiesstemconditions"
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openSampleTypeDialog}
        onClose={() => {
          setOpenSampleTypeDialog(false)
        }}
        title="Sample Type"
        value={sampleTypeQuery.data}
        getRelationUrl="varietiessampletypes"
        mainId={rowData.id}
        lefttoright
      />
      <MultipleSelect
        open={openCategoryDialog}
        onClose={() => {
          setOpenCategoryDialog(false)
        }}
        title="Categories"
        value={categoryQuery.data}
        getRelationUrl="categoriesvarieties"
        useLast
        getAdditionalData="id_category_type"
        deleteRelationKey="id_category_type"
        mainId={rowData.id}
        lefttoright
      />
      <FormDialog
        title={`${modalType} variety`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label="Name"
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label="Description"
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <ManyToManyInput
          inputLabel="Defects"
          name="id_defect"
          selectedValues={selectedDefects}
          allValues={defectQuery.data}
          setSelectedValues={setSelectedDefects}
        />
        <ManyToManyInput
          inputLabel="Sizes"
          name="id_size"
          selectedValues={selectedSizes}
          allValues={sizes}
          setSelectedValues={setSelectedSizes}
        />
        <ManyToManyInput
          inputLabel="Colors"
          name="id_color"
          selectedValues={selectedColors}
          allValues={colorQuery.data}
          setSelectedValues={setSelectedColors}
        />
        <ManyToManyInput
          inputLabel="Categories"
          name="id_category"
          selectedValues={selectedCategories}
          allValues={categoryQuery.data}
          setSelectedValues={setSelectedCategories}
        />
        <ManyToManyInput
          inputLabel="Stem Conditions"
          name="id_stem_condition"
          selectedValues={selectedStemConditions}
          allValues={stemConditionQuery.data}
          setSelectedValues={setSelectedStemConditions}
        />
        <ManyToManyInput
          inputLabel="Sample Types"
          name="id_sample_type"
          selectedValues={selectedSampleTypes}
          allValues={sampleTypeQuery.data}
          setSelectedValues={setSelectedSampleTypes}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title="Deactivate variety"
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {DEFAULT_COMFIRM_INACTIVE_MESSAGE} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Variety)
