import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { connect, useDispatch } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import PrimaryButton from '../button/button.component'
import useStyles from './size-chart.styles'
import { formatReadableToSizes, formatSizesToReadable, getPercentage, getPercentageTri } from '../../shared/utils/utils'
import { modeChangeAction, sizeDistributionAction, sizeResultsAction } from '../../redux/dashboard/dashboard.acions'
import useSizeQuery from '../../shared/hooks/useSizeQuery'

const options = {
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label(tooltipItem) {
        return `${tooltipItem.value}%`
      },
    },
  },
}

const height = 80

const SizeResults = ({ location, dataIn, dataFb, sizeResultsDispatch, desTemplateSizeResults }) => {
  const classes = useStyles()
  const handleChange = (event) => {
    sizeResultsDispatch(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={2} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 5 }}>
            Location
          </FormLabel>
          <RadioGroup aria-label="gender" name="location" value={location} onChange={handleChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={`${desTemplateSizeResults.TEMPLATE_LOC_INL || 'In-Line'}`}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={`${desTemplateSizeResults.TEMPLATE_LOC_FIN || 'Finished Box'}`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={10} className={classes.containerChart}>
        {
          {
            1: <RenderTriBarChart data={dataIn} />,
            2: <RenderTriBarChart data={dataFb} />,
          }[location]
        }
      </Grid>
    </Grid>
  )
}

const RenderTriBarChart = ({ ...props }) => {
  const [orderedBarKeys, setOrderedKeys] = useState([])

  useEffect(() => {
    if (props.data) {
      // From object, create array and sort.
      const sortable = Object.entries(props.data)
        .sort(([, a], [, b]) => b.id - a.id)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      const keys = Object.keys(sortable).map((key) => sortable[key].value)
      setOrderedKeys(keys)
    }
  }, [props.data])

  return (
    <Bar
      data={{
        labels: orderedBarKeys,
        datasets: [
          {
            label: 'Unders',
            backgroundColor: '#E84C3D',
            borderColor: '#E84C3D',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 0)
            }),
          },
          {
            label: 'Correct',
            backgroundColor: '#66bb6a',
            borderColor: '#66bb6a',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 1)
            }),
          },
          {
            label: 'Overs',
            backgroundColor: '#ffa726',
            borderColor: '#ffa726',
            borderWidth: 1,
            data: orderedBarKeys.map((key) => {
              const data = Object.values(props.data).find((item) => item.value === key)
              if (!data) return 0
              return getPercentageTri(data, 2)
            }),
          },
        ],
      }}
      height={height}
      options={options}
    />
  )
}

const SizeDistribution = ({
  location,
  dataRec,
  dataUp,
  totalRec,
  totalUps,
  sizeDistributionDispatch,
  desTemplateSizeDistribution,
}) => {
  const [dataAverage, setDataAverage] = useState({})
  const classes = useStyles()

  useEffect(() => {
    // calculate Average
    // get all the keys contained in both receiving and upstream data hashes
    const indexes = [...new Set([...Object.keys(dataRec), ...Object.keys(dataUp)])]

    const dataAverageHelper = {}
    indexes.forEach((i) => {
      // calculate totals for ups + rec
      const rec = dataRec && dataRec[i] ? dataRec[i]?.quantity : 0
      const ups = dataUp && dataUp[i] ? dataUp[i]?.quantity : 0
      const id = dataRec[i] ? dataRec[i]?.id : dataUp[i] ? dataUp[i]?.id : 0
      const value = dataRec[i] ? dataRec[i]?.value : dataUp[i] ? dataUp[i]?.value : 0
      dataAverageHelper[i] = { quantity: rec + ups, id, value }
    })
    setDataAverage(dataAverageHelper)
  }, [dataRec, dataUp])

  const handleChange = (event) => {
    sizeDistributionDispatch(event.target.value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={2} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ marginBottom: 5 }}>
            Location
          </FormLabel>
          <RadioGroup aria-label="gender" name="location" value={location} onChange={handleChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={`${desTemplateSizeDistribution.TEMPLATE_LOC_REC || 'Receiving'}`}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={`${desTemplateSizeDistribution.TEMPLATE_LOC_UPS || 'Upstream'}`}
            />
            <FormControlLabel value="3" control={<Radio />} label="Average" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={10} className={classes.containerChart}>
        {
          {
            1: <RenderBarChart total={totalRec} data={dataRec} />,
            2: <RenderBarChart total={totalUps} data={dataUp} />,
            3: <RenderBarChart total={totalRec + totalUps} data={dataAverage} />,
          }[location]
        }
      </Grid>
    </Grid>
  )
}

const RenderBarChart = ({ ...props }) => {
  const barDataOptions = {
    label: '',
    backgroundColor: '#66bb6a',
    borderColor: '#66bb6a',
    borderWidth: 1,
  }

  const [orderedBarKeys, setOrderedKeys] = useState([])

  useEffect(() => {
    if (props.data) {
      // From object, create array and sort.
      const sortable = Object.entries(props.data)
        .sort(([, a], [, b]) => b.id - a.id)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
      const keys = Object.keys(sortable).map((key) => sortable[key].value)
      setOrderedKeys(keys)
    }
  }, [props.data])

  return (
    <Bar
      data={{
        labels: orderedBarKeys,
        datasets: [
          {
            ...barDataOptions,
            data: orderedBarKeys.map((i) => {
              const data = Object.values(props.data).find((item) => item.value === i)
              if (!data) return 0
              return getPercentage(props.total, data.quantity)
            }),
          },
        ],
      }}
      height={height}
      options={options}
    />
  )
}

const SizeChart = ({
  modeChangeDispart,
  mode,
  locationDistribution,
  sizeDistributionDispatch,
  locationResult,
  sizeResultsDispatch,
  desTemplates,
  ...props
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const locations = props.locations ?? {}
  const sizeQuery = useSizeQuery()

  const ClearSizeChart = () => {
    setData({})
  }

  const assignDataSize = (dataLocation) => {
    const dataResult = {}
    if (sizeQuery.data.length > 0 && Object.values(dataLocation).length > 0) {
      sizeQuery.data.forEach((size) => {
        const sizeProps = Object.values(dataLocation).find((item) => item.name === size.name)
        if (sizeProps) {
          dataResult[size.name] = { ...sizeProps, ...size }
        } else {
          dataResult[size.name] = {
            ...size,
            oversize: 0,
            quantity: 0,
            undersize: 0,
          }
        }
      })
      const sizeUnder = Object.values(dataLocation).find((item) => item.name === 'Undersize')
      if (sizeUnder) {
        dataResult[sizeUnder.name] = { ...sizeUnder, value: 'Undersize' }
      }
    }
    return dataResult
  }

  useEffect(() => {
    // build the datasets
    const newData = props.newData ?? {}
    const dataSizingResult = newData?.result
    ClearSizeChart()
    if (dataSizingResult?.length > 0) {
      for (let i = 0; i < dataSizingResult?.length; i++) {
        if (newData && dataSizingResult[i].sample_data && dataSizingResult[i].id_sampletype === 1) {
          const dataObject = dataSizingResult[i].sample_data.reduce((acc, obj) => {
            const key = obj.name
            acc[key] = obj
            return acc
          }, {})
          let loc = ''
          if (dataSizingResult[i].template_location === 'LOC_FIN') {
            loc = 'fin'
          }
          if (dataSizingResult[i].template_location === 'LOC_REC') {
            loc = 'rec'
          }
          if (dataSizingResult[i].template_location === 'LOC_UPS') {
            loc = 'ups'
          }
          if (dataSizingResult[i].template_location === 'LOC_INL') {
            loc = 'inl'
          }
          if (loc !== '') {
            setData((prev) => ({ ...prev, [loc]: assignDataSize(dataObject) }))
          }
        }
      }
    }
  }, [props.newData, props.locations, sizeQuery.data])

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.container}>
          <div className={classes.flex}>
            <Icon className={classes.icon}>close_fullscreen</Icon>
            <Typography variant="subtitle1" color="primary">
              {mode ? 'Sizing Accuracies' : 'Size Distribution of Incoming Lot'}
            </Typography>
          </div>

          <PrimaryButton onClick={() => modeChangeDispart()}>
            <Icon className={classes.iconBtn}>leaderboard</Icon> Size
            {mode ? ' Distribution' : ' Results'}
          </PrimaryButton>
        </div>
        <div style={{ border: '1px solid #eee', marginTop: 5, marginBottom: 15 }} />
        {mode ? (
          <SizeResults
            locations={props.locations}
            location={locationResult}
            desTemplateSizeResults={desTemplates}
            dataIn={data && data.inl ? data.inl : {}}
            dataFb={data && data.fin ? data.fin : {}}
            sizeResultsDispatch={sizeResultsDispatch}
            dataSize={sizeQuery.data}
          />
        ) : (
          <SizeDistribution
            locations={props.locations}
            location={locationDistribution}
            desTemplateSizeDistribution={desTemplates}
            dataRec={data && data.rec ? data.rec : {}}
            dataUp={data && data.ups ? data.ups : {}}
            totalRec={props.totalRec}
            totalUps={props.totalUps}
            sizeDistributionDispatch={sizeDistributionDispatch}
            dataSize={sizeQuery.data}
          />
        )}
      </CardContent>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  mode: state.dashboard.mode,
  locationDistribution: state.dashboard.sizeDistributionVal,
  locationResult: state.dashboard.sizeResultsVal,
})

const mapDispatchToProps = (dispatch) => ({
  modeChangeDispart: () => dispatch(modeChangeAction()),
  sizeDistributionDispatch: (value) => dispatch(sizeDistributionAction(value)),
  sizeResultsDispatch: (value) => dispatch(sizeResultsAction(value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SizeChart)
