import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Typography, Select, MenuItem, InputLabel, FormControl, OutlinedInput } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { USERS_FACILITIES_QUERY } from '../../../shared/constants/queries'
import RequestService from '../../../services/request/request-service'

export default function DetailForm(props) {
  const { rowSelected, userId, submitForm, setHandleNext, isNext } = { ...props }
  const [rowData, setRowData] = useState(rowSelected)
  const [conditionId, setConditionId] = useState(rowData?.condition ?? null)
  const [scopeId, setScopeId] = useState(rowData?.scope?.type ?? null)
  const [facilities, setFacilities] = useState([])
  const [idFacility, setIdFacility] = useState(rowData?.scope?.value ?? null)
  const history = useHistory()
  const handleChange = (event) => {
    const { name, value } = event.target

    setRowData({
      ...rowData,
      [name]: value,
    })
    submitForm(name, value)
  }
  useEffect(async () => {
    setHandleNext(true)
  }, [isNext])
  useEffect(async () => {
    async function fetchData() {
      try {
        const loadFacilities = new Promise(() => {
          const facilityURL = `${USERS_FACILITIES_QUERY}?id=${userId}&filterByFirst=true`
          RequestService.Get(facilityURL, history).then((facilityResponse) => {
            setFacilities(facilityResponse.data.map((facility) => facility.second).filter((x) => x.active === true))
          })
        })

        Promise.all([loadFacilities])
      } catch (error) {
        console.error(error)
      }
    }

    await fetchData()
  }, [rowSelected])

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Details alert config
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            defaultValue={rowData.name}
            variant="outlined"
            margin="normal"
            name="name"
            label="Name"
            type="text"
            fullWidth
            required
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth size="small" variant="outlined" margin="normal">
            <InputLabel id="demo-simple-select-label">Condition*</InputLabel>
            <Select
              defaultValue={rowData?.condition}
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              name="condition"
              label="Condition"
              onChange={handleChange}
              style={{ minHeight: 56 }}
              required
            >
              <MenuItem key="1" value={1}>
                And
              </MenuItem>
              <MenuItem key="2" value={2}>
                Or
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth size="small" variant="outlined" margin="normal">
            <InputLabel id="demo-simple-select-label">Scope*</InputLabel>
            <Select
              value={scopeId}
              defaultValue={rowData?.scope?.type}
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              label="Scope"
              onChange={(event) => {
                setScopeId(event.target.value)
                if (event.target.value === 1) {
                  submitForm('scope', { type: 1, value: 0 })
                }
              }}
              style={{ minHeight: 56 }}
              required
            >
              <MenuItem key="1" value={1}>
                Global
              </MenuItem>
              <MenuItem key="2" value={2}>
                Facility
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {scopeId === 2 ? (
          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="outlined" margin="normal">
              <InputLabel id="demo-simple-select-label">Facility</InputLabel>
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                label="Facility"
                value={idFacility}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event
                  setIdFacility(value)
                  submitForm('scope', { type: 2, value })
                }}
                required
                input={<OutlinedInput label="Name" />}
              >
                {facilities.map((item, index) => (
                  <MenuItem key={`cats-${item.id}`} value={item.id}>
                    {item.description ? item.description : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <div />
        )}
        <Grid item xs={12}>
          <TextField
            defaultValue={rowData.description}
            variant="outlined"
            margin="normal"
            name="description"
            label="Description"
            type="text"
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  )
}
