import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CircularProgress } from '@material-ui/core'
import PrimaryButton from '../button/button.component'
import Transition from '../transition/transition.component'
import useStyles from './confirm-dialog.styles'

const ConfirmDialog = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <div style={{ position: 'relative' }}>
            <PrimaryButton onClick={props.onOk} autoFocus disabled={props.loading}>
              {props.okButton}
            </PrimaryButton>
            {props.loading && <CircularProgress size={24} className={classes.circularProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog
