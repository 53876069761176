import FacilityActionTypes from './facility.types'

export const updateCurrentFacilityId = (value) => ({
  type: FacilityActionTypes.SET_CURRENT_FACILITY_ID,
  payload: value,
})

export const updateCurrentFacility = (value) => ({
  type: FacilityActionTypes.SET_CURRENT_FACILITY,
  payload: value,
})

export const updateFacilities = (value) => ({
  type: FacilityActionTypes.SET_FACILITIES,
  payload: value,
})

export const updateMoreThanOneFacility = (value) => ({
  type: FacilityActionTypes.SET_MORE_THAN_ONE_FACILITY,
  payload: value,
})

export const resetFacilityState = () => ({
  type: FacilityActionTypes.RESET_FACILITY_STATE,
})
