import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import FormControl from '@material-ui/core/FormControl'

import useStyles from './active-toggle.styles'

const ActiveToggle = ({ ...props }) => {
  const classes = useStyles()
  return (
    <FormControl margin="normal" variant="outlined" fullWidth>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="button" className={classes.switchText}>
            Inactive
          </Typography>
        </Grid>
        <Grid item>
          <Switch checked={props.value} onChange={props.onToggle} name="statusCheckbox" />
        </Grid>
        <Grid item>
          <Typography variant="button" className={classes.switchText}>
            Active
          </Typography>
        </Grid>
      </Grid>
    </FormControl>
  )
}

export default ActiveToggle
