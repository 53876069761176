import { decodeToken } from 'react-jwt'
import { logoutCurrenUserAction, setCurrentUserAction } from '../../redux/user/user.actions'
import { PAGES_ROLES_MAP } from '../../shared/constants/roles'
import { ACCOUNT_LOGIN_QUERY, ACCOUNT_REFRESH_TOKEN_QUERY } from '../../shared/constants/queries'
import RequestService from '../request/request-service'

class AuthService {
  static async logUser(credentials, dispatch) {
    if (credentials?.email && credentials?.password && typeof dispatch === 'function') {
      const response = await RequestService.Post(ACCOUNT_LOGIN_QUERY, () => {}, credentials)
      if (response?.hasError) {
        throw response
      }

      return AuthService.handleLogInResponse(response, credentials, dispatch)
    }

    throw new Error('Authentication error')
  }

  static async handleLogInResponse(response, credentials, dispatch) {
    if (response && response?.data) {
      const { accessToken, expiresOn, refreshToken } = response.data
      if (accessToken && expiresOn) {
        const decodedToken = decodeToken(accessToken)
        if (decodedToken?.roles && decodedToken?.oid) {
          const role = Array.isArray(decodedToken.roles) ? decodedToken.roles[0] : decodedToken.roles
          const id = decodedToken.oid
          const afterLoginPage = AuthService.getAfterLoginPage(role)

          AuthService.setUser(accessToken, refreshToken, expiresOn, credentials, dispatch, role, id, afterLoginPage)

          return afterLoginPage
        }
      }
    }

    throw new Error('Authentication error')
  }

  static setUser(accessToken, refreshToken, expiresOn, credentials, dispatch, role, id, afterLoginPage) {
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('expiresOn', expiresOn)
    localStorage.setItem('accessToken', accessToken)
    dispatch(
      setCurrentUserAction({
        email: credentials.email,
        token: accessToken,
        refreshToken,
        expiresOn,
        isLoggedIn: true,
        role,
        id,
        afterLoginPage,
      })
    )
  }

  static logoutUser(dispatch) {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('expiresOn')
    localStorage.removeItem('accessToken')
    dispatch(logoutCurrenUserAction())
  }

  static canUserViewPage(page, role) {
    return PAGES_ROLES_MAP[role]?.pages.includes(page)
  }

  static getAfterLoginPage(role) {
    return PAGES_ROLES_MAP[role]?.afterLoginPage
  }
}

export default AuthService
