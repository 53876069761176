import { DateTime } from 'luxon'
import ReportsActionTypes from './reports.types'

const INITIAL_STATE = {
  selectedLot: 0,
  selectedLotName: '-',
  selectedVariety: 0,
  selectedVarietyName: '-',
  selectedGrower: 0,
  selectedGrowerName: '-',
  selectedStartDate: DateTime.now().startOf('day').toISO({ includeOffset: false }),
  selectedEndDate: DateTime.now().endOf('day').toISO({ includeOffset: false }),
  selectedStartDateISO: DateTime.now().startOf('day').toISODate(),
  selectedEndDateISO: DateTime.now().endOf('day').toISODate(),
  selectedMachine: { id: 0 },
}

// eslint-disable-next-line default-param-last
const drawerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportsActionTypes.SET_SELECTED_LOT:
      return {
        ...state,
        selectedLot: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_VARIETY:
      return {
        ...state,
        selectedVariety: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_GROWER:
      return {
        ...state,
        selectedGrower: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_LOT_NAME:
      return {
        ...state,
        selectedLotName: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_SEASON:
      return {
        ...state,
        selectedSeason: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_VARIETY_NAME:
      return {
        ...state,
        selectedVarietyName: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_GROWER_NAME:
      return {
        ...state,
        selectedGrowerName: action.payload,
      }
    case ReportsActionTypes.SET_START_DATE:
      return {
        ...state,
        selectedStartDate: action.payload,
      }
    case ReportsActionTypes.SET_END_DATE:
      return {
        ...state,
        selectedEndDate: action.payload,
      }
    case ReportsActionTypes.SET_START_DATE_ISO:
      return {
        ...state,
        selectedStartDateISO: action.payload,
      }
    case ReportsActionTypes.SET_END_DATE_ISO:
      return {
        ...state,
        selectedEndDateISO: action.payload,
      }
    case ReportsActionTypes.SET_SELECTED_MACHINE:
      return {
        ...state,
        selectedMachine: action.payload,
      }

    default:
      return state
  }
}

export default drawerReducer
