export const MENU_ITEMS = [
  { name: 'dashboard', label: 'Dashboard', icon: 'dashboard', url: '/dashboard' },
  {
    name: 'defect_parameters',
    label: 'Defect Summary',
    icon: 'assessment',
    url: '/defect_parameters',
  },
  {
    name: 'quality_matrix',
    label: 'Quality Matrix',
    icon: 'grid_on',
    url: '/quality_matrix',
  },
  {
    name: 'sizing_matrix',
    label: 'Sizing Matrix',
    icon: 'grid_on',
    url: '/sizing_matrix',
  },
  {
    name: 'staff',
    label: 'Staff Performance',
    icon: 'people',
    url: '/staff',
  },
  {
    name: 'lot_information',
    label: 'Lot Information',
    icon: 'description',
    url: '/lot_information',
  },
  { name: 'downtimes', label: 'Downtime', icon: 'history_toggle_off', url: '/downtimes' },
  { name: 'reports', label: 'Reports', icon: 'pie_chart', url: '/reports' },
  { name: 'lottimes', label: 'Lot Times', icon: 'av_timer', url: '/lottimes' },
  {
    name: 'params',
    label: 'General Parameters',
    icon: 'vertical_split',
    children: [
      {
        name: 'category_type',
        label: 'Category type',
        url: '/category_type',
      },
      {
        name: 'category',
        label: 'Category',
        url: '/category',
      },
      {
        name: 'unit_type',
        label: 'Unit type',
        url: '/unit_type',
      },
      {
        name: 'unit',
        label: 'Unit',
        url: '/unit',
      },
    ],
    open: false,
  },
  {
    name: 'business',
    label: 'Business Parameters',
    icon: 'vertical_split',
    open: false,
    children: [
      {
        name: 'region',
        label: 'Region',
        url: '/region',
      },
      {
        name: 'facility',
        label: 'Facility',
        url: '/facility',
      },
      {
        name: 'location',
        label: 'Location',
        url: '/location',
      },
      {
        name: 'outlet',
        label: 'Outlet',
        url: '/outlet',
      },
      {
        name: 'grower',
        label: 'Grower',
        url: '/grower',
      },
      {
        name: 'size',
        label: 'Size',
        url: '/size',
      },
      {
        name: 'defect',
        label: 'Defect',
        url: '/defect',
      },
      {
        name: 'variety',
        label: 'Variety',
        url: '/variety',
      },
      {
        name: 'color',
        label: 'Color',
        url: '/color',
      },
      {
        name: 'stemcondition',
        label: 'Stem Condition',
        url: '/stemcondition',
      },
      {
        name: 'sampletype',
        label: 'Sample Type',
        url: '/sampletype',
      },
      {
        name: 'packtype',
        label: 'Pack-Type',
        url: '/packtype',
      },
      {
        name: 'machine',
        label: 'Machine',
        url: '/machine',
      },
      {
        name: 'user',
        label: 'Users',
        url: '/user',
      },
      {
        name: 'shift',
        label: 'Shifts',
        url: '/shift',
      },
    ],
  },
  { name: 'alertconfig', label: 'Alert Config', icon: 'speaker_notes', url: '/alertconfig' },
  { name: 'logs', label: 'Logs', icon: 'notes', url: '/logs' },
  {
    name: 'help',
    label: 'Help',
    icon: 'question_mark_icon ',
    url: '/help',
  },
]
