import UserActionTypes from './user.types'

const INITIAL_STATE = {
  isLoggedIn: false,
  email: '',
  token: '',
  expiresOn: '',
  role: '',
  afterLoginPage: '',
  id: '',
  refreshToken: '',
}

// eslint-disable-next-line default-param-last
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...action.payload,
      }
    case UserActionTypes.LOGOUT_CURRENT_USER:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default userReducer
