import DashBoardActionTypes from './dashboard.types'

export const updateCurrentDashboardData = (value) => ({
  type: DashBoardActionTypes.UPDATE_DASHBOARDDATA,
  payload: value,
})

export const modeChangeAction = () => ({
  type: DashBoardActionTypes.MODE_CHANGE,
})

export const sizeDistributionAction = (value) => ({
  type: DashBoardActionTypes.SIZE_DISTRIBUTION_CHANGE,
  payload: value,
})

export const sizeResultsAction = (value) => ({
  type: DashBoardActionTypes.SIZE_RESULTS_CHANGE,
  payload: value,
})

export const setCurrentMarketingAction = (value) => ({
  type: DashBoardActionTypes.MARKETING,
  payload: value,
})
