import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'

import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import DashboardContext from '../../context/dashboard/DashboardContext'
import SingleDatePicker from '../single-datepicker/simple-datepicker.component'
import {
  setCurrentAlertInformationAction,
  setCurrentDashboardInformationAction,
} from '../../redux/subscription/subscription.actions'
import {
  resetLotStateAction,
  setCurrentLotAction,
  setSavedLotAction,
  setCurrentLotTimeAction,
} from '../../redux/lots/lots.actions'
import { updateSelectedLot, updateSelectedMachine } from '../../redux/reports/reports.actions'
import { updateCurrentFacility, updateCurrentFacilityId } from '../../redux/facility/facility.actions'
import { setCurrentGrowerNameAction } from '../../redux/grower/grower.actions'
import { setCurrentMachineAction } from '../../redux/machine/machine.action'

import Alert from '../alert/alert.component'

import RequestService from '../../services/request/request-service'
import useStyles from './lot-change.styles'

import {
  LOTS_FACILITIES_QUERY,
  LOTS_VARIETIES_QUERY,
  MACHINE_QUERY,
  LOTTIME_QUERY,
} from '../../shared/constants/queries'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'
import { OPERATOR_ROLE } from '../../shared/constants/roles'

const LotChange = ({
  onClose,
  currentLot,
  setCurrentLotDispatch,
  savedLot,
  setSavedLotDispatch,
  resetLotStateActionDispatch,
  updateCurrentGrowerNameDispatch,
  facilities,
  currentFacilityId,
  currentFacility,
  updateCurrentFacilityIdDispatch,
  updateCurrentFacilityDispatch,
  moreThanOneFacility,
  alertInformation,
  updateCurrentAlertInformationDispatch,
  dashboardInformation,
  updateCurrentDashboardInformationDispatch,
  currentMachine,
  updateCurrentMachineDispatch,
  setCurrentLotTimeDispatch,
  currentLotTime,
  role,
  updateSetSelectedLot,
  updateSetSelectedMachine,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    deleteSubscription,
    closeAlertSubscription,
    closeAlertReceiver,
    closeDashboardSubscription,
    closeDashboardReceiver,
  } = useContext(DashboardContext)

  const [lots, setLots] = useState([])
  const [varieties, setVarieties] = useState([])
  const [selectedLot, setSelectedLot] = useState(null)
  const [selectedVariety, setSelectedVariety] = useState(0)
  const [selectedFacilityId, setSelectedFacilityId] = useState(currentFacilityId)
  const [selectedMachineId, setSelectedMachineId] = useState(currentMachine.id)

  const [openAlert, setOpen] = useState(false)
  const [alertMsj, setAlertMsj] = useState('Lot started')
  const [areLotButtonsDisabled, setAreLotButtonsDisabled] = useState(true)
  const [advancedChecked, setAdvancedChecked] = useState(false)
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [disabledButtonUpdate, setDisabledButtonUpdate] = useState(false)
  const [machines, setMachines] = useState([])

  useEffect(async () => {
    // Load lots if savedLot or currentFacilityId updates.
    // Reset selected lot and selected variety
    setSelectedLot(null)
    if (currentFacilityId !== 0) await loadLots(currentFacilityId)
  }, [savedLot, currentFacilityId])

  useEffect(async () => {
    // load varieties when lot changes
    const getVarieties = async () => {
      try {
        const url = `${LOTS_VARIETIES_QUERY}?id=${selectedLot.id}`
        const response = await RequestService.Get(url, history)
        const varietiesData = response.data
          .map((item) => {
            return item.second
          })
          .filter((x) => x.active === true)
        setVarieties(varietiesData)
        if (savedLot.id_variety && selectedLot.id === savedLot.lot_number) setSelectedVariety(savedLot.id_variety)
      } catch (error) {
        console.error(error)
      }
    }
    if (selectedLot?.id) await getVarieties()
  }, [selectedLot, savedLot])

  useEffect(() => {
    if (selectedLot !== 0 && selectedVariety !== 0) setAreLotButtonsDisabled(false)
    else setAreLotButtonsDisabled(true)
  }, [selectedLot, selectedVariety])

  useEffect(async () => {
    if (selectedFacilityId > 0) {
      const url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${selectedFacilityId}`
      const resMachines = await RequestService.Get(url, history)
      setMachines(resMachines.data)
      if (resMachines.data.length > 0) {
        const selectedFirst = resMachines.data.some((x) => x.id === currentMachine.id)
        if (selectedMachineId === 0 || !selectedFirst) {
          setSelectedMachineId(resMachines.data[0].id)
        } else {
          setSelectedMachineId(currentMachine.id)
        }
      } else {
        setSelectedMachineId(0)
      }
    }
  }, [selectedFacilityId])

  useEffect(() => {
    if (currentLot && lots && lots.length > 0) {
      const presentLot = lots.find((lot) => lot.name === currentLot.lot_name)
      if (presentLot) {
        setSelectedLot(presentLot)
        setSelectedVariety(currentLot.id_variety)
      }
    }
  }, [currentLot, lots])

  const resetSubscriptions = async () => {
    await deleteSubscription(
      dashboardInformation.topicName,
      dashboardInformation.subscriptionName,
      DASHBOARD_SUBSCRIPTION
    )
    await closeDashboardSubscription()
    await closeDashboardReceiver()
    await updateCurrentDashboardInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
    await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
    closeAlertSubscription()
    await closeAlertReceiver()
    await updateCurrentAlertInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
  }

  const loadLots = async (facilityId) => {
    try {
      const url = `${LOTS_FACILITIES_QUERY}?id=${facilityId}&filterByFirst=false&all=true`
      const response = await RequestService.Get(url, history)
      const newLots = response.data.map((lot) => lot.first).filter((x) => x.active === true)
      setLots(newLots)
      if (savedLot.lot_number) setSelectedLot(newLots.find((item) => item.id === savedLot.lot_number))
    } catch (error) {
      console.error(error)
    }
  }

  const setTimerLotTime = async () => {
    try {
      const lottime = {
        id_facility: currentFacilityId,
        id_lot: selectedLot.id,
        id_variety: selectedVariety,
        created_Date: DateTime.now().toISO({ includeOffset: false }),
      }
      const resStartTime = await RequestService.Post(`${LOTTIME_QUERY}/StartTime`, history, lottime)
      lottime.id = resStartTime.data.id
      setCurrentLotTimeDispatch(lottime)

      if (
        currentLotTime.id_facility !== currentFacilityId ||
        currentLotTime.id_lot !== selectedLot.id ||
        currentLotTime.id_variety !== selectedVariety
      ) {
        if (currentLotTime.id)
          await RequestService.Post(`${LOTTIME_QUERY}/EndTime`, history, currentLotTime.id, { isBlankInstance: true })
      } else {
        await RequestService.Post(`${LOTTIME_QUERY}/RemoveTimer`, history, currentLotTime.id, { isBlankInstance: true })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const startLot = async () => {
    updateSetSelectedLot(0)
    let advanced = advancedChecked
    setAreLotButtonsDisabled(true)
    const variety = varieties.find((item) => item.id === selectedVariety)
    if (
      startDate.slice(0, 10) === DateTime.now().startOf('day').toISO().slice(0, 10) &&
      endDate.slice(0, 10) === DateTime.now().startOf('day').toISO().slice(0, 10)
    ) {
      advanced = false
    }
    setCurrentLotDispatch({
      lot_number: selectedLot.id,
      id_variety: selectedVariety,
      lot_name: selectedLot.name,
      variety: variety.name,
      advancedChecked: advanced,
      id_grower: selectedLot.id_grower,
      startDate,
      endDate,
    })
    setSavedLotDispatch({ lot_number: '', id_variety: '', variety: '' })
    setSelectedLot(null)
    setAlertMsj('Lot Started')
    setOpen(true)
    setAreLotButtonsDisabled(false)
    onClose()
    if (role === OPERATOR_ROLE) setTimerLotTime()
  }

  const saveLaterLot = async () => {
    const variety = varieties.find((item) => item.id === selectedVariety)
    setSavedLotDispatch({
      lot_number: selectedLot.id,
      id_variety: variety.id,
      lot_name: selectedLot.name,
      variety: variety.name,
    })
    setAlertMsj('Lot saved for later')
    setOpen(true)
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpen(false)
  }

  const updateFacility = async () => {
    updateSetSelectedMachine({ id: 0 })
    setDisabledButtonUpdate(true)
    if (currentFacilityId !== selectedFacilityId) {
      await resetSubscriptions()
      await resetLotStateActionDispatch()
      await updateCurrentGrowerNameDispatch('')
      await updateCurrentFacilityIdDispatch(selectedFacilityId)
      await updateCurrentFacilityDispatch(facilities.find((facility) => facility.id === selectedFacilityId))
    }
    if (currentMachine?.id !== selectedMachineId) {
      await updateCurrentMachineDispatch(machines.find((machine) => machine.id === selectedMachineId))
    }
    setDisabledButtonUpdate(false)
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button className={classes.closeBtn} onClick={onClose} variant="contained" color="secondary">
          <Icon>clear</Icon>
        </Button>
      </div>

      <Typography className={classes.currentLabel} component="h1" variant="h6" color="primary">
        Current Facility:
      </Typography>
      <Typography component="h1" variant="subtitle1" color="primary">
        {currentFacility.description !== '' ? currentFacility.description : currentFacility.name}
      </Typography>
      <Typography className={classes.lotLabel} component="h1" variant="h6" color="primary">
        {moreThanOneFacility && selectedMachineId > 0
          ? 'Update Facility & Machine:'
          : moreThanOneFacility
          ? 'Update Facility'
          : 'Update Machine'}
      </Typography>
      <form className={classes.form}>
        {moreThanOneFacility ? (
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel id="current-facility-label">Current Facility</InputLabel>
            <Select
              value={selectedFacilityId}
              id="current-facility"
              labelId="current-facility-label"
              label="Current Facility"
              onChange={(event) => {
                setSelectedFacilityId(event.target.value)
              }}
            >
              {facilities.map((item) => (
                <MenuItem key={`facility-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        {selectedMachineId > 0 ? (
          <FormControl fullWidth margin="normal" variant="filled">
            <InputLabel id="machine-label">Machine</InputLabel>
            <Select
              value={selectedMachineId}
              id="machine"
              labelId="machine-label"
              label="Machine"
              onChange={(event) => {
                setSelectedMachineId(event.target.value)
              }}
            >
              {machines.map((item) => (
                <MenuItem key={`machine-${item.id}`} value={item.id}>
                  {item.name ? item.name : item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </form>

      {moreThanOneFacility || selectedMachineId > 0 ? (
        <div className={classes.btnContainer}>
          <Button
            disabled={disabledButtonUpdate}
            className={classes.startButton}
            onClick={updateFacility}
            variant="contained"
            color="secondary"
          >
            Update
          </Button>
        </div>
      ) : null}
      <Typography className={classes.currentLabel} component="h1" variant="h6" color="primary">
        Current Viewing Information:
      </Typography>
      <Typography component="h1" variant="subtitle1" color="primary">
        Lot #: {currentLot.lot_name}
      </Typography>
      <Typography component="h1" variant="subtitle1" color="primary">
        Variety: {currentLot.variety}
      </Typography>
      {currentLot.advancedChecked ? (
        <Typography component="h1" variant="subtitle1" color="primary">
          {DateTime.fromISO(currentLot.startDate).toFormat('MMM-dd-yyyy')} to{' '}
          {currentLot.endDate.slice(0, 10) !== DateTime.now().toISO().slice(0, 10)
            ? DateTime.fromISO(currentLot.endDate).toFormat('MMM-dd-yyyy')
            : 'Today'}
        </Typography>
      ) : null}
      <Typography className={classes.lotLabel} component="h1" variant="h6" color="primary">
        Start New Lot
      </Typography>
      <form className={classes.form} onSubmit={(event) => event.preventDefault()}>
        <FormControl fullWidth margin="normal" variant="filled" className={classes.autoCompleteContainer}>
          <Autocomplete
            value={selectedLot}
            onChange={(event, newValue) => {
              setSelectedLot(newValue)
              setSelectedVariety(0)
            }}
            id="lot-select"
            options={lots}
            renderInput={(params) => <TextField {...params} label="Select a lot number" />}
            getOptionSelected={() => true}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            className={classes.autoComplete}
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="filled">
          <InputLabel id="lot-variety">Lot variety</InputLabel>
          <Select
            disabled={selectedLot === null}
            labelId="lot-variety"
            value={selectedVariety}
            onChange={(event) => setSelectedVariety(event.target.value)}
          >
            <MenuItem value={0}>
              <em>Select a lot variety</em>
            </MenuItem>
            {varieties.map((item) => (
              <MenuItem value={item.id} key={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Grid container alignItems="center" style={{ justifyContent: 'end', paddingTop: '15px' }}>
            <Grid item>
              <Typography component="h1" variant="subtitle1" color="primary">
                Advanced
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                onChange={(event) => {
                  setAdvancedChecked(event.target.checked)
                }}
              />
            </Grid>
          </Grid>
          {advancedChecked ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <SingleDatePicker
                  maxDate={endDate}
                  format="MMM-dd-yy"
                  style={{ width: '100%' }}
                  label="Start date"
                  name="startDate"
                  disableFuture
                  value={startDate}
                  onChange={(date) => {
                    const startDate = DateTime.fromJSDate(date)
                    // setStartDateISODate(startDate.toISODate())
                    setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SingleDatePicker
                  minDate={startDate}
                  format="MMM-dd-yy"
                  style={{ width: '100%' }}
                  label="End date"
                  name="endDate"
                  disableFuture
                  value={endDate}
                  onChange={(date) => {
                    const endDate = DateTime.fromJSDate(date)
                    // setEndDateISODate(endDate.toISODate())
                    setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </FormControl>
      </form>
      <div className={classes.btnContainer}>
        <Button
          disabled={areLotButtonsDisabled}
          className={classes.startButton}
          onClick={startLot}
          variant="contained"
          color="secondary"
        >
          Start Lot
        </Button>
        <Button
          disabled={areLotButtonsDisabled}
          className={classes.saveButton}
          color="secondary"
          onClick={saveLaterLot}
        >
          Save for later
        </Button>
      </div>
      <Alert onClose={handleAlertClose} open={openAlert} severity="success">
        {alertMsj}
      </Alert>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
  savedLot: state.lots.savedLot,
  facilities: state.facility.facilities,
  currentFacility: state.facility.currentFacility,
  currentFacilityId: state.facility.currentFacilityId,
  moreThanOneFacility: state.facility.moreThanOneFacility,
  alertInformation: state.subscription.alertInformation,
  dashboardInformation: state.subscription.dashboardInformation,
  currentMachine: state.machine,
  currentLotTime: state.lots.currentLotTime,
  role: state.user.role,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentLotDispatch: (lotData) => dispatch(setCurrentLotAction(lotData)),
  setSavedLotDispatch: (lotData) => dispatch(setSavedLotAction(lotData)),
  updateCurrentFacilityIdDispatch: (facilityId) => dispatch(updateCurrentFacilityId(facilityId)),
  updateCurrentFacilityDispatch: (facility) => dispatch(updateCurrentFacility(facility)),
  resetLotStateActionDispatch: () => dispatch(resetLotStateAction()),
  updateCurrentGrowerNameDispatch: (growerName) => dispatch(setCurrentGrowerNameAction(growerName)),
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(setCurrentAlertInformationAction(alertInformation)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(setCurrentDashboardInformationAction(dashboardInformation)),
  updateCurrentMachineDispatch: (machine) => dispatch(setCurrentMachineAction(machine)),
  setCurrentLotTimeDispatch: (lotData) => dispatch(setCurrentLotTimeAction(lotData)),
  updateSetSelectedLot: (lot) => dispatch(updateSelectedLot(lot)),
  updateSetSelectedMachine: (machine) => dispatch(updateSelectedMachine(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LotChange)
