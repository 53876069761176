import DrawerActionTypes from './drawer.types'

export const toggleDrawerAction = () => ({
  type: DrawerActionTypes.TOGGLE_DRAWER,
})

export const updateMenuSelectedAction = (value) => ({
  type: DrawerActionTypes.SET_MENU_SELECTED,
  payload: value,
})

export const updatePreviousLocationAction = (location) => ({
  type: DrawerActionTypes.SET_PREVIOUS_LOCATION,
  payload: location,
})

export const logOutAction = () => ({
  type: DrawerActionTypes.LOG_OUT,
})
