import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import './App.css'

import { IANAZone, Settings } from 'luxon'
import { SnackbarProvider } from 'notistack'
import { QueryClientProvider } from 'react-query'
import DashboardContextProvider from './context/dashboard/DashboardContextProvider'
import AppInsightsContextProvider from './context/app-insights/AppInsigthsContextProvider'
import AppInsightsTrackingContextProvider from './context/app-insights-tracking/AppInsightsTrackingContextProvider'

import Authenticator from './components/authenticator/authenticator.component'
import Login from './pages/login/login.component'
import MainLayout from './components/main-layout/main-layout.component'
import HttpAlert from './components/http-alert/http-alert.component'
import { snackBarSetting } from './shared/constants/snackbar.settings'

import FacilityWrapper from './components/facility-wrapper/facility-wrapper.component'
import ForgotPassword from './pages/forgot_password/forgot_password.component'
import Dashboard from './pages/dashboard/dashboardv2.component'
import Staff from './pages/staff/staff.component'
import DefectParams from './pages/defect_params/defect_params.component'
import Lots from './pages/lot_information/lots.component'
import StaffOverview from './pages/staff/staff_overview.component'
import CategoryType from './pages/general_params/category_type.component'
import UnitType from './pages/general_params/unit_type.component'
import Category from './pages/general_params/category.component'
import Unit from './pages/general_params/unit.component'
import NotFound from './pages/not-found/not-found.component'
import Defect from './pages/bussines_params/defect.component'
import Grower from './pages/bussines_params/grower.component'
import Variety from './pages/bussines_params/variety.component'
import Size from './pages/bussines_params/size.component'
import Region from './pages/bussines_params/region.component'
import Facility from './pages/bussines_params/facility.component'
import Location from './pages/bussines_params/location.component'
import Outlet from './pages/bussines_params/outlet.component'
import Users from './pages/bussines_params/users.component'
import Color from './pages/bussines_params/color.component'
import StemCondition from './pages/bussines_params/stem-condition.component'
import SampleType from './pages/bussines_params/sample-type.component'
import PackType from './pages/bussines_params/pack-type.component'
import Reports from './pages/reports/reports.component'
import Downtime from './pages/downtime/downtime.component'
import LogSamples from './pages/LogSamples/log-samples.component'
import ShiftComponent from './pages/bussines_params/shift.component'
import Machine from './pages/bussines_params/machine.component'
import QualityMatrix from './pages/quality_matrix/quality_matrix.component'
import SizingMatrix from './pages/sizing_matrix/sizing_matrix.component'
import LotTime from './pages/lot_time/lot_time.component'
import AlertConfig from './pages/alert_configuration/alert_configuration.component'
import { queryClientSettings } from './shared/constants/query-client.settings'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_KEY)

// Settings.defaultZone = IANAZone.create('UCT')

const App = () => {
  return (
    <AppInsightsContextProvider>
      <AppInsightsTrackingContextProvider>
        <SnackbarProvider {...snackBarSetting}>
          <HttpAlert>
            <QueryClientProvider client={queryClientSettings}>
              <Suspense
                fallback={
                  <DashboardContextProvider>
                    <MainLayout />
                  </DashboardContextProvider>
                }
              >
                <Switch>
                  <Route
                    exact
                    path={['/login', '/']}
                    render={() => (
                      <DashboardContextProvider>
                        <Login />
                      </DashboardContextProvider>
                    )}
                  />
                  <Route exact path={['/forgotpassword']} component={ForgotPassword} />
                  <Route
                    exact
                    path="/dashboard"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <FacilityWrapper>
                              <Dashboard />
                            </FacilityWrapper>
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/staff"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Staff />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/quality_matrix"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <QualityMatrix />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/sizing_matrix"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <SizingMatrix />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/defect_parameters"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <DefectParams />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/lot_information"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Lots />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/staff_overview"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <StaffOverview />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/category_type"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <CategoryType />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/unit_type"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <UnitType />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/category"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Category />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/unit"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Unit />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/defect"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Defect />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/grower"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Grower />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/variety"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Variety />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/size"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Size />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/region"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Region />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/facility"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Facility />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/location"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Location />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/outlet"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Outlet />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/user"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Users />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/shift"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <ShiftComponent />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/color"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Color />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/stemcondition"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <StemCondition />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/sampletype"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <SampleType />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/packtype"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <PackType />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/machine"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Machine />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/reports"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Reports />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/downtimes"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <Downtime />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/logs"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <LogSamples />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/lottimes"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <LotTime />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route
                    exact
                    path="/alertconfig"
                    render={() => (
                      <Authenticator>
                        <DashboardContextProvider>
                          <MainLayout>
                            <AlertConfig />
                          </MainLayout>
                        </DashboardContextProvider>
                      </Authenticator>
                    )}
                  />
                  <Route path="/help" />
                  <Route render={() => <NotFound />} />
                </Switch>
              </Suspense>
            </QueryClientProvider>
          </HttpAlert>
        </SnackbarProvider>
      </AppInsightsTrackingContextProvider>
    </AppInsightsContextProvider>
  )
}

export default App
