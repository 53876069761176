import React from 'react'
import { Box, FormControl, MenuItem, Typography, TextField } from '@material-ui/core'
import { TIME_OF_UNIT } from '../../shared/utils/datetime-utils'
import useStyles from './downTimeCalculate.style'

const DownTimeCalculate = React.memo(({ totalDownTimes, handleTimeOfUnitChange }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    if (handleTimeOfUnitChange) {
      handleTimeOfUnitChange(event.target.value)
    }
  }

  return (
    <div className={classes.container}>
      <Typography>Total Downtime:</Typography>
      <TextField size="small" variant="outlined" value={totalDownTimes} style={{ width: 160 }} />
      <Box sx={{ minWidth: 100 }}>
        <FormControl fullWidth>
          <TextField
            id="outlined-select-currency"
            select
            variant="outlined"
            size="small"
            defaultValue={TIME_OF_UNIT.DAY}
            onChange={handleChange}
          >
            <MenuItem value={TIME_OF_UNIT.DAY}>Day</MenuItem>
            <MenuItem value={TIME_OF_UNIT.WEEK}>Week</MenuItem>
            <MenuItem value={TIME_OF_UNIT.SEASON}>Season</MenuItem>
          </TextField>
        </FormControl>
      </Box>
    </div>
  )
})

export default DownTimeCalculate
