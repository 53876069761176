import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import Popover from '@material-ui/core/Popover'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import { NOTIFICATION_QUERY } from '../../../shared/constants/queries'
import RequestService from '../../../services/request/request-service'
import { convertFormatDateTime } from '../../../shared/utils/utils'
import useStyles from './notification.styles'
import { setCurrentNotificationAction } from '../../../redux/notification/notification-pusher.action'
import { OPERATOR_ROLE, SUPER_ADMIN_ROLE } from '../../../shared/constants/roles'
import { POSITION_ANCHOREL } from '../../../shared/constants/general'

const Notification = ({
  currentLot,
  currentFacilityId,
  open,
  anchorEl,
  onClose,
  setUnreadCount,
  notificationPusher,
  setNotificationPusher,
  role,
}) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false)
  const [size, setSize] = useState({ width: 0, height: 0 })
  const [notifications, setNotifications] = useState([])
  const [notifySelected, setNotifySelected] = useState(null)
  const [positionBadge, setPositionBadge] = useState(POSITION_ANCHOREL)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(async () => {
    if (currentLot && currentFacilityId > 0) {
      await loadData()
    }
  }, [currentLot])

  useEffect(async () => {
    if (notificationPusher && Object.keys(notificationPusher).length > 0) {
      if (notificationPusher.is_global || notificationPusher.id_facility === currentFacilityId) {
        if (
          notificationPusher.id_lot === currentLot.lot_number &&
          notificationPusher.id_variety === currentLot.id_variety
        ) {
          enqueueSnackbar(`${notificationPusher.content}`, { variant: 'warning' })
          await loadData()
        }
      }
      setNotificationPusher(null)
    }
  }, [notificationPusher])

  useEffect(() => {
    if (anchorEl) {
      if (window.innerWidth < 600) {
        setPositionBadge({
          top: anchorEl.getBoundingClientRect().top + 43,
          left: anchorEl.getBoundingClientRect().left,
        })
      } else {
        setPositionBadge({
          top: 125,
          left: 70,
        })
      }
    }
  }, [anchorEl])

  const detectSize = () => {
    setSize({
      top: window.innerWidth,
      height: window.innerHeight,
    })
  }
  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [size])

  const loadData = async (startRowIndex = 0, pageSize = 10) => {
    // eslint-disable-next-line max-len
    const url = `${NOTIFICATION_QUERY}?id_facility=${currentFacilityId}&id_lot=${currentLot.lot_number}&id_variety=${currentLot.id_variety}&startRowIndex=${startRowIndex}&pageSize=${pageSize}`
    setLoading(true)
    const response = await RequestService.Get(url, history)
    setLoading(false)
    setUnreadCount(response.data.unread_count)
    if (!response.data?.notifications || response.data?.notifications?.length <= 0) setNotifications([])
    setNotifications(response.data.notifications)
  }
  const readOne = async (id) => {
    if (id) {
      const url = `${NOTIFICATION_QUERY}/Read?id=${id}`
      setLoading(true)
      await RequestService.Put(url, history, null, { isBlankInstance: true })
      await loadData()
    }
  }
  const readAll = async () => {
    if (currentLot && currentFacilityId > 0) {
      // eslint-disable-next-line max-len
      const url = `${NOTIFICATION_QUERY}/ReadAll?id_facility=${currentFacilityId}&id_lot=${currentLot.lot_number}&id_variety=${currentLot.id_variety}`
      setLoading(true)
      await RequestService.Put(url, history, null, { isBlankInstance: true })
      await loadData()
    }
  }
  const deleteOne = async (id) => {
    if (id) {
      setNotifySelected(id)
      setConfirmOpenDelete(true)
    }
  }
  const deleteAll = async () => {
    setNotifySelected(null)
    setConfirmOpenDelete(true)
  }

  const loadMore = async () => {
    await loadData(0, notifications.length + 10)
  }

  const deleteDocument = async () => {
    setConfirmOpenDelete(false)
    if (notifySelected) {
      const url = `${NOTIFICATION_QUERY}/Delete?id=${notifySelected}`
      setLoading(true)
      await RequestService.Delete(url, history, { isBlankInstance: true })
      await loadData()
    } else {
      // eslint-disable-next-line max-len
      const url = `${NOTIFICATION_QUERY}/DeleteAll?id_facility=${currentFacilityId}&id_lot=${currentLot.lot_number}&id_variety=${currentLot.id_variety}`
      setLoading(true)
      await RequestService.Delete(url, history, { isBlankInstance: true })
      await loadData()
    }
  }

  return (
    <>
      <Dialog open={confirmOpenDelete} onClose={() => setConfirmOpenDelete(false)}>
        <DialogTitle>
          Are you sure you want to delete {notifySelected ? 'this notification' : 'all notifications'}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpenDelete(false)}>Cancel</Button>
          <Button onClick={() => deleteDocument()}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Popover
        id="notifications-popup"
        className={classes.notificationsBox}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={positionBadge}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 10px 3px 10px',
            position: 'relative',
            zIndex: 1001,
          }}
        >
          <Typography variant="h6" component="div" style={{ float: 'left' }}>
            Notifications
          </Typography>
          {notifications?.length > 0 && role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE ? (
            <div />
          ) : (
            <Button disabled={loading} style={{ textTransform: 'none', float: 'right' }} onClick={() => readAll()}>
              Mark all read
            </Button>
          )}
        </div>
        <div style={{ padding: '10px', overflowX: 'auto', maxHeight: '60vh', marginTop: '-15px' }}>
          {notifications?.length > 0 ? (
            notifications?.map((item) => {
              return (
                <Card
                  key={`${item.id}`}
                  sx={{ minWidth: 275 }}
                  variant={!item.read ? 'elevation' : 'outlined'}
                  style={{ marginTop: '3%', background: !item.read ? 'whitesmoke' : 'white' }}
                >
                  <CardHeader
                    action={
                      role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE ? null : (
                        <>
                          {item.read ? null : (
                            <Button
                              disabled={loading}
                              style={{ textTransform: 'none' }}
                              size="small"
                              onClick={() => readOne(item.id)}
                            >
                              Read
                            </Button>
                          )}
                          <Button
                            disabled={loading}
                            style={{ textTransform: 'none' }}
                            size="small"
                            onClick={() => deleteOne(item.id)}
                          >
                            Delete
                          </Button>
                        </>
                      )
                    }
                    title={
                      <div style={{ display: 'inline-flex', color: !item.read ? '#222222' : '#cccccc' }}>
                        <Icon style={{ fontSize: '18px', marginRight: '5px' }}>access_time</Icon>
                        <Typography variant="body2" color="text.secondary">
                          {convertFormatDateTime(item.created_date)}
                        </Typography>
                      </div>
                    }
                  />
                  <CardContent
                    style={{ color: !item.read ? '#222222' : '#cccccc', overflowX: 'auto', paddingTop: '0px' }}
                  >
                    <Typography variant="subtitle2">{item.message}</Typography>
                  </CardContent>
                </Card>
              )
            })
          ) : (
            <Card key="NoData" sx={{ minWidth: 275 }} variant="outlined" style={{ marginTop: '3%', width: '20vw' }}>
              <CardContent
                style={{
                  color: '#222222',
                  overflowX: 'auto',
                  paddingTop: '0px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">There are no alert on this</Typography>
              </CardContent>
            </Card>
          )}
          <Button
            disabled={loading}
            size="small"
            component="div"
            style={{ textTransform: 'none', display: 'flex' }}
            onClick={loadMore}
          >
            Load more
          </Button>
        </div>
        {notifications?.length > 0 && role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE ? (
          <div />
        ) : (
          <Button
            disabled={loading}
            style={{ textTransform: 'none', float: 'right', margin: '0px 10px 5px 10px' }}
            onClick={() => deleteAll()}
          >
            Delete all
          </Button>
        )}

        {loading && <CircularProgress size={24} className={classes.circularProgress} />}
      </Popover>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setNotificationPusher: (notificationPush) => dispatch(setCurrentNotificationAction(notificationPush)),
})

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
  currentFacilityId: state.facility.currentFacilityId,
  notificationPusher: state.notificationPusher,
  role: state.user.role,
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
