import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Button from '@material-ui/core/Button'

import RequestService from '../../services/request/request-service'

import SingleDatePicker from '../single-datepicker/simple-datepicker.component'
import SearchableInput from '../searchable-input/searchable-input.component'
import { LOTS_VARIETIES_QUERY } from '../../shared/constants/queries'

const ReportFilter = ({
  onApplyfilter,
  lots,
  sampleTypes,
  defects,
  rowSizes,
  locations,
  staffMembers,
  machines,
  loading,
}) => {
  const history = useHistory()

  const DEFAULT_FILTER = { id_sample_type: '0' }
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toISO({ includeOffset: false }))
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toISO({ includeOffset: false }))
  const [filter, setFilters] = useState(DEFAULT_FILTER)
  const [varieties, setVarieties] = useState([])

  useEffect(async () => {
    await getVarieties()
  }, [filter?.id_lot])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFilters({
      ...filter,
      [name]: value,
    })
  }

  const getVarieties = async () => {
    try {
      if (!filter.id_lot || filter.id_lot <= 0) {
        setVarieties([])
        return
      }
      const url = `${LOTS_VARIETIES_QUERY}?id=${filter.id_lot}`
      const response = await RequestService.Get(url, history)
      const varietiesData = response.data.map((item) => item.second)
      setVarieties(varietiesData)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Grid item xs={12} md={4}>
      <Card raised>
        <CardContent>
          <Grid container spacing={1}>
            <Grid xs={12} item style={{ borderBottom: '1px solid #eee', marginBottom: '10px' }}>
              <Typography variant="h6">Report Filters</Typography>
            </Grid>
            <Grid xs={12} md={6} item>
              <SingleDatePicker
                format="MMM-dd-yy"
                label="Start Date"
                name="start_date"
                value={startDate}
                onChange={(date) => {
                  const startDate = DateTime.fromJSDate(date)
                  setStartDate(startDate.startOf('day').toISO({ includeOffset: false }))
                }}
                disableFuture
                views={['year', 'month', 'date']}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <SingleDatePicker
                format="MMM-dd-yy"
                label="End Date"
                name="end_date"
                vale={endDate}
                onChange={(date) => {
                  const endDate = DateTime.fromJSDate(date)
                  setEndDate(endDate.endOf('day').toISO({ includeOffset: false }))
                }}
                disableFuture
                views={['year', 'month', 'date']}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid xs={12} item>
              <SearchableInput
                disabled={machines.length === 0}
                value={machines.find((lot) => lot.id === filter.id_machine) ?? 0}
                options={machines}
                onChange={(event) => handleChange(event)}
                label="Machine"
                id="id_machine"
                name="id_machine"
              />
            </Grid>
            <Grid xs={12} item>
              <SearchableInput
                value={lots.find((lot) => lot.id === filter.id_lot) ?? 0}
                options={lots}
                onChange={(event) => handleChange(event)}
                label="Lot Number"
                id="id_lot"
                name="id_lot"
                showName
              />
            </Grid>
            <Grid xs={12} item>
              <SearchableInput
                value={varieties.find((variety) => variety.id === filter.id_variety) ?? 0}
                options={varieties}
                onChange={(event) => handleChange(event)}
                label="Variety"
                id="id_variety"
                name="id_variety"
                disabled={varieties.length === 0}
              />
            </Grid>
            <Grid xs={12} item style={{ marginTop: '10px' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Sample Type</FormLabel>
                <RadioGroup onChange={(event) => handleChange(event)} row aria-label="sampleType" name="id_sample_type">
                  <FormControlLabel
                    checked={filter.id_sample_type === DEFAULT_FILTER.id_sample_type}
                    value={DEFAULT_FILTER.id_sample_type}
                    labelPlacement="start"
                    control={<Radio color="primary" />}
                    label="Both"
                  />
                  {sampleTypes?.map((element) => (
                    <FormControlLabel
                      checked={filter.id_sample_type === element.id.toString()}
                      value={element.id}
                      control={<Radio color="primary" />}
                      label={element.description}
                      labelPlacement="start"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* <Grid xs={12} item>
              <SearchableInput
                value={defects.find((defect) => defect.id === filter.id_defect) ?? 0}
                options={defects}
                onChange={(event) => handleChange(event)}
                label="Defect Type"
                id="id_defect"
                name="id_defect"
              />
            </Grid>
            <Grid xs={12} item>
              <SearchableInput
                value={rowSizes.find((rowSize) => rowSize.id === filter.id_row_size) ?? 0}
                options={rowSizes}
                onChange={(event) => handleChange(event)}
                label="Row Size"
                id="id_row_size"
                name="id_row_size"
              />
            </Grid> */}
            <Grid xs={12} item>
              <SearchableInput
                value={locations.find((location) => location.id === filter.id_location) ?? 0}
                options={locations}
                onChange={(event) => handleChange(event)}
                label="Location"
                id="id_location"
                name="id_location"
              />
            </Grid>
            <Grid xs={12} item>
              <SearchableInput
                value={staffMembers.find((member) => member.id === filter.id_staff_member) ?? 0}
                options={staffMembers}
                onChange={(event) => handleChange(event)}
                label="Staff Member"
                id="id_staff_member"
                name="id_staff_member"
              />
            </Grid>
            <Grid xs={12} item style={{ borderTop: '1px solid #eee', paddingTop: '10px' }} />
            <Grid xs={12} item style={{ textAlign: 'right' }}>
              <Button style={{ textTransform: 'none' }} onClick={() => setFilters(DEFAULT_FILTER)} color="primary">
                Reset
              </Button>
              <Button
                style={{ textTransform: 'none', padding: '6px 20px', letterSpacing: '1.5px' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  filter.startDate = startDate
                  filter.endDate = endDate
                  onApplyfilter(filter)
                }}
                disabled={loading}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <SimpleBackdrop open={loading} /> */}
    </Grid>
  )
}

export default ReportFilter
