export const SUPER_ADMIN_ROLE = 'SuperAdmin'
export const FACILITY_OWNER_ROLE = 'FacilityOwner'
export const OPERATOR_ROLE = 'Operator'
export const QUALITY_CONTROL_ROLE = 'QualityControl'
export const QUALITY_CONTROL_MANAGER_ROLE = 'QualityControlManager'

export const ROLES_ALLOWED_CURRENTLY_PACKING = [SUPER_ADMIN_ROLE, FACILITY_OWNER_ROLE]

export const SUPER_ADMIN_PAGES = [
  '/dashboard',
  '/staff',
  '/defect_parameters',
  '/lot_information',
  '/quality_matrix',
  '/sizing_matrix',
  '/category_type',
  '/category',
  '/unit_type',
  '/unit',
  '/region',
  '/facility',
  '/location',
  '/outlet',
  '/grower',
  '/size',
  '/defect',
  '/variety',
  '/color',
  '/stemcondition',
  '/sampletype',
  '/shift',
  '/packtype',
  '/machine',
  '/user',
  '/reports',
  '/downtimes',
  '/logs',
  '/help',
  '/lottimes',

  '/alertconfig',
  '/staff_overview',
  '/testhub',
]

export const OPERATOR_PAGES = [
  '/dashboard',
  '/staff',
  '/lot_information',
  '/quality_matrix',
  '/sizing_matrix',
  '/staff_overview',
  '/defect_parameters',
  '/defect',
  '/grower',
  '/size',
  '/reports',
  '/downtimes',
  '/help',
  '/lottimes',
]

export const FACILITY_OWNER_PAGES = [
  '/dashboard',
  '/staff',
  '/lot_information',
  '/quality_matrix',
  '/sizing_matrix',
  '/staff_overview',
  '/defect_parameters',
  '/reports',
  '/downtimes',
  '/help',
  '/lottimes',
]

export const QUALITY_CONTROL_PAGES = ['/dashboard', '/staff']

export const QUALITY_CONTROL_MANAGER_PAGES = ['/dashboard', '/lot_information', '/grower', '/variety', '/logs']

export const PAGES_ROLES_MAP = {
  [`${SUPER_ADMIN_ROLE}`]: {
    afterLoginPage: '/dashboard',
    pages: SUPER_ADMIN_PAGES,
  },
  [`${OPERATOR_ROLE}`]: {
    afterLoginPage: '/dashboard',
    pages: OPERATOR_PAGES,
  },
  [`${FACILITY_OWNER_ROLE}`]: {
    afterLoginPage: '/dashboard',
    pages: FACILITY_OWNER_PAGES,
  },
  [`${QUALITY_CONTROL_ROLE}`]: {
    afterLoginPage: '/dashboard',
    pages: QUALITY_CONTROL_PAGES,
  },
  [`${QUALITY_CONTROL_MANAGER_ROLE}`]: {
    afterLoginPage: '/dashboard',
    pages: QUALITY_CONTROL_MANAGER_PAGES,
  },
}
