import DashBoardActionTypes from './dashboard.types'

const INITIAL_STATE = {
  mode: true,
  sizeDistributionVal: '1',
  sizeResultsVal: '1',
  message: {},
  marketing: {},
}

// eslint-disable-next-line default-param-last
const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashBoardActionTypes.UPDATE_DASHBOARDDATA:
      return {
        ...state,
        message: action.payload,
      }
    case DashBoardActionTypes.MODE_CHANGE:
      return {
        ...state,
        mode: !state.mode,
      }
    case DashBoardActionTypes.SIZE_DISTRIBUTION_CHANGE:
      return {
        ...state,
        sizeDistributionVal: action?.payload,
      }
    case DashBoardActionTypes.SIZE_RESULTS_CHANGE:
      return {
        ...state,
        sizeResultsVal: action?.payload,
      }
    case DashBoardActionTypes.MARKETING:
      return {
        ...state,
        marketing: action?.payload,
      }
    default:
      return state
  }
}

export default dashboardReducer
