import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import { MenuItem, Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { DateTime } from 'luxon'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import DialogAlert from '../../components/alert/alert.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import DownTimeCalculate from '../../components/DownTimeCalculate/DownTimeCalculate.component'
import RequestService from '../../services/request/request-service'

import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, DATE_FORMAT, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { ACTIONS_COLUMN, DEFAULT_ROW_DATA, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import { DOWNTIME_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import { SUPER_ADMIN_ROLE, OPERATOR_ROLE } from '../../shared/constants/roles'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import {
  convertMinutesToHoursAndMinutes,
  getDateFromByTimeOfUnit,
  TIME_OF_UNIT,
} from '../../shared/utils/datetime-utils'

const Downtime = ({ userEmail, role }) => {
  const TimeUnit = {
    Hours: 'hours',
    Minutes: 'minutes',
  }
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)

  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)

  const [timeUnit, setTimeUnit] = useState(TimeUnit.Minutes)
  const { executeInactive, loadingInactive } = useHttpInactive(DOWNTIME_QUERY)

  const [totalDownTimes, setTotalDownTimes] = useState('0 hrs, 0 mins.')
  const [totalTimeUnit, setToalTimeUnit] = useState(TIME_OF_UNIT.DAY)
  const [rowFilter, setRowFilter] = useState(rows)
  // columns definition for table
  const columns = [
    {
      field: 'date',
      headerName: 'Date stop',
      minWidth: 140,
      flex: 1,
      valueFormatter: ({ value }) => DateTime.fromISO(value).toFormat(DATE_FORMAT),
    },
    { field: 'createdBy', headerName: 'Username', minWidth: 150, flex: 1 },
    { field: 'component', headerName: 'Component', minWidth: 150, flex: 1 },
    { field: 'resolution', headerName: 'Resolution', minWidth: 150, flex: 1 },
    { field: 'description', headerName: 'Description', minWidth: 150, flex: 1 },
    { field: 'downtimeMinutes', headerName: 'Minutes Down', minWidth: 150, flex: 1 },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
      flex: 1,
    },
    {
      ...ACTIONS_COLUMN,
      hide: role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_LOCATION_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      setRows([])
      const url = `${DOWNTIME_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setRows(response.data)
    } catch (error) {
      onError(error)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)

    let url = `${DOWNTIME_QUERY}`
    let response
    try {
      if (method === DELETE_METHOD) {
        url = `${url}?id=${data.id}`
        response = await RequestService.Delete(url, history)
        if (response?.hasError) {
          onError(response.errorMessage)
          return
        }
      } else {
        if (method === POST_METHOD) {
          response = await RequestService.Post(url, history, data)
        }
        if (method === PUT_METHOD) {
          response = await RequestService.Put(url, history, data)
        }
      }
      setTableConf({ ...tableConf })
      setSeverity(ALERT_TYPES.success)
      setOpenAlert(true)
      setOpen(false)
    } catch (error) {
      onError(error.errorMessage)
    } finally {
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      if (params.downtimeMinutes > 60) {
        setTimeUnit(TimeUnit.Hours)
        const times = parseFloat(params.downtimeMinutes) / 60
        setRowData({
          id: params.id,
          date: params.date,
          name: params.name,
          description: params.description,
          component: params.component,
          resolution: params.resolution,
          downtimeMinutes: times.toFixed(1),
          active: params.active,
        })
      } else {
        setTimeUnit(TimeUnit.Minutes)
        setRowData({
          id: params.id,
          date: params.date,
          name: params.name,
          description: params.description,
          component: params.component,
          resolution: params.resolution,
          downtimeMinutes: params.downtimeMinutes,
          active: params.active,
        })
      }
    } else {
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const data = { ...rowData }

    if (timeUnit === TimeUnit.Hours) {
      data.downtimeMinutes = parseFloat(data.downtimeMinutes) * 60
    }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      date: params.date,
      name: params.name,
      component: params.component,
      resolution: params.resolution,
      downtime: params.downtime,
      description: params.description,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }
  const handleChangeTime = (event) => {
    const { name, value } = event.target

    setRowData({
      ...rowData,
      [name]: value,
    })
  }
  const handleChangeMH = (event) => {
    setTimeUnit(event.target.value)
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }
  useEffect(() => {
    caculateDowntime(totalTimeUnit)
  }, [rows])
  const caculateDowntime = (timeOfUnit) => {
    const fromDate = getDateFromByTimeOfUnit(timeOfUnit)
    const rowFilter = rows.filter((x) => DateTime.fromISO(x.date) >= fromDate)
    const totalDownTimeMinutes = rowFilter.reduce((acc, x) => acc + x.downtimeMinutes, 0)
    const textHoursAndMins = convertMinutesToHoursAndMinutes(totalDownTimeMinutes)
    setTotalDownTimes(textHoursAndMins)
    setToalTimeUnit(timeOfUnit)
    setRowFilter(rowFilter)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Downtime Log
        </Typography>
      </Box>
      <TableContainer
        header={<DownTimeCalculate totalDownTimes={totalDownTimes} handleTimeOfUnitChange={caculateDowntime} />}
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        disableNew={role !== SUPER_ADMIN_ROLE && role !== OPERATOR_ROLE}
        columns={columns}
        rows={rowFilter}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
        role={role}
      />
      <FormDialog
        title={`${modalType} downtime`}
        open={openDialog}
        onClose={() => {
          setTimeUnit(TimeUnit.Minutes)
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.component}
          variant="outlined"
          margin="normal"
          name="component"
          label="Component"
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label="Description"
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <TextField
          defaultValue={rowData.resolution}
          variant="outlined"
          margin="normal"
          name="resolution"
          label="Resolution"
          type="text"
          fullWidth
          required
          onChange={handleChange}
        />
        <Grid container>
          <Grid item xs={6}>
            <TextField
              defaultValue={rowData.downtimeMinutes}
              variant="outlined"
              label="DownTime"
              name="downtimeMinutes"
              placeholder="Time"
              margin="normal"
              type="number"
              fullWidth
              required
              inputProps={{ min: 0, step: 'any', pattern: 'd*' }}
              onChange={handleChangeTime}
              onKeyPress={(event) => {
                if (event?.key === '-' || event?.key === '+' || event?.key === '.' || event?.key === 'e') {
                  event.preventDefault()
                }
              }}
              onInput={(event) => {
                event.target.value = Math.max(0, parseInt(event.target.value, 10)).toString().slice(0, 3)
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <TextField
              variant="outlined"
              label="Time Unit"
              margin="normal"
              select
              onChange={handleChangeMH}
              value={timeUnit}
            >
              <MenuItem value={TimeUnit.Minutes}>Minutes</MenuItem>
              <MenuItem value={TimeUnit.Hours}>Hours</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title="Deactivate downtime"
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {DEFAULT_COMFIRM_INACTIVE_MESSAGE} this Downtime record?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {DEFAULT_DEPENDENCIES_ERROR_MESSAGE}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
  role: state.user.role,
})

export default connect(mapStateToProps, null)(Downtime)
