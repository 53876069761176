import subscriptionTypes from './subscription.types'

const INITIAL_STATE = {
  alertInformation: {
    topicName: null,
    subscriptionName: null,
  },
  dashboardInformation: {
    topicName: null,
    subscriptionName: null,
  },
  notificationInformation: {
    topicName: null,
    subscriptionName: null,
  },
  isLoading: false,
}

// eslint-disable-next-line default-param-last
const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case subscriptionTypes.SET_CURRENT_DASHBOARD_INFORMATION:
      return {
        ...state,
        dashboardInformation: {
          topicName: action.payload.topicName,
          subscriptionName: action.payload.subscriptionName,
        },
      }
    case subscriptionTypes.SET_CURRENT_ALERT_INFORMATION:
      return {
        ...state,
        alertInformation: {
          topicName: action.payload.topicName,
          subscriptionName: action.payload.subscriptionName,
        },
      }
    case subscriptionTypes.SET_CURRENT_NOTIFICATION_INFORMATION:
      return {
        ...state,
        notificationInformation: {
          topicName: action.payload.topicName,
          subscriptionName: action.payload.subscriptionName,
        },
      }
    case subscriptionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}

export default subscriptionReducer
