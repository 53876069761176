import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import clsx from 'clsx'
import { Link, useHistory, useLocation } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '@material-ui/core/Icon'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ER_USER_MANUAL_OPERATOR_PDF_URL } from '../../shared/constants/external-resources'

import { MENU_ITEMS } from '../../shared/constants/menuItems'

import {
  toggleDrawerAction,
  updateMenuSelectedAction,
  updatePreviousLocationAction,
} from '../../redux/drawer/drawer.actions'
import logo from '../../logo.png'
import useStyles from './drawer.styles'

import AuthService from '../../services/auth/auth-service'
import useOnClickOutside from '../../shared/hooks/useClickOutSide'

const DrawerComponent = (props) => {
  const {
    isDrawerOpen,
    drawerMenuSelected,
    updateMenuSelectedDispatch,
    role,
    previousLocation,
    updatePreviousLocationDispatch,
  } = props

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const drawerRef = useRef()

  const path = location?.pathname.replace('/', '')

  useEffect(() => {
    setSelectedMenuItem()
  }, [path])

  useEffect(() => {
    if (previousLocation !== path) {
      updatePreviousLocationDispatch(path)
    }
  }, [path])

  const doPush = (url) => {
    history.push(url)
    if (window.innerWidth < 600) {
      dispatch(toggleDrawerAction())
    }
  }

  const setSelectedMenuItem = () => {
    MENU_ITEMS.forEach((item) => {
      if (item.name === path) {
        updateMenuSelectedDispatch({
          parent: path,
          child: '',
        })
      } else if (item?.children && item.children.length > 0) {
        item.children.forEach((child) => {
          if (child.name === path) {
            updateMenuSelectedDispatch({
              parent: item.name,
              child: path,
            })
          }
        })
      }
    })
  }

  const showLink = (menuItem, isParent = false) => {
    if (menuItem.url) {
      return AuthService.canUserViewPage(menuItem.url, role)
    }
    if (isParent) return menuItem
    return false
  }

  const showListItem = (menuItem, hasChildren = false) => {
    const chevron = hasChildren ? menuItem.open ? <ExpandLess /> : <ExpandMore /> : <div />
    return (
      <ListItem
        className={clsx(classes.listColor, {
          [classes.selectedListColor]: drawerMenuSelected.parent === menuItem.name,
        })}
        button
        component={Link}
        to="#"
        onClick={() => {
          menuItem.open = !menuItem.open
          if (menuItem.url) {
            if (menuItem.url === '/help') {
              window.open(`${ER_USER_MANUAL_OPERATOR_PDF_URL}`, '')
            } else {
              doPush(menuItem.url)
            }
          }
        }}
      >
        <ListItemIcon>
          <Icon
            className={clsx(classes.listColor, {
              [classes.selectedListColor]: drawerMenuSelected.parent === menuItem.name,
            })}
          >
            {menuItem.icon}
          </Icon>
        </ListItemIcon>
        <ListItemText primary={menuItem.label} />
        {chevron}
      </ListItem>
    )
  }

  const renderMenu = (menuItem) => {
    if (!showLink(menuItem, true)) return null

    const hasChildren = menuItem?.children

    if (!hasChildren) {
      return (
        <List disablePadding key={menuItem.name}>
          {showListItem(menuItem, hasChildren)}
        </List>
      )
    }
    const showCollapse = menuItem.children.filter((child) => AuthService.canUserViewPage(child.url, role)).length > 0

    if (showCollapse) {
      return (
        <List disablePadding key={menuItem.name}>
          {showListItem(menuItem, hasChildren)}
          <Collapse in={menuItem.open} timeout="auto" unmountOnExit>
            <List
              className={clsx(classes.listColor, {
                [classes.selectedListColor]: drawerMenuSelected.parent === menuItem.name,
              })}
              component="div"
              disablePadding
            >
              {menuItem.children.map((child) => {
                if (!showLink(child)) return null
                return (
                  <ListItem
                    key={child.name}
                    button
                    className={clsx(classes.nested, {
                      [classes.drawerItemHover]: drawerMenuSelected.child === child.name,
                    })}
                    onClick={() => {
                      if (child.url) doPush(child.url)
                    }}
                  >
                    <ListItemIcon>
                      <Icon
                        className={clsx(classes.listColor, {
                          [classes.selectedListColor]: drawerMenuSelected.child === menuItem.name,
                        })}
                      >
                        horizontal_rule
                      </Icon>
                    </ListItemIcon>
                    <ListItemText primary={child.label} />
                  </ListItem>
                )
              })}
            </List>
          </Collapse>
        </List>
      )
    }

    return null
  }

  useOnClickOutside(drawerRef, () => {
    if (isDrawerOpen && window.innerWidth < 600) {
      dispatch(toggleDrawerAction())
    }
  })

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
      ref={drawerRef}
    >
      <div className={classes.drawerHeader}>
        <Typography className={`${classes.drawerTitle} header-title`} color="primary" component="h1" variant="h5">
          <img src={logo} className={classes.drawerLogo} alt="Logo" />
          <span className={classes.buenaFrutaBold}>Buena</span>
          <span className={classes.buenaFruta}>Fruta</span>
        </Typography>
      </div>

      {MENU_ITEMS.map((item) => renderMenu(item))}
      <Box m={3} />
      <Divider />
    </Drawer>
  )
}
const mapStateToProps = (state) => ({
  isDrawerOpen: state.drawer.isOpen,
  drawerMenuSelected: state.drawer.menuSelected,
  role: state.user.role,
  previousLocation: state.drawer.previousLocation,
})

const mapDispatchToProps = (dispatch) => ({
  updateMenuSelectedDispatch: (menu) => dispatch(updateMenuSelectedAction(menu)),
  updatePreviousLocationDispatch: (location) => dispatch(updatePreviousLocationAction(location)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent)
