import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import * as XLSXStyle from 'xlsx-js-style'

import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'

import useStyles from './exporttoexcel.styles'

const ExportToExcel = ({ apiData, fileName }) => {
  const classes = useStyles()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.aoa_to_sheet(apiData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <MenuItem onClick={(e) => exportToCSV(apiData, fileName)}>
      <Icon className={classes.icon}>file_download</Icon> Export Report
    </MenuItem>
  )
}

export default ExportToExcel

export const funcExportExcel = (apiData, columns, fileName, metaData) => {
  if (!apiData || apiData.length === 0) return

  let dataExport = []
  let colOffset = 0

  if (metaData) {
    dataExport = [...metaData, []]
    colOffset = 2
  }
  dataExport.push([
    ...Array(colOffset).map((x) => ''),
    ...columns.map((x) => {
      return {
        t: 's',
        s: { font: { bold: true, sz: 11 } },
        v: x.headerName,
      }
    }),
  ])

  apiData.forEach((x) => {
    const row = [...Array(colOffset).map((x) => '')]
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i]
      if (column.renderCell) {
        const param = { row: x, field: column.field, value: x[column.field] }
        row.push(column.renderCell(param))
      } else {
        row.push(x[column.field])
      }
    }
    dataExport.push(row)
  })

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const ws = XLSX.utils.aoa_to_sheet(dataExport)
  ws['!cols'] = autoFixColumns(dataExport)

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSXStyle.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

const autoFixColumns = function (dataExport) {
  const withCols = []

  for (let i = 0; i < dataExport.length; i++) {
    const element1 = dataExport[i]

    if (element1.length !== 0)
      for (let j = 0; j < element1.length; j++) {
        const element2 = element1[j]
        if (typeof element2 !== 'undefined') {
          if (typeof element2 === 'number') withCols[j] = (withCols[j] || 0) < 10 ? 10 : withCols[j]
          else if (typeof element2 === 'string')
            withCols[j] = (withCols[j] || 0) < element2.length ? element2.length : withCols[j]
          else withCols[j] = (withCols[j] || 0) < element2.v.length ? element2.v.length : withCols[j]
        }
      }
  }
  return withCols.map((x) => {
    return {
      wch: x + 3,
    }
  })
}

export const tranformSizeDistribution = (lot, columns, rows, noSamples) => {
  const array = []
  let rowIndex = 0
  const totalSamples = Object.values(noSamples)

  array[rowIndex] = ['Lot', lot.lot, 'Variety', lot.variety]
  rowIndex++

  for (let i = 0; i < 3; i++) {
    array[rowIndex] = ['Total Number of Samples', totalSamples[i]]
    rowIndex++
    const totalCherries = rows[i].reduce((t, acc) => t + acc, 0)
    array[rowIndex] = ['Total Number of Cherries', totalCherries]
    rowIndex++
    array[rowIndex] = ['', ...columns[i]]
    rowIndex++
    array[rowIndex] = ['TOTAL', ...rows[i]]
    rowIndex++
    array[rowIndex] = ['PERCENTAGE', ...rows[i].map((item) => ((item * 100) / totalCherries).toFixed(2))]
    rowIndex++

    array[rowIndex] = []
    rowIndex++
  }

  return array
}

export const tranformSizeAccuracy = (lot, table, noSamples) => {
  const array = []
  let rowIndex = 0

  const totals = [
    table.reduce((acc, i) => acc + i.underSize, 0),
    table.reduce((acc, i) => acc + i.total, 0),
    table.reduce((acc, i) => acc + i.overSize, 0),
  ]

  const granTotal = totals[0] + totals[1] + totals[2]
  const percents = [(totals[0] * 100) / granTotal, (totals[1] * 100) / granTotal, (totals[2] * 100) / granTotal]

  array[rowIndex] = ['Lot', lot.lot, 'Variety', lot.variety]
  rowIndex++
  array[rowIndex] = [
    'Total Number of Samples',
    noSamples.total,
    'Total Number of Cherries',
    totals.reduce((acc, i) => acc + i, 0),
  ]
  rowIndex++
  array[rowIndex] = []
  rowIndex++

  array[rowIndex] = ['Row Size', 'Undersize', 'Correct', 'Oversize']
  rowIndex++

  for (let j = 0; j < table.length; j++) {
    const row = table[j]
    array[rowIndex] = [`${row.size} Row`, row.underSize, row.total, row.overSize]
    rowIndex++
  }

  array[rowIndex] = ['TOTAL', totals[0], totals[1], totals[2]]
  rowIndex++

  array[rowIndex] = ['PERCENTAGE', percents[0], percents[1], percents[2]]
  rowIndex++

  return array
}
