import machineActionTypes from './machine.types'

const INITIAL_STATE = { id: 0 }

// eslint-disable-next-line default-param-last
const machineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case machineActionTypes.SET_CURRENT_MACHINE:
      return {
        ...INITIAL_STATE,
        ...action.payload,
      }
    default:
      return state
  }
}

export default machineReducer
