import { DARKBLUE, LIGHTBLUE, DARKGRAY, LIGHTGRAY } from '../../../shared/constants/pdf'
import { groupBy } from '../../../shared/utils/array-utils'
import PdfMakerService from '../../../services/pdf-maker/pdf-maker-service'

export const printfCartornReportSummary = (action, rows, columns, hiddenColumn, dateRepotString, sizeQuery) => {
  if (rows.length === 0) return

  const header = []
  const headerHide = []
  const headerPdf = []
  const dataRow = []
  const arrGroupByCategory = []

  columns.forEach((item) => {
    if (item.field !== 'Oversize') {
      if (!hiddenColumn.has(item.field)) {
        header.push(item.field)
      } else {
        headerHide.push(item.field)
      }
    }
  })

  header.forEach((item) => {
    switch (item) {
      case 'pick_date':
        headerPdf.push({ text: `Pick date`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'lot_number':
        headerPdf.push({ text: `Lot`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'variety_name':
        headerPdf.push({ text: `Variety`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'estimated_po':
        headerPdf.push({ text: `EPO`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'minor_defect':
        headerPdf.push({ text: `Min`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'major_defect':
        headerPdf.push({ text: `Maj`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'firmness':
        headerPdf.push({ text: `FM`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'brix':
        headerPdf.push({ text: `BX`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'bin_count':
        headerPdf.push({ text: `BC`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'net_weight':
        headerPdf.push({ text: `NW`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'field_average':
        headerPdf.push({ text: `FA`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Rojo Oscuro ':
        headerPdf.push({ text: `1`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Rojo':
        headerPdf.push({ text: `2`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Rojo Claro':
        headerPdf.push({ text: `3`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Rosada':
        headerPdf.push({ text: `4`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Verde':
        headerPdf.push({ text: `5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Undersize':
        headerPdf.push({ text: `U`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '12 Row':
        headerPdf.push({ text: `12`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '11.5 Row':
        headerPdf.push({ text: `11.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '11 Row':
        headerPdf.push({ text: `11`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '10.5 Row':
        headerPdf.push({ text: `10.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '10 Row':
        headerPdf.push({ text: `10`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '9.5 Row':
        headerPdf.push({ text: `9.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '9 Row':
        headerPdf.push({ text: `9`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '8.5 Row':
        headerPdf.push({ text: `8.5`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case '8 Row':
        headerPdf.push({ text: `8`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        break
      case 'Oversize':
        break
      default:
        if (sizeQuery.data) {
          const newHeader = sizeQuery.data.find((size) => size.description === item)
          if (newHeader) {
            headerPdf.push({ text: `${newHeader.value}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
          }
        } else {
          headerPdf.push({ text: `${item}`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        }
    }
  })

  rows.forEach((item) => {
    const data = {}
    header.forEach((hdItem) => {
      data[hdItem] = item[hdItem]
    })

    dataRow.push(data)
  })

  const groupByCategory = groupBy(dataRow, (product) => {
    return product.variety_name
  })

  const SummaryData = [headerPdf]
  Object.keys(groupByCategory).forEach(function (key) {
    arrGroupByCategory.push(groupByCategory[key])
  })
  const tempTotalFinal = header.map((item) => {
    const data = {}
    data[item] = 0
    return data
  })
  let tempTotal18Final = 0
  const tempCountTotal = header.map((item) => {
    const data = {}
    data[item] = 0
    return data
  })
  arrGroupByCategory.forEach((variety) => {
    const tempI = header.map((item) => {
      const data = {}
      data[item] = 0
      return data
    })

    const tempTotal = header.map((item) => {
      const data = {}
      data[item] = 0
      return data
    })
    let VarietyCarton = 0
    variety.forEach((data) => {
      const tempRowData = []
      let Epo = 0
      let NetWeight = 0
      header.forEach((key, index) => {
        if (key !== 'pick_date' && key !== 'variety_name' && key !== 'lot_number') {
          if (data[key] !== undefined) {
            if (key === 'net_weight') {
              NetWeight = parseFloat(data[key].replace(/,/g, ''))
            }
            if (key === 'estimated_po') {
              Epo = Number(data[key].replace('%', ''))
            }
            if (sizeQuery.data.find((item) => item.description === key) || key === 'Undersize') {
              const tempVal = data[key].percentage.replace('%', '')
              const a = parseFloat(tempVal)
              const carton = getCartonData(NetWeight, a, Epo)
              tempRowData.push({
                text: `${Number(carton.toFixed())}`,
                style: 'tableLabelValue7',
              })
            } else {
              const temp = typeof data[key] === 'number' ? data[key] : data[key].replace(/,/g, '')
              const a = parseFloat(temp)
              if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
                tempRowData.push({
                  text: `${Number(a.toFixed()).toLocaleString('en-US')}`,
                  style: 'tableLabelValue7',
                })
              } else if (key === 'brix') {
                tempRowData.push({
                  text: `${Number(a.toFixed(1))}`,
                  style: 'tableLabelValue7',
                })
              } else {
                tempRowData.push({
                  text: `${Number(a.toFixed(1))}%`,
                  style: 'tableLabelValue7',
                })
              }
            }
          } else if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
            tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
          } else if (sizeQuery.data.find((item) => item.description === key) || key === 'Undersize' || key === 'brix') {
            tempRowData.push({ text: `0`, style: 'tableLabelValue7' })
          } else {
            tempRowData.push({ text: `0%`, style: 'tableLabelValue7' })
          }
        } else {
          tempRowData.push({ text: `${data[key]}`, style: 'tableLabelValue7' })
        }

        if (key !== 'pick_date' && key !== 'variety_name' && key !== 'lot_number') {
          if (data[key] !== undefined) {
            if (sizeQuery.data.find((item) => item.description === key) || key === 'Oversize' || key === 'Undersize') {
              const tempVal = data[key].percentage.replace('%', '')
              const a = parseFloat(tempVal)
              const carton = getCartonData(NetWeight, a, Epo)
              tempTotal[index][key] += Number(carton.toFixed())
              VarietyCarton += Number(carton.toFixed())
            } else if (typeof data[key] === 'number') {
              if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
                if (data[key] !== 0) {
                  tempI[index][key]++
                }
                tempTotal[index][key] += Number(parseFloat(data[key]?.replace(/,/g, '')).toFixed())
              } else {
                if (data[key] !== 0) {
                  tempI[index][key]++
                }
                tempTotal[index][key] += data[key]
              }
            } else if (key === 'bin_count' || key === 'net_weight' || key === 'firmness') {
              if (data[key] !== '0.0%' && data[key] !== '0%' && data[key] !== '0.0' && data[key] !== '0') {
                tempI[index][key]++
              }
              let tempVal = data[key].replace('%', '')
              tempVal = tempVal.replace(',', '')
              tempTotal[index][key] += Number(parseFloat(data[key].replace(/,/g, '')).toFixed())
            } else {
              if (data[key] !== '0.0%' && data[key] !== '0%' && data[key] !== '0.0' && data[key] !== '0') {
                tempI[index][key]++
              }
              const tempVal = data[key].replace('%', '')
              tempTotal[index][key] += parseFloat(data[key])
            }
          } else {
            tempTotal[index][key] += 0
          }
        }
      })

      SummaryData.push(tempRowData)
    })

    const totalVariety = []
    const total18lb = []
    const blank = []
    let packableWeight = 0
    let total18 = 0
    Object.values(tempTotal).forEach(function (item, index) {
      const key = Object.keys(tempTotal[index]) // eslint-disable-line no-param-reassign
      if (key[0] !== 'bin_count' && key[0] !== 'net_weight') {
        tempTotal[index][key[0]] /= tempI[index][key[0]] > 0 ? tempI[index][key[0]] : 1
      }
    })

    Object.values(tempTotal).forEach(function (item, index) {
      const key = Object.keys(tempTotal[index]) // eslint-disable-line no-param-reassign
      // if (key[0] !== 'bin_count' && key[0] !== 'net_weight') {
      //   tempTotal[index][key[0]] /= tempI[index][key[0]] > 0 ? tempI[index][key[0]] : 1
      // }

      tempCountTotal[index][key]++
      const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])

      switch (key[0]) {
        case 'pick_date':
        case 'lot_number':
          totalVariety.push({ text: ``, style: 'tableLabel7', fillColor: DARKGRAY })
          total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
          break
        case 'variety_name':
          totalVariety.push({ text: `Variety Totals`, style: 'tableLabel7', fillColor: DARKGRAY })
          total18lb.push({ text: '18lb Cartons', style: 'tableLabel7', fillColor: LIGHTGRAY })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
          break
        case 'bin_count': {
          const packWeight = getPackableWeight(tempTotal)
          total18 = getTotal18lb(tempTotal, packWeight, sizeQuery)
          totalVariety.push({
            text: `${Number(Number(tempTotal[index][key[0]].toFixed())).toLocaleString('en-US')}`,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({
            text: Number(VarietyCarton.toFixed()).toLocaleString('en-US'),
            style: 'tableLabel7',
            fillColor: LIGHTGRAY,
          })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])

          break
        }
        case 'net_weight':
        case 'firmness':
          totalVariety.push({
            text: `${Number(tempTotal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
          break
        case 'brix':
          totalVariety.push({
            text: `${tempTotal[index][key[0]].toFixed(1)}`,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
          break
        case 'estimated_po':
          if (tempTotal[index][key[0]] !== 0) {
            packableWeight = getPackableWeight(tempTotal)
          }
          totalVariety.push({
            text: `${
              tempTotal[index][key[0]] === 100 || tempTotal[index][key[0]] === 0
                ? tempTotal[index][key[0]].toFixed()
                : tempTotal[index][key[0]].toFixed(1)
            }%`,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({
            text: ` `,
            style: 'tableLabel7',
            fillColor: LIGHTGRAY,
          })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
          break
        case 'Undersize':
        case `${keySizeQuery ? keySizeQuery.description : ''}`:
          totalVariety.push({
            text: ``,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({
            text: `${tempTotal[index][key].toFixed().toLocaleString('en-US')}`,
            style: 'tableLabel7',
            fillColor: LIGHTGRAY,
          })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += Number(tempTotal[index][key[0]].toFixed())
          tempTotal18Final += parseFloat(tempTotal[index][key[0]])
          break
        default:
          totalVariety.push({
            text: `${Number(tempTotal[index][key[0]].toFixed(1))}%`,
            style: 'tableLabel7',
            fillColor: DARKGRAY,
          })
          total18lb.push({ text: '', style: 'tableLabel7', fillColor: LIGHTGRAY })
          blank.push({ text: ' ', style: 'tableLabel7' })
          tempTotalFinal[index][key] += parseFloat(tempTotal[index][key[0]])
      }
    })

    SummaryData.push(totalVariety)
    SummaryData.push(total18lb)
    SummaryData.push(blank)
  })
  const totalFinal = []
  const total18Final = []
  Object.values(tempTotalFinal).forEach(function (item, index) {
    const key = Object.keys(tempTotalFinal[index]) // eslint-disable-line no-param-reassign
    const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])

    switch (key[0]) {
      case 'pick_date':
      case 'lot_number':
        totalFinal.push({ text: ``, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })

        break
      case 'variety_name':
        totalFinal.push({ text: `Totals`, style: 'tableSumaryLabel', fillColor: DARKBLUE })
        total18Final.push({ text: `18lb Total`, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
        break
      case 'bin_count':
        totalFinal.push({
          text: `${Number(tempTotalFinal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
          style: 'tableSumaryLabel',
          fillColor: DARKBLUE,
        })
        total18Final.push({
          text: `${Number(tempTotal18Final.toFixed()).toLocaleString('en-US')}`,
          style: 'tableSumaryLabel8',
          fillColor: LIGHTBLUE,
        })
        break
      case 'brix':
        totalFinal.push({
          text: `${tempTotalFinal[index][key[0]].toFixed(1)}`,
          style: 'tableSumaryLabel',
          fillColor: DARKBLUE,
        })
        total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
        break
      case 'firmness':
      case 'net_weight':
        totalFinal.push({
          text: `${Number(tempTotalFinal[index][key[0]].toFixed()).toLocaleString('en-US')}`,
          style: 'tableSumaryLabel',
          fillColor: DARKBLUE,
        })
        total18Final.push({ text: ``, style: 'tableSumaryLabel8', fillColor: LIGHTBLUE })
        break
      case 'Undersize':
      case `${keySizeQuery ? keySizeQuery.description : ''}`:
        totalFinal.push({
          text: ``,
          style: 'tableSumaryLabel',
          fillColor: DARKBLUE,
        })
        total18Final.push({
          text: Number(tempTotalFinal[index][key[0]].toFixed()).toLocaleString('en-US'),
          style: 'tableSumaryLabel8',
          fillColor: LIGHTBLUE,
        })
        break
      default:
        totalFinal.push({
          text: `${Number(tempTotalFinal[index][key[0]].toFixed(1))}%`,
          style: 'tableSumaryLabel',
          fillColor: DARKBLUE,
        })
        total18Final.push({
          text: ' ',
          style: 'tableSumaryLabel8',
          fillColor: LIGHTBLUE,
        })
    }
  })

  SummaryData.push(totalFinal)
  SummaryData.push(total18Final)
  if (action === 'print') {
    PdfMakerService.generateCartonSummary(`Receiving_Carton_Summary_${dateRepotString}`, SummaryData)
  }
}
const getPackableWeight = (totalData) => {
  let varietyEPO = 0
  let netWeight = 0
  Object.values(totalData).forEach(function (item, index) {
    const key = Object.keys(totalData[index]) // eslint-disable-line no-param-reassign

    switch (key[0]) {
      case 'net_weight':
        netWeight = Number(totalData[index][key[0]].toFixed())
        break
      case 'estimated_po':
        varietyEPO = Number(totalData[index][key[0]].toFixed(2)) / 100
        break
      default:
        break
    }
  })
  const packableWeight = netWeight * varietyEPO
  return packableWeight
}
const getTotal18lb = (totalData, packableWeight, sizeQuery) => {
  let total18 = 0
  Object.values(totalData).forEach(function (item, index) {
    const key = Object.keys(totalData[index]) // eslint-disable-line no-param-reassign
    const keySizeQuery = sizeQuery.data.find((size) => size.description === key[0])
    switch (key[0]) {
      case 'Undersize':
      case `${keySizeQuery ? keySizeQuery.description : ''}`:
        total18 += Number((((Number(totalData[index][key[0]] || 0).toFixed(1) / 100) * packableWeight) / 18).toFixed())
        break
      default:
        break
    }
  })

  return total18
}

const getCartonData = (net_weight, size, varietyEPO) => {
  const netWeightPacked = varietyEPO * (net_weight / 100)
  const data = (((size || 0) / 100) * netWeightPacked) / 18
  return data
}
