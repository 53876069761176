import { forwardRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Transition from '../transition/transition.component'
import PrimaryButton from '../button/button.component'
import useStyles from './form-dialog.styles'

const FormDialog = ({ children, ...props }, ref) => {
  const classes = useStyles()
  return (
    <Dialog
      ref={ref}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
        props.onClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <form onSubmit={props.handleSubmit}>
        <DialogContent>
          <DialogContentText style={{ margin: 0 }}>Please fill in the information below.</DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <div style={{ position: 'relative' }}>
            <PrimaryButton variant="contained" disabled={props.loading} type="submit">
              Save
            </PrimaryButton>
            {props.loading && <CircularProgress size={24} className={classes.circularProgress} />}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default forwardRef(FormDialog)
