import React from 'react'
import ReactDOM from 'react-dom'
// Navigation
import { BrowserRouter } from 'react-router-dom'
// Store
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { ThemeProvider } from '@material-ui/core/styles'
import { store, persistor } from './redux/store'

// Theming Material UI
import theme from './muiTheme'

import './index.css'
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
