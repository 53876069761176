import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Badge from '@material-ui/core/Badge'

import DashboardContext from '../../context/dashboard/DashboardContext'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'

import logo from '../../logo.png'
import './sidebar.styles.scss'

import { selectLoginData } from '../../redux/user/user.selectors'
import { selectSubscriptionData } from '../../redux/subscription/subscription.selectors'
import { toggleDrawerAction, logOutAction } from '../../redux/drawer/drawer.actions'
import { resetFacilityState } from '../../redux/facility/facility.actions'
import {
  setCurrentAlertInformationAction,
  setCurrentDashboardInformationAction,
} from '../../redux/subscription/subscription.actions'

import AuthService from '../../services/auth/auth-service'
import { ALERT_SUBSCRIPTION, DASHBOARD_SUBSCRIPTION } from '../../shared/constants/general'
import { LOG_ACTIONS } from '../../shared/constants/logging'
import Notification from './notification/notification.component'

const SideBar = ({
  toggleDrawerDispatch,
  loginData,
  subscriptionData,
  updateCurrentAlertInformationDispatch,
  updateCurrentDashboardInformationDispatch,
  resetFacilityStateDispatch,
  logOutDispatch,
}) => {
  const { cleanUpDashboardContext, cleanUpAlertContext, deleteSubscription } = useContext(DashboardContext)
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const dispatch = useDispatch()
  const { dashboardInformation, alertInformation } = subscriptionData
  const { afterLoginPage } = loginData

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElNotifications, setAnchorElNotifications] = useState(null)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenNotifications = (event) => {
    setAnchorElNotifications(event.currentTarget)
    setOpenNotifications(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    await cleanUpDashboardContext()
    await deleteSubscription(
      dashboardInformation.topicName,
      dashboardInformation.subscriptionName,
      DASHBOARD_SUBSCRIPTION
    )
    await updateCurrentDashboardInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
    await cleanUpAlertContext()
    await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
    await updateCurrentAlertInformationDispatch({
      topicName: null,
      subscriptionName: null,
    })
    await resetFacilityStateDispatch()
    trackEvent(LOG_ACTIONS.LOGOUT_SUCCESSFUL, { email: loginData.email })
    AuthService.logoutUser(dispatch)
    logOutDispatch()
  }

  return (
    <div className="sidebar">
      <div className="logo-container">
        <Link to={afterLoginPage} className="link-logo">
          <img src={logo} className="logo" alt="Logo" />
        </Link>

        <Icon onClick={toggleDrawerDispatch} className="link-menu">
          menu_open
        </Icon>
        <div className="menu-stack">
          <Badge className="link-menu" badgeContent={unreadCount || 0} color="secondary">
            <Icon onClick={handleOpenNotifications}>mail</Icon>
          </Badge>
          <Icon onClick={handleClick} className="link-menu">
            settings
          </Icon>
          <Menu
            id="simple-menu"
            className="menu-logout"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleLogOut()}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
      <Notification
        anchorEl={anchorElNotifications}
        onClose={() => setOpenNotifications(false)}
        open={openNotifications}
        setUnreadCount={setUnreadCount}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  toggleDrawerDispatch: () => dispatch(toggleDrawerAction()),
  resetFacilityStateDispatch: () => dispatch(resetFacilityState()),
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(setCurrentAlertInformationAction(alertInformation)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(setCurrentDashboardInformationAction(dashboardInformation)),
  logOutDispatch: () => dispatch(logOutAction()),
})

const mapStateToProps = createStructuredSelector({
  loginData: selectLoginData,
  subscriptionData: selectSubscriptionData,
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
