import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { DateTime } from 'luxon'
import {
  BLUE,
  RED,
  DEFAULT_TABLE_ARRAY,
  GREEN,
  IMAGES,
  LOGO,
  NO_BORDER,
  NO_MARGIN,
  PAGE_MARGINS,
  STYLES,
} from '../../shared/constants/pdf'
import { numberToPercentage, parseSummaryDefectDataToPDFData } from '../../shared/utils/utils'

pdfMake.vfs = pdfFonts.pdfMake.vfs

class PdfMakerService {
  static generateReport(documentName, reportType, data, images = ['']) {
    this.generatePDF(documentName, this.generateContent(documentName, reportType, data, images))
  }

  static generateReportSummary(documentName, data, lots) {
    this.generateSumPDF(documentName, this.generateSumarryContent(documentName, data), 'landscape')
  }

  static generateRunListSummary(documentName, data) {
    this.generateSumPDF(documentName, this.generateRunListContent(documentName, data), 'landscape')
  }

  static generateCartonSummary(documentName, data) {
    this.generateSumPDF(documentName, this.generateCartonContent(data), 'landscape')
  }

  static attachSummarryToEmail(documentName, data, lots) {
    return this.generatePDFNoDown(this.generateSumarryContent(documentName, data), 'landscape')
  }

  static attachToEmail(documentName, reportType, data, images = ['']) {
    return this.generatePDFNoDown(this.generateContent(documentName, reportType, data, images), 'portrait')
  }

  static generateContent(documentName, reportType, data, images) {
    const {
      general,
      quality,
      size,
      sizeChartData,
      sizeCartonData,
      upstreamSummary,
      inlineSummary,
      finishedBoxSummary,
      cullsSummary,
      sizeDistribution,
      upstream,
      receiving,
      sizingAccuracies,
      productionSummaryData,
      totalRecvingSizing,
      totalUpstreamSizing,
      totalRecvingQuality,
      totalUpstreamQuality,
      totalInlineQuality,
      totalFinishedBoxQuality,
      totalGIBQuality,
      totalGIPQuality,
      varietiesName,
      dateReport,
      machineName,
      peddlersSummary,
      receivingQuality,
      upstreamQuality,
      inLineQuality,
      finishBoxQuality,
      packTypeSummaryData,
      packTypes,
      varieties,
      facilities,
      machines,
      sizes,
      totalInline,
      totalFinishedBox,
      desTemplates,
    } = data
    let packOutColor = 'green'
    let content
    let colorBreakdownArray = []
    let upstreamSummaryMinorDefectsArray = DEFAULT_TABLE_ARRAY
    let upstreamSummaryMajorDefectsArray = DEFAULT_TABLE_ARRAY
    let inlineSummaryMinorDefectsArray = DEFAULT_TABLE_ARRAY
    let inlineSummaryMajorDefectsArray = DEFAULT_TABLE_ARRAY
    let finishedBoxSummaryMinorDefectsArray = DEFAULT_TABLE_ARRAY
    let finishedBoxSummaryMajorDefectsArray = DEFAULT_TABLE_ARRAY
    const sizingSummarySizeDistributionWidthsArray = [70]
    const receivingSummarySizeDistributionWidthsArray = [70]
    const upstreamSummarySizeDistributionWidthsArray = [70]
    const receivingReportSizeDistributionWidthsArray = [75]
    const sizingSummarySizeDistributionBodyArray = [{}]
    const receivingSummarySizeDistributionBodyArray = [{}]
    const upstreamSummarySizeDistributionBodyArray = [{}]
    const receivingReportSizeDistributionBodyArray = [{}]
    const sizingSummarySizeDistributionPiecesArray = [{ text: 'Total (pieces)', style: 'tableLabel' }]
    const receivingSummarySizeDistributionPiecesArray = [{ text: 'Total (pieces)', style: 'tableLabel' }]
    const upstreamSummarySizeDistributionPiecesArray = [{ text: 'Total (pieces)', style: 'tableLabel' }]
    const receivingReportSizeDistributionPiecesArray = [{ text: 'Total (pieces)', style: 'tableLabel' }]
    const sizingSummarySizeDistributionPercentageArray = [{ text: 'Percentage', style: 'tableLabel' }]
    const receivingSummarySizeDistributionPercentageArray = [{ text: 'Percentage', style: 'tableLabel' }]
    const upstreamSummarySizeDistributionPercentageArray = [{ text: 'Percentage', style: 'tableLabel' }]
    const receivingReportSizeDistributionPercentageArray = [{ text: 'Percentage', style: 'tableLabel' }]
    const receivingReportSizeDistributionCartonArray = [{ text: '18lb Cartons', style: 'tableLabel' }]
    const sizingAccuraciesInlineArray = [
      [
        { text: 'Row size', style: 'tableLabel' },
        { text: 'Unders', style: 'tableLabel', fillColor: 'red', color: 'white', alignment: 'center' },
        { text: 'Correct', style: 'tableLabel', fillColor: 'green', alignment: 'center' },
        { text: 'Overs', style: 'tableLabel', fillColor: 'orange', alignment: 'center' },
      ],
    ]
    const sizingAccuraciesFinishedBoxArray = [
      [
        { text: 'Row size', style: 'tableLabel' },
        { text: 'Unders', style: 'tableLabel', fillColor: 'red', color: 'white', alignment: 'center' },
        { text: 'Correct', style: 'tableLabel', fillColor: 'green', alignment: 'center' },
        { text: 'Overs', style: 'tableLabel', fillColor: 'orange', alignment: 'center' },
      ],
    ]
    const sampleNotesSummarys = general?.sample_notes_summarys?.map((sampleNotes) => {
      return [
        { text: sampleNotes.userName, style: 'tableLabel' },
        { text: sampleNotes.sample_notes, style: 'tableLabelValue' },
      ]
    })

    const ProductionSummaryReceivingSize = [
      [
        { text: '', style: 'tableLabel' },
        { text: '8 Row', style: 'tableLabel' },
        { text: '8.5 Row', style: 'tableLabel' },
        { text: '9 Row', style: 'tableLabel' },
        { text: '9.5 Row', style: 'tableLabel' },
        { text: '10 Row', style: 'tableLabel' },
        { text: '10.5 Row', style: 'tableLabel' },
        { text: '11 Row', style: 'tableLabel' },
        { text: '11.5 Row', style: 'tableLabel' },
        { text: '12 Row', style: 'tableLabel' },
        { text: 'Undersize', style: 'tableLabel' },
      ],
    ]

    const ProductionSummaryUpstreamSize = [
      [
        { text: '', style: 'tableLabel' },
        { text: '8 Row', style: 'tableLabel' },
        { text: '8.5 Row', style: 'tableLabel' },
        { text: '9 Row', style: 'tableLabel' },
        { text: '9.5 Row', style: 'tableLabel' },
        { text: '10 Row', style: 'tableLabel' },
        { text: '10.5 Row', style: 'tableLabel' },
        { text: '11 Row', style: 'tableLabel' },
        { text: '11.5 Row', style: 'tableLabel' },
        { text: '12 Row', style: 'tableLabel' },
        { text: 'Undersize', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryInlineSize = []
    const ProductionSummaryFinishedSize = []

    const ProductionSummaryReceivingQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'Estimated Packout', style: 'tableLabel' },
        { text: 'Minor Defect', style: 'tableLabel' },
        { text: 'Major Defect', style: 'tableLabel' },
        { text: 'Firmness', style: 'tableLabel' },
        { text: 'Brix', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryUpstreamQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'Estimated Packout', style: 'tableLabel' },
        { text: 'Minor Defect', style: 'tableLabel' },
        { text: 'Major Defect', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryInlineQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'Correct', style: 'tableLabel' },
        { text: 'Minor Defect', style: 'tableLabel' },
        { text: 'Major Defect', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryFinishedQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'Correct', style: 'tableLabel' },
        { text: 'Minor Defect', style: 'tableLabel' },
        { text: 'Major Defect', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryGoodInBadQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'G.I.B.%', style: 'tableLabel' },
        { text: 'Doubles & Spurs %', style: 'tableLabel' },
        { text: 'Correct %', style: 'tableLabel' },
      ],
    ]
    const ProductionSummaryPeddlerQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'G.I.P.%', style: 'tableLabel' },
        { text: 'Bad %', style: 'tableLabel' },
        { text: 'Peddler %', style: 'tableLabel' },
      ],
    ]
    const PackTypeSummaryFinishedSize = []
    const PackTypeSummaryFinishedQuality = [
      [
        { text: '', style: 'tableLabel' },
        { text: 'Correct', style: 'tableLabel' },
        { text: 'Minor Defect', style: 'tableLabel' },
        { text: 'Major Defect', style: 'tableLabel' },
      ],
    ]
    if (quality?.colorArray?.length > 0) {
      const sortedArray = quality?.colorArray?.sort((a, b) => a.id - b.id)
      colorBreakdownArray = sortedArray.map((color) => {
        return [
          { text: color.description, style: 'tableLabel' },
          { text: `${parseFloat(color.percentage.replace('%', '')).toFixed(1)} %`, style: 'tableLabelValue' },
        ]
      })
    } else {
      colorBreakdownArray = [DEFAULT_TABLE_ARRAY]
    }
    sizeChartData?.forEach((size, index) => {
      if (index < sizeChartData?.length) receivingReportSizeDistributionWidthsArray.push('*')
      receivingReportSizeDistributionBodyArray.push({
        text: size?.name,
        style: 'tableLabel',
        alignment: 'center',
        fillColor: general.peakSize === size.formattedName ? 'yellow' : null,
      })
      receivingReportSizeDistributionPiecesArray.push({
        text: size.pieces ?? 0,
        style: 'tableLabelValue',
        alignment: 'center',
      })
      receivingReportSizeDistributionPercentageArray.push({
        text: `${size.percentage.toFixed(1)}%`,
        style: 'tableLabelValue',
        alignment: 'center',
      })
    })
    let totalCartons = 0
    sizeCartonData?.forEach((size, index) => {
      totalCartons += Number(size.carton.toFixed())
      receivingReportSizeDistributionCartonArray.push({
        text: size.carton.toFixed(),
        style: 'tableLabel',
        alignment: 'center',
        fillColor: general.peakSize === size.formattedName ? 'yellow' : null,
      })
    })
    const packTypeIdToName = (id_packtype) => {
      const packName = packTypes.find((x) => x.id === id_packtype)
      if (packName) return packName.name
      return ''
    }
    const varietyIdToName = (id_variety) => {
      const varietyName = varieties.find((x) => x.id === id_variety)
      if (varietyName) return varietyName.name
      return ''
    }

    switch (reportType) {
      case 'ReceivingReport':
        if (quality.estimatedPackOut < 80 && quality.estimatedPackOut > 59) packOutColor = 'orange'
        if (quality.estimatedPackOut < 60) packOutColor = 'red'
        content = [
          LOGO,
          {
            text: ['Estimated Pack-Out: ', { text: `${quality.estimatedPackOutPercentage}%`, style: packOutColor }],
            style: 'subHeader',
          },
          { text: `Peak Size: ${general.peakSize}`, style: 'subHeader' },
          { text: 'Receiving Report', style: 'mainHeader' },
          { text: general.facility, style: 'titleHeader' },
          {
            style: 'table',
            table: {
              widths: [75, '*', 75, '*'],
              margin: NO_MARGIN,
              body: [
                [
                  { text: 'Lot #', style: 'tableLabel' },
                  { text: general.lotNumber, style: 'tableLabelValue' },
                  { text: 'Location', style: 'tableLabel' },
                  { text: 'Receiving', style: 'tableLabelValue' },
                ],
                [
                  { text: 'Grower', style: 'tableLabel' },
                  { text: general.grower, style: 'tableLabelValue' },
                  { text: 'Report Date', style: 'tableLabel' },
                  { text: general.reportDate, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Variety', style: 'tableLabel' },
                  { text: general.variety_name, style: 'tableLabelValue' },
                  { text: 'Pick Date', style: 'tableLabel' },
                  { text: general.pickDate, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Bin count', style: 'tableLabel' },
                  { text: general.bin_count, style: 'tableLabelValue' },
                  { text: 'Net weight', style: 'tableLabel' },
                  { text: general.net_weight, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Field Average', style: 'tableLabel' },
                  { text: `${general.field_average}%`, style: 'tableLabelValue' },
                  { text: 'House Average', style: 'tableLabel' },
                  { text: general.house_average, style: 'tableLabelValue' },
                ],
              ],
            },
          },
          { text: 'Quality Information', style: 'titleHeader' },
          {
            style: 'table',
            table: {
              widths: [75, '*', 75, '*'],
              body: [
                [
                  { text: 'Good Fruit', style: 'tableLabel' },
                  { text: `${numberToPercentage(quality.packableFruit)}`, style: 'tableLabelValue' },
                  { text: 'Ave. Firmness', style: 'tableLabel' },
                  { text: quality.aveFirmness, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Minor Defect', style: 'tableLabel' },
                  { text: `${quality.minorDefect}%`, style: 'tableLabelValue' },
                  { text: 'Stem Condition', style: 'tableLabel' },
                  { text: quality.stemCondition, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Major Defect', style: 'tableLabel' },
                  { text: `${quality.majorDefect}%`, style: 'tableLabelValue' },
                  { text: 'Ave. Brix', style: 'tableLabel' },
                  { text: quality.aveBrix, style: 'tableLabelValue' },
                ],
                [
                  { text: '# Samples', style: 'tableLabel' },
                  { text: quality.numberSamplesGrade, style: 'tableLabelValue' },
                  { text: 'Temperature', style: 'tableLabel' },
                  { text: quality.temperature, style: 'tableLabelValue' },
                ],
                [
                  { text: 'Sample Size', style: 'tableLabel' },
                  { text: quality.sampleSizeGrade, style: 'tableLabelValue' },
                  { text: 'Total Cartons', style: 'tableLabel' },
                  { text: totalCartons, style: 'tableLabelValue' },
                ],
              ],
            },
          },
          {
            style: 'table',
            margin: NO_MARGIN,
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  { text: 'Color Breakdown', width: 250, border: NO_BORDER },
                  { text: '', width: 150, border: NO_BORDER },
                  {
                    text: sampleNotesSummarys && sampleNotesSummarys.length > 0 ? 'Notes' : '',
                    width: 250,
                    border: NO_BORDER,
                  },
                ],
              ],
            },
          },
          {
            columns: [
              {
                width: '*',
                style: 'table',
                table: {
                  widths: [75, 80],
                  body: colorBreakdownArray,
                },
              },
              {
                width: '*',
                style: 'table',
                table: {
                  widths: ['*'],
                  body: [[{ image: images[3], width: 150, border: NO_BORDER }]],
                },
              },
              {
                width: '*',
                style: 'table',
                table: {
                  widths: [75, '*'],
                  body: sampleNotesSummarys && sampleNotesSummarys.length > 0 ? sampleNotesSummarys : [[]],
                },
              },
            ],
          },
          {
            style: 'table',
            margin: NO_MARGIN,
            table: {
              widths: ['*', '*'],
              body: [
                [
                  { text: 'Minor Defects', width: 250, border: NO_BORDER, style: 'titleHeader' },
                  { text: 'Major Defects', width: 250, border: NO_BORDER, style: 'titleHeader' },
                ],
              ],
            },
          },
          {
            style: 'table',
            margin: NO_MARGIN,
            table: {
              widths: ['*', '*'],
              body: [
                [
                  { image: images[0], width: 250, border: NO_BORDER },
                  { image: images[1], width: 250, border: NO_BORDER },
                ],
              ],
            },
          },
          { text: 'Size Information', style: 'titleHeader' },
          {
            style: 'table',
            table: {
              widths: [75, '*', 75, '*'],
              body: [
                [
                  { text: '# of Samples', style: 'tableLabel' },
                  { text: size.numberSamples, style: 'tableLabelValue' },
                  { text: 'Sample Size', style: 'tableLabel' },
                  { text: size.sampleSize, style: 'tableLabelValue' },
                ],
              ],
            },
          },
          {
            style: 'table',
            table: {
              widths: receivingReportSizeDistributionWidthsArray,
              body: [
                receivingReportSizeDistributionBodyArray.length > 1 ? receivingReportSizeDistributionBodyArray : [],
                receivingReportSizeDistributionPiecesArray.length > 1 ? receivingReportSizeDistributionPiecesArray : [],
                receivingReportSizeDistributionPercentageArray.length > 1
                  ? receivingReportSizeDistributionPercentageArray
                  : [],
                receivingReportSizeDistributionCartonArray.length > 1 ? receivingReportSizeDistributionCartonArray : [],
              ],
            },
            layout: {
              fillColor(rowIndex) {
                if (rowIndex === 1) return BLUE
                if (rowIndex === 2) return GREEN
                if (rowIndex === 3) return RED
                return null
              },
            },
          },
          { image: images[2], width: 300, border: NO_BORDER, margin: [0, 10, 0, 0], alignment: 'center' },
        ]
        break
      case 'QualitySummary':
        if (general.color.length > 0) {
          colorBreakdownArray = general.color.map((color) => {
            return [
              { text: color.name, style: 'tableLabel' },
              { text: color.value, style: 'tableLabelValue' },
            ]
          })
        } else {
          colorBreakdownArray = [DEFAULT_TABLE_ARRAY]
        }
        upstreamSummaryMinorDefectsArray = parseSummaryDefectDataToPDFData(upstreamSummary.upstreamSummaryMinorDefects)
        upstreamSummaryMajorDefectsArray = parseSummaryDefectDataToPDFData(upstreamSummary.upstreamSummaryMajorDefects)
        inlineSummaryMinorDefectsArray = parseSummaryDefectDataToPDFData(inlineSummary.inlineSummaryMinorDefects)
        inlineSummaryMajorDefectsArray = parseSummaryDefectDataToPDFData(inlineSummary.inlineSummaryMajorDefects)
        finishedBoxSummaryMinorDefectsArray = parseSummaryDefectDataToPDFData(
          finishedBoxSummary.finishedBoxSummaryMinorDefects
        )
        finishedBoxSummaryMajorDefectsArray = parseSummaryDefectDataToPDFData(
          finishedBoxSummary.finishedBoxSummaryMajorDefects
        )

        content = [
          LOGO,
          { text: 'Quality Summary', style: 'mainHeader' },
          {
            columns: [
              {
                width: 320,
                style: 'table',
                margin: [0, 22, 0, 0],
                table: {
                  widths: [80, '*', 80, '*'],

                  body: [
                    [
                      { text: 'Facility', style: 'tableLabel' },
                      { text: general?.facility ?? '-', style: 'tableLabelValue', colSpan: 3 },
                      { text: '' },
                      { text: '' },
                    ],
                    [
                      { text: 'Machine', style: 'tableLabel' },
                      { text: general?.machine ?? '-', style: 'tableLabelValue' },
                      { text: 'Ave. Firmness', style: 'tableLabel' },
                      { text: general?.aveFirmness ?? 0, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Lot #', style: 'tableLabel' },
                      { text: general?.lotNumber ?? '-', style: 'tableLabelValue' },
                      { text: 'Stem Condition', style: 'tableLabel' },
                      { text: general?.stemCondition ?? '-', style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Grower', style: 'tableLabel' },
                      { text: general?.grower ?? '-', style: 'tableLabelValue' },
                      { text: 'Ave. Brix', style: 'tableLabel' },
                      { text: general?.aveBrix ?? '0', style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Variety', style: 'tableLabel' },
                      { text: general?.variety ?? '-', style: 'tableLabelValue' },
                      { text: 'Packable Fruit', style: 'tableLabel' },
                      { text: `${general?.estimatedPO ?? 0}`, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Report Date', style: 'tableLabel' },
                      { text: general?.reportDate ?? '-', style: 'tableLabelValue' },
                      { text: 'Minor Defect', style: 'tableLabel' },
                      { text: `${general?.minorDefect ?? 0}`, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Pick Date', style: 'tableLabel' },
                      { text: general?.pickDate ?? '-', style: 'tableLabelValue' },
                      { text: 'Major Defect', style: 'tableLabel' },
                      { text: `${general?.majorDefect ?? 0}`, style: 'tableLabelValue' },
                    ],
                  ],
                },
              },
              {
                width: 100,
                stack: [
                  { text: 'Color Breakdown', style: 'titleHeader2' },
                  {
                    style: 'table',
                    table: {
                      widths: [80, 80],
                      body: colorBreakdownArray,
                    },
                  },
                ],
              },
            ],
            columnGap: 10,
          },
          {
            columns: [
              {
                width: 210,
                margin: [0, 3, 0, 0],
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} Summary: ${numberToPercentage(
                      ((upstreamSummary?.majorDefect ?? 0) + (upstreamSummary?.minorDefect ?? 0)).toFixed(2)
                    )} (B.I.G)`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    table: {
                      widths: [60, '*', 60, '*'],
                      body: [
                        [
                          { text: '# of Samples', style: 'tableLabel' },
                          { text: upstreamSummary?.numberSamples ?? 0, style: 'tableLabelValue' },
                          { text: 'Sample Size', style: 'tableLabel' },
                          { text: upstreamSummary?.sampleSize ?? 0, style: 'tableLabelValue' },
                        ],
                        [
                          { text: 'Minor Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((upstreamSummary?.minorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                          { text: 'Major Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((upstreamSummary?.majorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              {
                width: 290,
                stack: [
                  {
                    style: 'table',
                    margin: NO_MARGIN,
                    table: {
                      widths: [100, '*', 100, '*'],
                      body: [
                        [
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} Minor Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                          },
                          { text: '', border: NO_BORDER },
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} Major Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                          },
                          { text: '', border: NO_BORDER },
                        ],
                      ],
                    },
                  },
                  {
                    columns: [
                      {
                        width: '*',
                        margin: NO_MARGIN,
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: upstreamSummaryMinorDefectsArray,
                        },
                      },
                      {
                        width: '*',
                        margin: NO_MARGIN,
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: upstreamSummaryMajorDefectsArray,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            columnGap: 10,
          },

          {
            columns: [
              {
                width: 210,
                margin: [0, 6, 0, 0],
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_INL || 'In-Line'} Summary: ${numberToPercentage(
                      ((inlineSummary?.majorDefect ?? 0) + (inlineSummary?.minorDefect ?? 0)).toFixed(2)
                    )} (B.I.G)`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    table: {
                      widths: [60, '*', 60, '*'],
                      body: [
                        [
                          { text: '# of Samples', style: 'tableLabel' },
                          { text: inlineSummary?.numberSamples ?? 0, style: 'tableLabelValue' },
                          { text: 'Sample Size', style: 'tableLabel' },
                          { text: inlineSummary?.sampleSize ?? 0, style: 'tableLabelValue' },
                        ],
                        [
                          { text: 'Minor Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((inlineSummary?.minorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                          { text: 'Major Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((inlineSummary?.majorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              {
                width: 290,
                margin: NO_MARGIN,
                stack: [
                  {
                    style: 'table',
                    margin: NO_MARGIN,
                    table: {
                      widths: [100, '*', 100, '*'],
                      body: [
                        [
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_INL || 'In-Line'} Minor Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                          },
                          { text: '', border: NO_BORDER },
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_INL || 'In-Line'} Major Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                          },
                          { text: '', border: NO_BORDER },
                        ],
                      ],
                    },
                  },
                  {
                    columns: [
                      {
                        width: '*',
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: inlineSummaryMinorDefectsArray,
                        },
                      },
                      {
                        width: '*',
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: inlineSummaryMajorDefectsArray,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            columnGap: 10,
          },

          {
            columns: [
              {
                width: 210,
                margin: NO_MARGIN,
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Summary: ${numberToPercentage(
                      ((finishedBoxSummary?.minorDefect ?? 0) + (finishedBoxSummary?.majorDefect ?? 0)).toFixed(2)
                    )} (B.I.G)`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    table: {
                      widths: [60, '*', 60, '*'],
                      body: [
                        [
                          { text: '# of Samples', style: 'tableLabel' },
                          { text: finishedBoxSummary?.numberSamples ?? 0, style: 'tableLabelValue' },
                          { text: 'Sample Size', style: 'tableLabel' },
                          { text: finishedBoxSummary?.sampleSize ?? 0, style: 'tableLabelValue' },
                        ],
                        [
                          { text: 'Minor Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((finishedBoxSummary?.minorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                          { text: 'Major Defect', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage((finishedBoxSummary?.majorDefect ?? 0).toFixed(2))}`,
                            style: 'tableLabelValue',
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              {
                width: 290,
                margin: NO_MARGIN,
                stack: [
                  {
                    style: 'table',
                    table: {
                      widths: [100, '*', 100, '*'],
                      body: [
                        [
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Minor Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                            margin: NO_MARGIN,
                          },
                          { text: '', border: NO_BORDER, margin: NO_MARGIN },
                          {
                            text: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Major Defects`,
                            style: 'titleHeader2',
                            border: NO_BORDER,
                            margin: NO_MARGIN,
                          },
                          { text: '', border: NO_BORDER, margin: NO_MARGIN },
                        ],
                      ],
                    },
                  },
                  {
                    columns: [
                      {
                        width: '*',
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: finishedBoxSummaryMinorDefectsArray,
                        },
                      },
                      {
                        width: '*',
                        style: 'table',
                        table: {
                          widths: [80, '*'],
                          body: finishedBoxSummaryMajorDefectsArray,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            columnGap: 10,
          },
          {
            columns: [
              {
                width: 297,
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_CUL || 'Culls'} Summary: ${numberToPercentage(
                      cullsSummary?.cullSummary?.percentage ?? 0
                    )} (G.I.B)`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    table: {
                      widths: [80, 115],
                      body: [
                        [
                          { text: 'Sample Size', style: 'tableLabel' },
                          { text: cullsSummary?.sampleSize ?? 0, style: 'tableLabelValue' },
                        ],
                        [
                          { text: 'Culls', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage(cullsSummary?.cullsCulls?.percentage ?? 0)}`,
                            style: 'tableLabelValue',
                          },
                        ],
                        [
                          { text: 'Dubs/Spur', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage(cullsSummary?.cullsDoubleSpurs?.percentage ?? 0)}`,
                            style: 'tableLabelValue',
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
              {
                width: 250,
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_PED || 'Peddler'} Summary: ${numberToPercentage(
                      peddlersSummary?.peddlerGood?.percentage ?? 0
                    )} (G.I.P)`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    table: {
                      widths: [80, 115],
                      body: [
                        [
                          { text: 'Sample Size', style: 'tableLabel' },
                          { text: peddlersSummary?.sampleSize ?? 0, style: 'tableLabelValue' },
                        ],
                        [
                          { text: 'Peddler', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage(peddlersSummary?.peddlerPeddler?.percentage ?? 0)}`,
                            style: 'tableLabelValue',
                          },
                        ],
                        [
                          { text: 'Bad', style: 'tableLabel' },
                          {
                            text: `${numberToPercentage(peddlersSummary?.peddlerBad?.percentage ?? 0)}`,
                            style: 'tableLabelValue',
                          },
                        ],
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ]
        break
      case 'SizingSummary':
        sizeDistribution?.data?.forEach((size, index) => {
          if (index < sizeDistribution.data.length) sizingSummarySizeDistributionWidthsArray.push('*')
          sizingSummarySizeDistributionBodyArray.push({
            text: size?.numberName === 13 ? 'Unders' : size?.numberName,
            style: 'tableLabel7',
            alignment: 'center',
            fillColor: general.peakSize === size.name ? 'yellow' : null,
          })
          sizingSummarySizeDistributionPiecesArray.push({
            text: size.pieces ?? 0,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
          sizingSummarySizeDistributionPercentageArray.push({
            text: `${numberToPercentage(size.percentage)}`,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
        })
        receiving?.data?.forEach((size, index) => {
          if (index < receiving.data.length) receivingSummarySizeDistributionWidthsArray.push('*')
          receivingSummarySizeDistributionBodyArray.push({
            text: size?.numberName === 13 ? 'Unders' : size?.numberName,
            style: 'tableLabel7',
            alignment: 'center',
            fillColor: general.peakSize === size.name ? 'yellow' : null,
          })
          receivingSummarySizeDistributionPiecesArray.push({
            text: size.pieces ?? 0,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
          receivingSummarySizeDistributionPercentageArray.push({
            text: `${numberToPercentage(size.percentage)}`,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
        })
        upstream?.data?.forEach((size, index) => {
          if (index < upstream.data.length) upstreamSummarySizeDistributionWidthsArray.push('*')
          upstreamSummarySizeDistributionBodyArray.push({
            text: size?.numberName === 13 ? 'Unders' : size?.numberName,
            style: 'tableLabelValue7',
            alignment: 'center',
            fillColor: general.peakSize === size.name ? 'yellow' : null,
          })
          upstreamSummarySizeDistributionPiecesArray.push({
            text: size.pieces ?? 0,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
          upstreamSummarySizeDistributionPercentageArray.push({
            text: `${numberToPercentage(size.percentage)}`,
            style: 'tableLabelValue7',
            alignment: 'center',
          })
        })
        sizingAccuracies?.inline?.rowSize.forEach((size) => {
          sizingAccuraciesInlineArray.push([
            { text: size?.id, style: 'tableLabelValue7', alignment: 'right' },
            {
              text: `${numberToPercentage(size?.unders || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
            {
              text: `${numberToPercentage(size?.correct || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
            {
              text: `${numberToPercentage(size?.overs || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
          ])
        })
        sizingAccuracies?.finishedBox?.rowSize.forEach((size) => {
          sizingAccuraciesFinishedBoxArray.push([
            { text: size?.id, style: 'tableLabelValue7', alignment: 'right' },
            {
              text: `${numberToPercentage(size?.unders || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
            {
              text: `${numberToPercentage(size?.correct || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
            {
              text: `${numberToPercentage(size?.overs || 0)}`,
              style: 'tableLabelValue7',
              alignment: 'center',
            },
          ])
        })

        content = [
          LOGO,
          { text: 'Sizing Summary', style: 'mainHeader' },
          { text: 'Size Distribution', style: 'titleHeader' },
          {
            columns: [
              {
                style: 'table',
                width: 220,
                table: {
                  widths: [70, 120],
                  body: [
                    [
                      { text: 'Facility', style: 'tableLabel7' },
                      { text: general.facility, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Machine', style: 'tableLabel' },
                      { text: general.machine, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Lot #', style: 'tableLabel7' },
                      { text: general.lotNumber, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Grower', style: 'tableLabel7' },
                      { text: general.grower, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Variety', style: 'tableLabel7' },
                      { text: general.variety, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Report Date', style: 'tableLabel7' },
                      { text: general.reportDate, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Pick Date', style: 'tableLabel7' },
                      { text: general.pickDate, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Peak Size', style: 'tableLabel7' },
                      { text: general.peakSize, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: '# of Samples', style: 'tableLabel7' },
                      { text: general.numberSamples, style: 'tableLabelValue7' },
                    ],
                    [
                      { text: 'Sample Size', style: 'tableLabel7' },
                      { text: general.sampleSize, style: 'tableLabelValue7' },
                    ],
                  ],
                },
              },
              {
                width: 320,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [320],
                  body: [[{ image: images[0], width: 320, border: NO_BORDER }]],
                },
              },
            ],
          },

          {
            style: 'table',
            margin: [0, 5, 0, 0],
            table: {
              widths: sizingSummarySizeDistributionWidthsArray,
              body: [
                sizingSummarySizeDistributionBodyArray.length > 1 ? sizingSummarySizeDistributionBodyArray : [],
                sizingSummarySizeDistributionPiecesArray.length > 1 ? sizingSummarySizeDistributionPiecesArray : [],
                sizingSummarySizeDistributionPercentageArray.length > 1
                  ? sizingSummarySizeDistributionPercentageArray
                  : [],
              ],
            },
            layout: {
              fillColor(rowIndex) {
                if (rowIndex === 1) return BLUE
                if (rowIndex === 2) return GREEN
                return null
              },
            },
          },
          {
            style: 'table',
            margin: [0, 5, 0, 0],
            table: {
              widths: [70, 80],
              body: [
                [
                  { text: 'Location', style: 'tableLabel7' },
                  { text: desTemplates?.TEMPLATE_LOC_REC || receiving.location, style: 'tableLabelValue7' },
                ],
                [
                  { text: '# of Samples', style: 'tableLabel7' },
                  { text: receiving.numberSamples, style: 'tableLabelValue7' },
                ],
                [
                  { text: 'Sample Size', style: 'tableLabel7' },
                  { text: receiving.sampleSize, style: 'tableLabelValue7' },
                ],
              ],
            },
          },
          {
            style: 'table',
            margin: [0, 5, 0, 0],
            table: {
              widths: receivingSummarySizeDistributionWidthsArray,
              body: [
                receivingSummarySizeDistributionBodyArray.length > 1 ? receivingSummarySizeDistributionBodyArray : [],
                receivingSummarySizeDistributionPiecesArray.length > 1
                  ? receivingSummarySizeDistributionPiecesArray
                  : [],
                receivingSummarySizeDistributionPercentageArray.length > 1
                  ? receivingSummarySizeDistributionPercentageArray
                  : [],
              ],
            },
            layout: {
              fillColor(rowIndex) {
                if (rowIndex === 1) return BLUE
                if (rowIndex === 2) return GREEN
                return null
              },
            },
          },
          {
            style: 'table',
            margin: [0, 5, 0, 0],
            table: {
              widths: [70, 80],
              body: [
                [
                  { text: 'Location', style: 'tableLabel7' },
                  { text: desTemplates?.TEMPLATE_LOC_UPS || upstream.location, style: 'tableLabelValue7' },
                ],
                [
                  { text: '# of Samples', style: 'tableLabel7' },
                  { text: upstream.numberSamples, style: 'tableLabelValue7' },
                ],
                [
                  { text: 'Sample Size', style: 'tableLabel' },
                  { text: upstream.sampleSize, style: 'tableLabelValue7' },
                ],
              ],
            },
          },
          {
            style: 'table',
            margin: [0, 5, 0, 0],
            table: {
              widths: upstreamSummarySizeDistributionWidthsArray,
              body: [
                upstreamSummarySizeDistributionBodyArray.length > 1 ? upstreamSummarySizeDistributionBodyArray : [],
                upstreamSummarySizeDistributionPiecesArray.length > 1 ? upstreamSummarySizeDistributionPiecesArray : [],
                upstreamSummarySizeDistributionPercentageArray.length > 1
                  ? upstreamSummarySizeDistributionPercentageArray
                  : [],
              ],
            },
            layout: {
              fillColor(rowIndex) {
                if (rowIndex === 1) return BLUE
                if (rowIndex === 2) return GREEN
                return null
              },
            },
          },
          { text: 'Sizing Accuracies', style: 'titleHeaderNoMG' },
          {
            columns: [
              {
                width: 107,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [40, 47],
                  body: [
                    [
                      { text: 'Location', style: 'tableLabel' },
                      {
                        text: desTemplates?.TEMPLATE_LOC_INL || sizingAccuracies?.inline?.location || 'In-Line',
                        style: 'tableLabelValue',
                      },
                    ],
                    [
                      { text: '# of Samples', style: 'tableLabel' },
                      { text: sizingAccuracies?.inline?.numberSamples || 0, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Sample Size', style: 'tableLabel' },
                      {
                        text: sizingAccuracies?.inline?.sampleSize || 0,
                        style: 'tableLabelValue',
                      },
                    ],
                  ],
                },
              },
              {
                width: 160,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [30, 30, 30, 30],
                  body: sizingAccuraciesInlineArray,
                },
              },
              {
                width: 108,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [40, 48],
                  body: [
                    [
                      { text: 'Location', style: 'tableLabel' },
                      {
                        text:
                          desTemplates?.TEMPLATE_LOC_FIN || sizingAccuracies?.finishedBox?.location || 'Finished Box',
                        style: 'tableLabelValue',
                      },
                    ],
                    [
                      { text: '# of Samples', style: 'tableLabel' },
                      { text: sizingAccuracies?.finishedBox?.numberSamples || 0, style: 'tableLabelValue' },
                    ],
                    [
                      { text: 'Sample Size', style: 'tableLabel' },
                      {
                        text: sizingAccuracies?.finishedBox?.sampleSize || 0,
                        style: 'tableLabelValue',
                      },
                    ],
                  ],
                },
              },
              {
                width: 160,
                style: 'margin: NO_MARGIN',
                margin: NO_MARGIN,
                table: {
                  widths: [30, 30, 30, 30],
                  body: sizingAccuraciesFinishedBoxArray,
                },
              },
            ],
            columnGap: 1,
          },
          {
            columns: [
              {
                width: 260,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [260],
                  body: [[{ image: images[1], width: 260, border: NO_BORDER }]],
                },
              },

              {
                width: 260,
                style: 'table',
                margin: NO_MARGIN,
                table: {
                  widths: [260],
                  body: [[{ image: images[2], width: 260, border: NO_BORDER }]],
                },
              },
            ],
            columnGap: 1,
          },
        ]
        break
      case 'ProductionSizingSummary':
        {
          const subHeader = []
          if (machineName) {
            subHeader.push({ text: `Machine: ${machineName}`, style: 'subHeader' })
          }
          subHeader.push({ text: `${dateReport}`, style: 'subHeader' })
          const SummaryContent = [LOGO, ...subHeader]

          if (
            productionSummaryData.length > 0 &&
            productionSummaryData.some((x) =>
              Object.keys(x.productionSizingSummary).some((z) => x.productionSizingSummary[z])
            )
          ) {
            SummaryContent.push({ text: `Production Sizing Summary`, style: 'mainHeader' })
          }

          productionSummaryData.forEach((item, index) => {
            const ProductionSummaryReceivingSizeItem = [{ text: `${varietiesName[index]}`, style: 'tableLabel' }]
            item?.productionSizingSummary?.receivingSizing?.forEach((receivingSizingItem) => {
              if (receivingSizingItem.name !== 'Oversize') {
                const item = {
                  text: `${numberToPercentage(((receivingSizingItem?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                }

                ProductionSummaryReceivingSizeItem.push(item)
              }
            })
            const ProductionSummaryUpstreamSizeItem = [{ text: `${varietiesName[index]}`, style: 'tableLabel' }]
            item?.productionSizingSummary?.upstreamSizing?.forEach((upstreamSizingItem) => {
              if (upstreamSizingItem.name !== 'Oversize') {
                const item = {
                  text: `${numberToPercentage(((upstreamSizingItem?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                }

                ProductionSummaryUpstreamSizeItem.push(item)
              }
            })
            const ProductionSummaryInlineSizeTable = [
              [
                { text: ` `, style: 'tableLabel' },
                { text: 'Under', style: 'tableLabel' },
                { text: 'Correct', style: 'tableLabel' },
                { text: 'Over', style: 'tableLabel' },
              ],
            ]

            let totalCorrect = 0
            let totalUnder = 0
            let totalOver = 0
            let countInline = 0
            item?.productionSizingSummary?.inLineSizing?.correct?.forEach((itemCorrect, i) => {
              if (
                itemCorrect.name !== 'Undersize' &&
                itemCorrect.name !== 'Oversize' &&
                (item?.productionSizingSummary?.inLineSizing?.under[i]?.percentage !== 0 ||
                  itemCorrect?.percentage !== 0 ||
                  item?.productionSizingSummary?.inLineSizing?.over[i]?.percentage !== 0)
              ) {
                const ProductionSummaryInlineSizeItem = []
                ProductionSummaryInlineSizeItem.push({
                  text: `${itemCorrect?.name}`,
                  style: 'tableLabel',
                })
                ProductionSummaryInlineSizeItem.push({
                  text: `${numberToPercentage(
                    ((item?.productionSizingSummary?.inLineSizing?.under[i]?.percentage || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                })
                ProductionSummaryInlineSizeItem.push({
                  text: `${numberToPercentage(((itemCorrect?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                })
                ProductionSummaryInlineSizeItem.push({
                  text: `${numberToPercentage(
                    ((item?.productionSizingSummary?.inLineSizing?.over[i]?.percentage || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                })
                totalCorrect += itemCorrect?.percentage || 0
                totalUnder += item?.productionSizingSummary?.inLineSizing?.under[i]?.percentage || 0
                totalOver += item?.productionSizingSummary?.inLineSizing?.over[i]?.percentage || 0
                countInline++
                ProductionSummaryInlineSizeTable.push(ProductionSummaryInlineSizeItem)
              }
            })

            ProductionSummaryInlineSizeTable.push([
              {
                text: 'Total',
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(((totalUnder * 100) / (countInline === 0 ? 1 : countInline)).toFixed(2))}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalCorrect * 100) / (countInline === 0 ? 1 : countInline)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(((totalOver * 100) / (countInline === 0 ? 1 : countInline)).toFixed(2))}`,
                style: 'tableLabel',
              },
            ])
            const tableInline = {
              key: `${desTemplates?.TEMPLATE_LOC_INL || 'In-Line'} Size Accuracy ${varietiesName[index]} `,
              value: ProductionSummaryInlineSizeTable,
            }

            let totalFinishCorrect = 0
            let totalFinishUnder = 0
            let totalFinishOver = 0
            let countFinish = 0
            const ProductionSummaryFinishSizeTable = [
              [
                { text: ` `, style: 'tableLabel' },
                { text: 'Under', style: 'tableLabel' },
                { text: 'Correct', style: 'tableLabel' },
                { text: 'Over', style: 'tableLabel' },
              ],
            ]

            item?.productionSizingSummary?.finishBoxSizing?.correct?.forEach((itemCorrect, i) => {
              if (
                itemCorrect.name !== 'Undersize' &&
                itemCorrect.name !== 'Oversize' &&
                (item?.productionSizingSummary?.finishBoxSizing?.under[i]?.percentage !== 0 ||
                  itemCorrect?.percentage !== 0 ||
                  item?.productionSizingSummary?.finishBoxSizing?.over[i]?.percentage !== 0)
              ) {
                const ProductionSummaryFinishSizeItem = []
                ProductionSummaryFinishSizeItem.push({
                  text: `${itemCorrect?.name}`,
                  style: 'tableLabel',
                })
                ProductionSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(
                    ((item?.productionSizingSummary?.finishBoxSizing?.under[i]?.percentage || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                })
                ProductionSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(((itemCorrect?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                })
                ProductionSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(
                    ((item?.productionSizingSummary?.finishBoxSizing?.over[i]?.percentage || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                })
                totalFinishCorrect += itemCorrect?.percentage || 0
                totalFinishUnder += item?.productionSizingSummary?.finishBoxSizing?.under[i]?.percentage || 0
                totalFinishOver += item?.productionSizingSummary?.finishBoxSizing?.over[i]?.percentage || 0
                countFinish++
                ProductionSummaryFinishSizeTable.push(ProductionSummaryFinishSizeItem)
              }
            })
            ProductionSummaryFinishSizeTable.push([
              {
                text: 'Total',
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishUnder * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishCorrect * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishOver * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
            ])
            const tableFinish = {
              key: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Size Accuracy ${varietiesName[index]} `,
              value: ProductionSummaryFinishSizeTable,
            }

            if (ProductionSummaryReceivingSizeItem.length > 1) {
              ProductionSummaryReceivingSize.push(ProductionSummaryReceivingSizeItem)
            }
            if (ProductionSummaryUpstreamSizeItem.length > 1) {
              ProductionSummaryUpstreamSize.push(ProductionSummaryUpstreamSizeItem)
            }
            if (ProductionSummaryInlineSizeTable.length > 2) {
              ProductionSummaryInlineSize.push(tableInline)
            }
            if (ProductionSummaryFinishSizeTable.length > 2) {
              ProductionSummaryFinishedSize.push(tableFinish)
            }
          })

          const totalRecvingSizingRow = [{ text: 'Total', style: 'tableLabel' }]

          totalRecvingSizing.forEach((totalValue) => {
            const item = {
              text: `${numberToPercentage(((totalValue?.value || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            }
            totalRecvingSizingRow.push(item)
          })
          if (totalRecvingSizingRow.length > 1) {
            ProductionSummaryReceivingSize.push(totalRecvingSizingRow)
          }

          const totalUpstreamSizingRow = [{ text: 'Total', style: 'tableLabel' }]
          totalUpstreamSizing.forEach((totalValue) => {
            const item = {
              text: `${numberToPercentage(((totalValue?.value || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            }
            totalUpstreamSizingRow.push(item)
          })
          if (totalUpstreamSizingRow.length > 1) {
            ProductionSummaryUpstreamSize.push(totalUpstreamSizingRow)
          }
          if (ProductionSummaryReceivingSize.length > 1) {
            SummaryContent.push(
              { text: `${desTemplates?.TEMPLATE_LOC_REC || 'Receving'} Size Distribution`, style: 'titleHeader2' },
              {
                style: 'table',
                table: {
                  widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                  body: ProductionSummaryReceivingSize.length > 1 ? ProductionSummaryReceivingSize : [],
                  dontBreakRows: true,
                },
              }
            )
          }
          if (ProductionSummaryUpstreamSize.length > 1) {
            SummaryContent.push(
              { text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} Size Distribution`, style: 'titleHeader2' },
              {
                style: 'table',

                table: {
                  widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                  body: ProductionSummaryUpstreamSize.length > 1 ? ProductionSummaryUpstreamSize : [],
                  dontBreakRows: true,
                },
              }
            )
          }

          let inlineTables3column = []
          const inlineTables = []
          ProductionSummaryInlineSize.forEach((inlineTable, index) => {
            if (index % 3 === 0) {
              if (inlineTables3column.length > 0 && index !== 0) {
                inlineTables.push(inlineTables3column)

                inlineTables3column = []
              }
              inlineTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${inlineTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: inlineTable.value.length > 2 ? inlineTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            } else {
              inlineTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${inlineTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: inlineTable.value.length > 2 ? inlineTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            }
            if (index === ProductionSummaryInlineSize.length - 1) {
              inlineTables.push(inlineTables3column)
            }
          })
          inlineTables.forEach((inline3Table) => {
            SummaryContent.push({ columns: inline3Table })
          })

          let finishTables3column = []
          const finishTables = []
          ProductionSummaryFinishedSize.forEach((finishTable, index) => {
            if (index % 3 === 0) {
              if (finishTables3column.length > 0 && index !== 0) {
                finishTables.push(finishTables3column)

                finishTables3column = []
              }
              finishTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${finishTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: finishTable.value.length > 2 ? finishTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            } else {
              finishTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${finishTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: finishTable.value.length > 2 ? finishTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            }
            if (index === ProductionSummaryFinishedSize.length - 1) {
              finishTables.push(finishTables3column)
            }
          })
          finishTables.forEach((finish3Table) => {
            SummaryContent.push({ columns: finish3Table })
          })

          content = SummaryContent
        }
        break
      case 'ProductionQualitySummary':
        {
          const subHeader = []
          if (machineName) {
            subHeader.push({ text: `Machine: ${machineName}`, style: 'subHeader' })
          }
          subHeader.push({ text: `${dateReport}`, style: 'subHeader' })
          const SummaryContent = [LOGO, ...subHeader]
          productionSummaryData.forEach((item, index) => {
            const ProductionSummaryReceivingQualityItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.receivingQuality !== null) {
              ProductionSummaryReceivingQualityItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.receivingQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.receivingQuality?.minorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.receivingQuality?.majorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${(item?.productionQualitySummary?.receivingQuality?.firmness || 0).toFixed(2)}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${(item?.productionQualitySummary?.receivingQuality?.brix || 0).toFixed(2)}`,
                  style: 'tableLabelValue',
                }
              )
            }
            const ProductionSummaryUpstreamQualityItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.upstreamQuality !== null) {
              ProductionSummaryUpstreamQualityItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.upstreamQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.upstreamQuality?.minorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.upstreamQuality?.majorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                }
              )
            }
            const ProductionSummaryInLineQualityItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.inLineQuality !== null) {
              ProductionSummaryInLineQualityItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.inLineQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.inLineQuality?.minorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.inLineQuality?.majorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                }
              )
            }
            const ProductionSummaryFinishBoxQualityItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.finishBoxQuality !== null) {
              ProductionSummaryFinishBoxQualityItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.finishBoxQuality?.estimatedPackOut || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.finishBoxQuality?.minorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.finishBoxQuality?.majorDefect || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                }
              )
            }
            const ProductionSummaryGoodInBadQualityItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.goodInBadQuality !== null) {
              ProductionSummaryGoodInBadQualityItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.goodInBadQuality?.goodInBad || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.goodInBadQuality?.dobleSpurs || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.goodInBadQuality?.correct || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                }
              )
            }
            const ProductionSummaryPeddlerItem = []
            if (productionSummaryData.length > 0 && item?.productionQualitySummary?.peddlerQuality !== null) {
              ProductionSummaryPeddlerItem.push(
                { text: `${varietiesName[index]}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.peddlerQuality?.good || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.peddlerQuality?.bad || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(
                    ((item?.productionQualitySummary?.peddlerQuality?.peddler || 0) * 100).toFixed(2)
                  )}`,
                  style: 'tableLabelValue',
                }
              )
            }

            if (ProductionSummaryReceivingQualityItem.length > 1) {
              ProductionSummaryReceivingQuality.push(ProductionSummaryReceivingQualityItem)
            }
            if (ProductionSummaryUpstreamQualityItem.length > 1) {
              ProductionSummaryUpstreamQuality.push(ProductionSummaryUpstreamQualityItem)
            }
            if (ProductionSummaryInLineQualityItem.length > 1) {
              ProductionSummaryInlineQuality.push(ProductionSummaryInLineQualityItem)
            }
            if (ProductionSummaryFinishBoxQualityItem.length > 1) {
              ProductionSummaryFinishedQuality.push(ProductionSummaryFinishBoxQualityItem)
            }
            if (ProductionSummaryGoodInBadQualityItem.length > 1) {
              ProductionSummaryGoodInBadQuality.push(ProductionSummaryGoodInBadQualityItem)
            }
            if (ProductionSummaryPeddlerItem.length > 1) {
              ProductionSummaryPeddlerQuality.push(ProductionSummaryPeddlerItem)
            }
          })

          ProductionSummaryReceivingQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalRecvingQuality?.totalEpo || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalRecvingQuality?.totalMinDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalRecvingQuality?.totalMajDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${(totalRecvingQuality.totalFirm || 0).toFixed(2)}`,
              style: 'tableLabel',
            },
            {
              text: `${(totalRecvingQuality.totalBrix || 0).toFixed(2)}`,
              style: 'tableLabel',
            },
          ])

          ProductionSummaryUpstreamQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalUpstreamQuality?.totalEpo || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalUpstreamQuality?.totalMinDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalUpstreamQuality?.totalMajDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])

          ProductionSummaryInlineQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalInlineQuality?.totalEpo || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalInlineQuality?.totalMinDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalInlineQuality?.totalMajDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])

          ProductionSummaryFinishedQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalEpo || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalMinDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalMajDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])
          ProductionSummaryGoodInBadQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalGIBQuality?.totalGoodInBad || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalGIBQuality?.totalDobleSpurs || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalGIBQuality?.totalCorrect || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])
          ProductionSummaryPeddlerQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalGIPQuality?.totalGood || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalGIPQuality?.totalBad || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalGIPQuality?.totalPeddler || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])

          if (
            productionSummaryData.length > 0 &&
            productionSummaryData.some((x) =>
              Object.keys(x.productionQualitySummary).some((z) => x.productionQualitySummary[z])
            )
          ) {
            SummaryContent.push({ text: `Production Quality Summary`, style: 'mainHeader2' })
          }
          if (ProductionSummaryReceivingQuality.length > 2) {
            SummaryContent.push(
              { text: `${desTemplates?.TEMPLATE_LOC_REC || 'Receiving'} Quality`, style: 'titleHeader2' },
              {
                style: 'table',

                table: {
                  widths: ['*', '*', '*', '*', '*', '*'],
                  body: ProductionSummaryReceivingQuality.length > 2 ? ProductionSummaryReceivingQuality : [],
                  dontBreakRows: true,
                },
              }
            )
          }
          if (ProductionSummaryUpstreamQuality.length > 2) {
            SummaryContent.push(
              { text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} Quality`, style: 'titleHeader2' },
              {
                style: 'table',
                table: {
                  widths: ['*', '*', '*', '*'],
                  body: ProductionSummaryUpstreamQuality.length > 2 ? ProductionSummaryUpstreamQuality : [],
                  dontBreakRows: true,
                },
              }
            )
          }
          const columnsCol = []
          const columnsGIPGIBCol = []
          if (ProductionSummaryInlineQuality.length > 2) {
            columnsCol.push([
              {
                width: 150,
                style: 'marginr10',
                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_INL || 'In-Line'} Quality`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    width: [],
                    table: {
                      body: ProductionSummaryInlineQuality.length > 2 ? ProductionSummaryInlineQuality : [],
                      dontBreakRows: true,
                    },
                  },
                ],
              },
            ])
          }
          if (ProductionSummaryFinishedQuality.length > 2) {
            columnsCol.push([
              {
                width: 150,

                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Quality`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    width: [],
                    table: {
                      body: ProductionSummaryFinishedQuality.length > 2 ? ProductionSummaryFinishedQuality : [],
                      dontBreakRows: true,
                    },
                  },
                ],
              },
            ])
          }
          if (ProductionSummaryGoodInBadQuality.length > 2) {
            columnsGIPGIBCol.push([
              {
                width: 150,
                stack: [
                  {
                    text: 'Good In Bad',
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    width: [],
                    table: {
                      body: ProductionSummaryGoodInBadQuality.length > 2 ? ProductionSummaryGoodInBadQuality : [],
                      dontBreakRows: true,
                    },
                  },
                ],
              },
            ])
          }
          if (ProductionSummaryPeddlerQuality.length > 2) {
            columnsGIPGIBCol.push([
              {
                width: 150,
                stack: [
                  {
                    text: `Good In ${desTemplates?.TEMPLATE_LOC_PED || 'Peddler'}`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    width: [],
                    table: {
                      body: ProductionSummaryPeddlerQuality.length > 2 ? ProductionSummaryPeddlerQuality : [],
                      dontBreakRows: true,
                    },
                  },
                ],
              },
            ])
          }

          if (columnsCol.length > 0) {
            SummaryContent.push({
              columns: columnsCol,
            })
          }
          if (columnsGIPGIBCol.length > 0) {
            SummaryContent.push({
              columns: columnsGIPGIBCol,
            })
          }

          content = SummaryContent
        }
        break
      case 'DefectSummary':
        {
          const subHeader = []
          const LoactionDefect = []

          if (machineName) {
            subHeader.push({ text: `Machine: ${machineName}`, style: 'subHeader' })
          }
          subHeader.push({ text: `${dateReport}`, style: 'subHeader' })
          const SummaryContent = [LOGO, ...subHeader, { text: 'Defect Summary', style: 'mainHeader' }]

          if (
            receivingQuality.minorDefect !== null &&
            receivingQuality.majorDefect !== null &&
            receivingQuality.totalLocations !== 0
          ) {
            LoactionDefect.push({
              text: `${desTemplates?.TEMPLATE_LOC_REC || 'Receving'} ${numberToPercentage(
                Number((receivingQuality.totalLocations * 100).toFixed(2))
              )}`,
              style: 'titleHeader',
            })
          }
          const receivingTables = []
          const receivingDefectMinor = [
            [
              { text: 'Minor Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (receivingQuality.minorDefect !== null) {
            receivingQuality?.minorDefect?.forEach((defect) => {
              const { name, value } = defect
              receivingDefectMinor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          const receivingDefectMajor = [
            [
              { text: 'Major Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (receivingQuality.majorDefect !== null) {
            receivingQuality?.majorDefect?.forEach((defect) => {
              const { name, value } = defect
              receivingDefectMajor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          if (receivingDefectMinor.length > 1) {
            receivingTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: receivingDefectMinor,
                dontBreakRows: true,
              },
            })
          }
          if (receivingDefectMajor.length > 1) {
            receivingTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: receivingDefectMajor,
                dontBreakRows: true,
              },
            })
          }
          LoactionDefect.push({ columns: receivingTables })

          if (
            upstreamQuality.minorDefect !== null &&
            upstreamQuality.majorDefect !== null &&
            upstreamQuality.totalLocations !== 0
          ) {
            LoactionDefect.push({
              text: `${desTemplates?.TEMPLATE_LOC_UPS || 'Upstream'} ${numberToPercentage(
                Number((upstreamQuality.totalLocations * 100).toFixed(2))
              )}`,
              style: 'titleHeader',
            })
          }
          const upstreamTables = []
          const upstreamDefectMinor = [
            [
              { text: 'Minor Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (upstreamQuality.minorDefect !== null) {
            upstreamQuality?.minorDefect?.forEach((defect) => {
              const { name, value } = defect
              upstreamDefectMinor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          const upstreamDefectMajor = [
            [
              { text: 'Major Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (upstreamQuality.majorDefect !== null) {
            upstreamQuality?.majorDefect?.forEach((defect) => {
              const { name, value } = defect
              upstreamDefectMajor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          if (upstreamDefectMinor.length > 1) {
            upstreamTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: upstreamDefectMinor,
                dontBreakRows: true,
              },
            })
          }
          if (upstreamDefectMajor.length > 1) {
            upstreamTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: upstreamDefectMajor,
                dontBreakRows: true,
              },
            })
          }
          LoactionDefect.push({ columns: upstreamTables })

          if (
            inLineQuality.minorDefect !== null &&
            inLineQuality.majorDefect !== null &&
            inLineQuality.totalLocations !== 0
          ) {
            LoactionDefect.push({
              text: `${desTemplates?.TEMPLATE_LOC_INL || 'InLine'} ${numberToPercentage(
                Number((inLineQuality.totalLocations * 100).toFixed(2))
              )}`,
              style: 'titleHeader',
            })
          }
          const inLineTables = []
          const inLineDefectMinor = [
            [
              { text: 'Minor Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (inLineQuality.minorDefect !== null) {
            inLineQuality?.minorDefect?.forEach((defect) => {
              const { name, value } = defect
              inLineDefectMinor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          const inLineDefectMajor = [
            [
              { text: 'Major Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (inLineQuality.majorDefect !== null) {
            inLineQuality?.majorDefect?.forEach((defect) => {
              const { name, value } = defect
              inLineDefectMajor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          if (inLineDefectMinor.length > 1) {
            inLineTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: inLineDefectMinor,
                dontBreakRows: true,
              },
            })
          }
          if (inLineDefectMajor.length > 1) {
            inLineTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: inLineDefectMajor,
                dontBreakRows: true,
              },
            })
          }
          LoactionDefect.push({ columns: inLineTables })

          if (
            finishBoxQuality.minorDefect !== null &&
            finishBoxQuality.majorDefect !== null &&
            finishBoxQuality.totalLocations !== 0
          ) {
            LoactionDefect.push({
              text: `${desTemplates?.TEMPLATE_LOC_FIN || 'FinishBox'} ${numberToPercentage(
                Number((finishBoxQuality.totalLocations * 100).toFixed(2))
              )}`,
              style: 'titleHeader',
            })
          }
          const finishBoxTables = []
          const finishBoxDefectMinor = [
            [
              { text: 'Minor Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (finishBoxQuality.minorDefect !== null) {
            finishBoxQuality?.minorDefect?.forEach((defect) => {
              const { name, value } = defect
              finishBoxDefectMinor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          const finishBoxDefectMajor = [
            [
              { text: 'Major Defect', style: 'tableLabel' },
              { text: 'Percentage', style: 'tableLabel' },
            ],
          ]
          if (finishBoxQuality.majorDefect !== null) {
            finishBoxQuality?.majorDefect?.forEach((defect) => {
              const { name, value } = defect
              finishBoxDefectMajor.push([
                { text: `${name}`, style: 'tableLabel' },
                { text: `${numberToPercentage(Number((value * 100).toFixed(2)))}`, style: 'tableLabelValue' },
              ])
            })
          }
          if (finishBoxDefectMinor.length > 1) {
            finishBoxTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: finishBoxDefectMinor,
                dontBreakRows: true,
              },
            })
          }
          if (finishBoxDefectMajor.length > 1) {
            finishBoxTables.push({
              width: 300,
              style: 'table',
              table: {
                widths: [100, 100],
                body: finishBoxDefectMajor,
                dontBreakRows: true,
              },
            })
          }
          LoactionDefect.push({ columns: finishBoxTables })
          SummaryContent.push(LoactionDefect)
          content = SummaryContent
        }
        break
      case 'PackTypeSizingSummary':
        {
          const subHeader = []
          if (machineName) {
            subHeader.push({ text: `Machine: ${machineName}`, style: 'subHeader' })
          }
          subHeader.push({ text: `${dateReport}`, style: 'subHeader' })
          const SummaryContent = [LOGO, ...subHeader]

          if (packTypeSummaryData.packTypeSizingSummary.length > 0) {
            SummaryContent.push({ text: `Pack-Type Sizing Summary`, style: 'mainHeader' })
          }

          packTypeSummaryData.packTypeSizingSummary.forEach((item) => {
            let totalFinishCorrect = 0
            let totalFinishUnder = 0
            let totalFinishOver = 0
            let countFinish = 0
            const PackTypeSummaryFinishSizeTable = [
              [
                { text: `${varietyIdToName(item.variery_sizing[0].id_variety)}`, style: 'tableLabel' },
                { text: 'Under', style: 'tableLabel' },
                { text: 'Correct', style: 'tableLabel' },
                { text: 'Over', style: 'tableLabel' },
              ],
            ]

            item.variery_sizing?.forEach((itemVariety, i) => {
              if (
                itemVariety?.name_sample_size !== 'Undersize' &&
                itemVariety?.name_sample_size !== 'Oversize' &&
                (itemVariety?.under?.percentage !== 0 ||
                  itemVariety?.correct?.percentage !== 0 ||
                  itemVariety?.over?.percentage !== 0)
              ) {
                const PackTypeSummaryFinishSizeItem = []
                PackTypeSummaryFinishSizeItem.push({
                  text: `${itemVariety?.name_sample_size}`,
                  style: 'tableLabel',
                })
                PackTypeSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(((itemVariety?.under?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                })
                PackTypeSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(((itemVariety?.correct?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                })
                PackTypeSummaryFinishSizeItem.push({
                  text: `${numberToPercentage(((itemVariety?.over?.percentage || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                })
                totalFinishCorrect += itemVariety?.correct?.percentage || 0
                totalFinishUnder += itemVariety?.under?.percentage || 0
                totalFinishOver += itemVariety?.over?.percentage || 0
                countFinish++
                PackTypeSummaryFinishSizeTable.push(PackTypeSummaryFinishSizeItem)
              }
            })
            PackTypeSummaryFinishSizeTable.push([
              {
                text: 'Total',
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishUnder * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishCorrect * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
              {
                text: `${numberToPercentage(
                  ((totalFinishOver * 100) / (countFinish === 0 ? 1 : countFinish)).toFixed(2)
                )}`,
                style: 'tableLabel',
              },
            ])
            const tableFinish = {
              key: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Size Accuracy ${packTypeIdToName(
                item.id_packtype
              )}`,
              value: PackTypeSummaryFinishSizeTable,
            }

            if (PackTypeSummaryFinishSizeTable.length > 2) {
              PackTypeSummaryFinishedSize.push(tableFinish)
            }
          })

          let finishTables3column = []
          const finishTables = []
          PackTypeSummaryFinishedSize.forEach((finishTable, index) => {
            if (index % 3 === 0) {
              if (finishTables3column.length > 0 && index !== 0) {
                finishTables.push(finishTables3column)

                finishTables3column = []
              }
              finishTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${finishTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: finishTable.value.length > 2 ? finishTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            } else {
              finishTables3column.push([
                {
                  width: 150,
                  stack: [
                    { text: `${finishTable.key}`, style: 'titleHeader2' },
                    {
                      style: 'table',
                      width: [],
                      table: {
                        body: finishTable.value.length > 2 ? finishTable.value : [],
                        dontBreakRows: true,
                      },
                    },
                  ],
                },
              ])
            }
            if (index === PackTypeSummaryFinishedSize.length - 1) {
              finishTables.push(finishTables3column)
            }
          })
          finishTables.forEach((finish3Table) => {
            SummaryContent.push({ columns: finish3Table })
          })

          content = SummaryContent
        }
        break
      case 'PackTypeQualitySummary':
        {
          const subHeader = []
          if (machineName) {
            subHeader.push({ text: `Machine: ${machineName}`, style: 'subHeader' })
          }
          subHeader.push({ text: `${dateReport}`, style: 'subHeader' })
          const SummaryContent = [LOGO, ...subHeader]

          if (packTypeSummaryData.packTypeQualitySummary.length > 0) {
            SummaryContent.push({ text: `Pack-Type Quality Summary`, style: 'mainHeader' })
          }
          packTypeSummaryData.packTypeQualitySummary.forEach((item) => {
            const PackTypeSummaryFinishBoxQualityItem = []
            if (packTypeSummaryData.packTypeQualitySummary.length > 0 && item?.id_packtype !== null) {
              PackTypeSummaryFinishBoxQualityItem.push(
                { text: `${packTypeIdToName(item.id_packtype)}`, style: 'tableLabel' },
                {
                  text: `${numberToPercentage(((item?.estimatedPackOut || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(((item?.minorDefect || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                },
                {
                  text: `${numberToPercentage(((item?.majorDefect || 0) * 100).toFixed(2))}`,
                  style: 'tableLabelValue',
                }
              )
            }

            if (PackTypeSummaryFinishBoxQualityItem.length > 1) {
              PackTypeSummaryFinishedQuality.push(PackTypeSummaryFinishBoxQualityItem)
            }
          })

          PackTypeSummaryFinishedQuality.push([
            { text: 'Total', style: 'tableLabel' },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalEpo || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalMinDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
            {
              text: `${numberToPercentage(((totalFinishedBoxQuality?.totalMajDef || 0) * 100).toFixed(2))}`,
              style: 'tableLabel',
            },
          ])

          if (packTypeSummaryData.length > 0) {
            SummaryContent.push({ text: `Pack-Type Quality Summary`, style: 'mainHeader2' })
          }
          const columnsCol = []
          if (PackTypeSummaryFinishedQuality.length > 2) {
            columnsCol.push([
              {
                width: 150,

                stack: [
                  {
                    text: `${desTemplates?.TEMPLATE_LOC_FIN || 'Finished Box'} Quality`,
                    style: 'titleHeader2',
                  },
                  {
                    style: 'table',
                    width: [],
                    table: {
                      body: PackTypeSummaryFinishedQuality.length > 2 ? PackTypeSummaryFinishedQuality : [],
                      dontBreakRows: true,
                    },
                  },
                ],
              },
            ])
          }

          if (columnsCol.length > 0) {
            SummaryContent.push({
              columns: columnsCol,
            })
          }

          content = SummaryContent
        }
        break
      case 'GradeBySizeSummary':
        {
          const InlineTableHeader = [{ text: `Total Sample`, style: 'tableLabel' }]
          const InlineTableValue = [{ text: `${totalInline}`, style: 'tableLabel' }]
          const inlineWidth = [50]
          if (general?.inline_quality.length > 0) {
            general.inline_quality.forEach((item, index) => {
              inlineWidth.push('*')
              InlineTableHeader.push({
                text: `${sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-'}`,
                style: 'tableLabelValue',
              })
              InlineTableValue.push({
                text: `${numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2))}`,
                style: 'tableLabelValue',
              })
            })
          }
          const InlineTable =
            InlineTableHeader?.length > 1
              ? {
                  width: 320,
                  style: 'table',
                  margin: [0, 22, 0, 0],
                  table: {
                    widths: inlineWidth,
                    body: InlineTableHeader?.length > 1 ? [InlineTableHeader, InlineTableValue] : [],
                  },
                }
              : { text: '' }

          const FinisedBoxTableHeader = [{ text: `Total Sample`, style: 'tableLabel' }]
          const FinisedBoxTableValue = [{ text: `${totalFinishedBox}`, style: 'tableLabel' }]
          const FinisedBoxWidth = [50]
          if (general?.finish_box_quality.length > 0) {
            general.finish_box_quality.forEach((item, index) => {
              FinisedBoxWidth.push('*')
              FinisedBoxTableHeader.push({
                text: `${sizes.find((x) => x.id === item.id_sample_size)?.description ?? '-'}`,
                style: 'tableLabelValue',
              })
              FinisedBoxTableValue.push({
                text: `${numberToPercentage(((item?.correct_percentage || 0) * 100).toFixed(2))}`,
                style: 'tableLabelValue',
              })
            })
          }
          const FinisedBoxTable =
            FinisedBoxTableHeader?.length > 1
              ? {
                  width: 320,
                  style: 'table',
                  margin: [0, 22, 0, 0],
                  table: {
                    widths: FinisedBoxWidth,
                    body: FinisedBoxTableHeader?.length > 1 ? [FinisedBoxTableHeader, FinisedBoxTableValue] : [],
                  },
                }
              : { text: '' }

          content = [
            LOGO,
            { text: 'Grade By Size Summary', style: 'mainHeader' },
            {
              table: {
                widths: [50, '*'],
                body: [
                  [
                    { text: 'Facility', style: 'tableLabel' },
                    {
                      text: facilities.find((x) => x.id === general.id_facility)?.description ?? '-',
                      style: 'tableLabelValue',
                    },
                  ],
                  [
                    { text: 'Machine', style: 'tableLabel' },
                    {
                      text: machines.find((x) => x.id === general?.id_machine)?.name ?? '-',
                      style: 'tableLabelValue',
                    },
                  ],
                  [
                    { text: 'Lot #', style: 'tableLabel' },
                    {
                      text: general?.lotName ?? '-',
                      style: 'tableLabelValue',
                    },
                  ],
                  [
                    { text: 'Variety', style: 'tableLabel' },
                    {
                      text: general?.varietyName ?? '-',
                      style: 'tableLabelValue',
                    },
                  ],
                  [
                    { text: 'Date Range', style: 'tableLabel' },
                    { text: general?.date_ranger ?? '-', style: 'tableLabelValue' },
                  ],
                ],
              },
            },
            {
              text: InlineTableHeader?.length > 1 ? `${desTemplates?.TEMPLATE_LOC_INL || 'Inline'}` : '',
              style: 'mainHeader',
            },
            InlineTable,
            {
              text: FinisedBoxTableHeader?.length > 1 ? `${desTemplates?.TEMPLATE_LOC_INL || 'Finished Box'}` : '',
              style: 'mainHeader',
            },
            FinisedBoxTable,
          ]
        }

        break
      default:
    }
    return content
  }

  static generateSumarryContent(documentName, data) {
    const content = [
      LOGO,
      {
        text: [`Date:  ${DateTime.now().toFormat('MM-dd-yy')}`],
        style: 'subHeader',
      },
      { text: 'Receiving Report Summary', style: 'mainHeaderBot3' },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              dontBreakRows: true,
              margin: NO_MARGIN,

              body: data,
            },
            alignment: 'center',
          },
          { width: '*', text: '' },
        ],
        style: 'leftTable',
      },
    ]
    return content
  }

  static generateRunListContent(documentName, data) {
    const content = [
      LOGO,
      {
        text: [`Date:  ${DateTime.now().toFormat('MM-dd-yy')}`],
        style: 'subHeader',
      },
      { text: 'Run List', style: 'mainHeaderBot3' },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              dontBreakRows: true,
              margin: NO_MARGIN,

              body: data,
            },
            alignment: 'center',
          },
          { width: '*', text: '' },
        ],
        style: 'leftTable',
      },
    ]
    return content
  }

  static generateCartonContent(data) {
    const content = [
      LOGO,
      {
        text: [`Date:  ${DateTime.now().toFormat('MM-dd-yy')}`],
        style: 'subHeader',
      },
      { text: 'Receiving Carton Summary', style: 'mainHeaderBot3' },
      {
        columns: [
          { width: '*', text: '' },
          {
            width: 'auto',
            table: {
              dontBreakRows: true,
              margin: NO_MARGIN,

              body: data,
            },
            alignment: 'center',
          },
          { width: '*', text: '' },
        ],
        style: 'leftTable',
      },
    ]
    return content
  }

  static generatePDF(documentName, content) {
    pdfMake
      .createPdf({
        content,
        styles: STYLES,
        images: IMAGES,
        pageMargins: PAGE_MARGINS,
      })
      .download(documentName)
  }

  static generateSumPDF(documentName, content, pageO) {
    pdfMake
      .createPdf({
        pageOrientation: pageO,
        content,
        styles: STYLES,
        images: IMAGES,
        pageMargins: PAGE_MARGINS,
      })
      .download(documentName)
  }

  static generatePDFNoDown(content, pageO) {
    return pdfMake.createPdf({
      pageOrientation: pageO,
      content,
      styles: STYLES,
      images: IMAGES,
      pageMargins: PAGE_MARGINS,
    })
  }
}

export default PdfMakerService
