import React, { useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { DataGrid } from '@material-ui/data-grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import SearchBar from '../SearchBar/SearchBar.component'

import PrimaryButton from '../button/button.component'
import useStyles from './catalog-grid.styles'
import { ROW_PER_PAGE_OPTIONS } from '../../shared/constants/table'
import { searchFullColumns } from '../../shared/utils/utils'

const CatalogGrid = ({
  rows,
  columns,
  onToggle,
  toggleValue,
  header,
  haveSearchInput,
  disableNew,
  onNew,
  filter,
  filterValue,
  filterChange,
  filterValues,
  pageSize,
  onPageSizeChange,
  loading,
}) => {
  const classes = useStyles()
  const [showToggle, setShowToggle] = useState(false)
  const [rowData, setRowData] = useState([])

  const handleChangeSearch = (event) => {
    const text = event.target.value

    if (rows && rows?.length) {
      searchFullColumns(rows, text, setRowData, columns)
    }
  }
  useEffect(() => {
    setShowToggle(onToggle && toggleValue !== undefined)
  }, [toggleValue])
  useEffect(() => {
    setRowData(rows)
  }, [rows])
  return (
    <div>
      <Grid container className={classes.topRow}>
        <Grid container>
          {showToggle ? (
            <Grid item xs={12} sm={6}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="button" className={classes.switchText}>
                    Show All
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch checked={toggleValue} onChange={onToggle} name="checkedC" />
                </Grid>
                <Grid item>
                  <Typography variant="button" className={classes.switchText}>
                    Show Active
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            sm={showToggle ? 6 : 12}
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            {haveSearchInput && <SearchBar onChange={handleChangeSearch} />}
            {!disableNew && (
              <PrimaryButton className={classes.buttonAddNew} onClick={onNew}>
                Add new
              </PrimaryButton>
            )}
          </Grid>
          <Grid item sm={12} style={{ textAlign: 'right', display: 'flex', width: '100%' }}>
            {header}
          </Grid>
        </Grid>
      </Grid>
      <Card raised>
        <CardContent className={classes.card}>
          {filter && (
            <FormControl size="small" variant="outlined" margin="normal" className={classes.formControlFilter}>
              <InputLabel id="demo-simple-select-label">{filter}</InputLabel>

              <Select
                value={filterValue}
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                label={filter}
                onChange={filterChange}
              >
                <MenuItem key="cats-0" value={0}>
                  All
                </MenuItem>
                {filterValues.map((item) => (
                  <MenuItem key={`cats-${item.id}`} value={item.id}>
                    {item.description ? item.description : item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={classes.dataGridContainer}>
            <div className={classes.dataGridInnerContainer}>
              <DataGrid
                className={classes.dataGrid}
                columns={columns.map((column) => ({
                  ...column,
                  filterable: false,
                }))}
                rows={rowData}
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                onPageSizeChange={onPageSizeChange}
                hideFooterSelectedRowCount
                loading={loading || false}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default CatalogGrid
