import subscriptionTypes from './subscription.types'

export const setCurrentDashboardInformationAction = (dashboardInformation) => ({
  type: subscriptionTypes.SET_CURRENT_DASHBOARD_INFORMATION,
  payload: dashboardInformation,
})

export const setCurrentAlertInformationAction = (alertInformation) => ({
  type: subscriptionTypes.SET_CURRENT_ALERT_INFORMATION,
  payload: alertInformation,
})
export const setCurrentNotificationInformationAction = (notificationInformation) => ({
  type: subscriptionTypes.SET_CURRENT_NOTIFICATION_INFORMATION,
  payload: notificationInformation,
})

export const setIsLoadingAction = (isLoading) => ({
  type: subscriptionTypes.SET_IS_LOADING,
  payload: isLoading,
})
