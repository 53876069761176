import notificationActionTypes from './notification-pusher.types'

const INITIAL_STATE = null

// eslint-disable-next-line default-param-last
const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case notificationActionTypes.SET_CURRENT_NOTIFICATION:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}

export default notificationReducer
