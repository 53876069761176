import lotsActionTypes from './lots.types'

const INITIAL_STATE = {
  currentLot: { lot_number: '', lot_name: '', id_variety: '', variety: '' },
  savedLot: { lot_number: '', lot_name: '', id_variety: '', variety: '' },
  currentLotTime: { id_facility: 0, id_lot: 0, id_variety: 0 },
}

// eslint-disable-next-line default-param-last
const lotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case lotsActionTypes.SET_CURRENT_LOT:
      return {
        ...state,
        currentLot: action.payload,
      }
    case lotsActionTypes.SET_SAVED_LOT:
      return {
        ...state,
        savedLot: action.payload,
      }
    case lotsActionTypes.RESET_LOT_STATE:
      return {
        ...INITIAL_STATE,
      }
    case lotsActionTypes.SET_CURRENT_LOTTIME:
      return {
        ...state,
        currentLotTime: action.payload,
      }
    default:
      return state
  }
}

export default lotReducer
