import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'

const PrintButton = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Grid xs={12} item style={{ textAlign: 'right' }}>
      <IconButton onClick={handleClick}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => props.printDocument()}>
          <Icon style={{ marginRight: 10 }}>printer</Icon> Print Report
        </MenuItem>
        <MenuItem onClick={() => props.printfReportExcel()}>
          <Icon style={{ marginRight: 10 }}>calendar_view_month</Icon> Report Excel
        </MenuItem>
      </Menu>
    </Grid>
  )
}

export default PrintButton
