import DrawerActionTypes from './drawer.types'

const INITIAL_STATE = {
  isOpen: false,
  menuSelected: {
    parent: 'dashboard',
    child: '',
  },
  previousLocation: '',
}

// eslint-disable-next-line default-param-last
const drawerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DrawerActionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    case DrawerActionTypes.SET_MENU_SELECTED:
      return {
        ...state,
        menuSelected: action.payload,
      }
    case DrawerActionTypes.SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload,
      }
    case DrawerActionTypes.LOG_OUT:
      return {
        ...state,
        isOpen: false,
      }
    default:
      return state
  }
}

export default drawerReducer
