import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { DataGrid } from '@material-ui/data-grid'
import { Switch } from '@material-ui/core'
import useSizeQuery from '../../shared/hooks/useSizeQuery'

import RequestService from '../../services/request/request-service'

import { SIZINGMATRIX_QUERY, MACHINE_QUERY } from '../../shared/constants/queries'

import useStyles from './sizing_matrix.styles'

const LOCATION_TYPE = {
  Inline: 'inline',
  FinishedBox: 'finishedBox',
}

const SizingMaTrix = ({ currentFacilityId, currentMachine }) => {
  const classes = useStyles()
  const history = useHistory()
  const { data: sizes } = useSizeQuery({ showActive: true })

  const [loading, setLoading] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [machines, setMachines] = useState([])
  const [selectedMachineId, setSelectedMachineId] = useState(0)
  const [locationType, setLocationType] = useState(LOCATION_TYPE.Inline)
  const [rows, setRows] = useState(null)

  useEffect(async () => {
    if (currentFacilityId > 0) {
      await loadMachine()
    }
  }, [])

  const loadData = async () => {
    if (currentFacilityId <= 0 || minutes <= 0) {
      setRows(null)
      return
    }
    try {
      setRows(null)
      setLoading(true)
      const realMinutes = new Date().getTimezoneOffset() / -1 - parseInt(minutes, 10)
      /* eslint-disable-next-line max-len */
      const url = `${SIZINGMATRIX_QUERY}?id_facility=${currentFacilityId}&id_machine=${selectedMachineId}&minutes=${realMinutes}`
      const response = await RequestService.Get(url, history)
      setRows(response?.data)
    } catch (error) {
      setLoading(false)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const loadMachine = async () => {
    try {
      const url = `${MACHINE_QUERY}/getmachinesbyidfacility?id_facility=${currentFacilityId}`
      setLoading(true)
      const resMachines = await RequestService.Get(url, history)
      setMachines(resMachines.data)
      if (resMachines.data?.length > 0) setSelectedMachineId(resMachines.data[0].id)
    } finally {
      setLoading(false)
    }
  }

  const parseRowsData = (data) => {
    if (!data || (data?.inline.length === 0 && data?.finishedBox.length === 0)) {
      const rows = sizes.reduce(
        (result, item) => {
          result.correct = { ...result.correct, [String(item.value)]: 0 }
          result.unders = { ...result.unders, [String(item.value)]: 0 }
          result.overs = { ...result.overs, [String(item.value)]: 0 }
          result.totalSamples = { ...result.totalSamples, [String(item.value)]: 0 }
          return result
        },
        {
          correct: { field: 'Correct', id: 1 },
          unders: { field: 'Unders', id: 2 },
          overs: { field: 'Overs', id: 3 },
          totalSamples: { field: 'Samples', id: 4 },
        }
      )
      return [rows.correct, rows.unders, rows.overs, rows.totalSamples]
    }

    const rows = data[locationType].reduce(
      (result, item) => {
        result.correct = { ...result.correct, [String(item.size)]: item.correct }
        result.unders = { ...result.unders, [String(item.size)]: item.unders }
        result.overs = { ...result.overs, [String(item.size)]: item.overs }
        result.totalSamples = { ...result.totalSamples, [String(item.size)]: item.totalSamples }
        return result
      },
      {
        correct: { field: 'Correct', id: 1 },
        unders: { field: 'Unders', id: 2 },
        overs: { field: 'Overs', id: 3 },
        totalSamples: { field: 'Samples', id: 4 },
      }
    )

    return [rows.correct, rows.unders, rows.overs, rows.totalSamples]
  }

  const parseColumnsData = (data) => {
    if (!data) {
      return [
        {
          field: 'field',
          headerName: ' ',
          headerClassName: classes.tableHeader,
          sortable: false,
          flex: 1,
          minWidth: 100,
          align: 'center',
          renderCell: (params) => {
            return (
              <Typography variant="subtitle2" className={classes.tableHeader} color="primary">
                {params.value}
              </Typography>
            )
          },
        },
        ...sizes.map((size) => ({
          field: String(size.value),
          headerName: String(size.value),
          headerClassName: classes.tableHeader,
          sortable: false,
          flex: 1,
          minWidth: 150,
          renderCell: (params) => cellFormat(params.value, params.row.id),
        })),
      ]
    }

    return [
      {
        field: 'field',
        headerName: ' ',
        headerClassName: classes.tableHeader,
        sortable: false,
        flex: 1,
        minWidth: 100,
        align: 'center',
        renderCell: (params) => {
          return (
            <Typography variant="subtitle2" className={classes.tableHeader} color="primary">
              {params.value}
            </Typography>
          )
        },
      },
      ...data[locationType].map((item) => ({
        field: String(item.size),
        headerName: String(item.size),
        headerClassName: classes.tableHeader,
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => cellFormat(params.value, params.row.id),
      })),
    ]
  }

  const handleChangeMinutes = (event) => {
    const { name, value } = event.target
    setMinutes(value)
  }

  const handleChangeLocation = (checked) => {
    if (checked) setLocationType(LOCATION_TYPE.FinishedBox)
    else setLocationType(LOCATION_TYPE.Inline)
  }

  const cellFormat = (value, rowId) => {
    let color = 'green'

    switch (rowId) {
      case 1: {
        if (value >= 75 && value <= 84) color = 'orange'
        if (value <= 74) color = 'red'
        return (
          <Typography style={{ color }} variant="h6">
            {value}
          </Typography>
        )
      }
      case 2:
      case 3: {
        if (value >= 6 && value <= 15) color = 'orange'
        if (value >= 16) color = 'red'
        return (
          <Typography style={{ color }} variant="h6">
            {value}
          </Typography>
        )
      }
      default: {
        return (
          <Typography style={{ color }} variant="h6">
            {value}
          </Typography>
        )
      }
    }
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Sizing Matrix
        </Typography>
      </Box>
      <Grid container spacing={1} style={{ justifyContent: 'center' }}>
        {machines.length > 0 ? (
          <Grid item xs={12} sm={4} md={3}>
            <FormControl margin="none" variant="outlined" size="small" fullWidth>
              <InputLabel id="select-machine-label">Machine</InputLabel>
              <Select
                disabled={loading}
                labelId="select-machine-label"
                label="Machine"
                value={selectedMachineId}
                onChange={(event) => {
                  setSelectedMachineId(event.target.value)
                }}
              >
                {machines.map((item) => (
                  <MenuItem key={`machine-${item.id}`} value={item.id}>
                    {item.name ? item.name : item.description ? item.description : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={9} sm={4} md={3}>
          <TextField
            fullWidth
            disabled={loading}
            id="outlined-basic"
            size="small"
            variant="outlined"
            type="number"
            label="Minutes to include"
            onChange={handleChangeMinutes}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 8)
            }}
            onKeyDown={async (ev) => {
              if (ev.key === 'Enter') {
                await loadData()
                ev.preventDefault()
              }
            }}
          />
        </Grid>
        <Grid item xs={3} sm={1} md={1}>
          <Button
            className={classes.buttonFilter}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={loadData}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={1} md={2}>
          <div className={classes.locationWrapper}>
            <Switch
              id="location-switch"
              color="primary"
              disabled={loading}
              checked={locationType === LOCATION_TYPE.FinishedBox}
              onChange={(event) => handleChangeLocation(event.target.checked)}
            />
            <label htmlFor="location-switch" className={classes.locationTitle}>
              {locationType === LOCATION_TYPE.Inline ? 'In-line' : 'Finished Box'}
            </label>
          </div>
        </Grid>
      </Grid>
      <div className={classes.card}>
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              loading={loading}
              style={{ borderStyle: 'none' }}
              columns={parseColumnsData(rows).map((column) => ({
                ...column,
                filterable: false,
              }))}
              rows={parseRowsData(rows)}
              hideFooterPagination
              disableSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentFacilityId: state.facility.currentFacilityId,
  currentMachine: state.machine,
})

export default connect(mapStateToProps, null)(SizingMaTrix)
