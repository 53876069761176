import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  appBar: { border: 'none', borderBottom: '2px solid #eee', position: 'relative', height: '48px', zIndex: 1 },
  tab: { textTransform: 'none', fontSize: 18 },
  tabs: { position: 'absolute', width: '100%' },
  tableCell: { border: '1px solid black' },
  tableCellLabel: { fontWeight: 'bold' },
  tableCellUnders: { backgroundColor: 'red', color: 'white' },
  tableCellCorrect: { backgroundColor: 'green', color: 'black' },
  tableCellOvers: { backgroundColor: 'orange', color: 'black' },
  backgroundYellow: { backgroundColor: 'yellow' },
  grid: {
    marginTop: '20px',
  },
  positionTable: {
    position: 'relative',
    height: '146px',
    '& div.MuiTableContainer-root': {
      position: 'absolute',
    },
  },
  chartContainer: {
    position: 'relative',
    height: '400px',
    '& div.recharts-responsive-container': {
      position: 'absolute',
    },
  },
  noDataContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  hideChart: {
    position: 'absolute',
    width: '100%',
    zIndex: -10000,
    top: '-10000px',
    left: '-10000px',
  },
  searchBox: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '5px',
  },
  tooltip: {
    maxWidth: 240,
    fontSize: '14px',
  },
  highlight: {
    border: '2px solid black',
    borderRadius: '4px',
  },
}))

export default useStyles
