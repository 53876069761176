import React from 'react'
import CatalogGrid from '../catalog-grid/catalog-grid.component'

const TableContainer = ({ ...props }) => {
  const {
    columns,
    filter,
    filterChange,
    filterValue,
    filterValues,
    handleClickOpen,
    rows,
    setTableConf,
    tableConfiguration,
    onToggleClick,
    showActive,
    loading,
    haveSearchInput,
    disableNew,
    header,
  } = props

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const onPrevPage = () => {
    const newPage = tableConfiguration.page - 1 < 1 ? tableConfiguration.page : tableConfiguration.page - 1
    setTableConf({ ...tableConfiguration, page: newPage })
  }

  const onNextPage = () => {
    const newPage = tableConfiguration.page + 1
    setTableConf({ ...tableConfiguration, page: newPage })
  }

  return (
    <CatalogGrid
      filterValues={filterValues}
      filterValue={filterValue}
      filter={filter}
      filterChange={filterChange}
      onNew={handleClickOpen ? handleClickOpen('Add new', null) : null}
      columns={columns}
      rows={rows}
      pageSize={tableConfiguration.pageSize}
      onPageSizeChange={onPageSizeChange}
      onPrevPage={onPrevPage}
      onNextPage={onNextPage}
      onToggle={onToggleClick}
      toggleValue={showActive}
      loading={loading}
      haveSearchInput={haveSearchInput}
      role={props.role}
      disableNew={disableNew}
      header={header}
    />
  )
}

export default TableContainer
