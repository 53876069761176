import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a3858',
    },
    secondary: {
      main: '#5a7a98',
    },
  },
})

export default theme
