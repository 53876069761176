import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { useSnackbar } from 'notistack'

import { DateTime } from 'luxon'
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core'

import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import RequestService from '../../services/request/request-service'
import Alert from '../alert/alert.component'
import SearchBar from '../SearchBar/SearchBar.component'
import useStyles from './recovery.style'
import { ROW_PER_PAGE_OPTIONS, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import { SAMPLE_QUERY, SAMPLE_DELETED_HISTORY_QUERY } from '../../shared/constants/queries'
import { searchFullColumns, convertFormatDateTime } from '../../shared/utils/utils'

const Recovery = React.memo(({ lots, varieties, onClose, currentFacility }) => {
  const classes = useStyles()
  const history = useHistory()
  const [rows, setRows] = useState([])
  const [rowsResponse, setRowsResponse] = useState([])
  const [dataRecoveries, setdataRecoveries] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [openAlert, setOpen] = useState(false)
  const [severityType, setSeverityType] = useState('success')
  const [alertMsg, setAlertMsg] = useState('Lot started')
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [select, setSelection] = useState([])
  const [confirmOpenDelete, setConfirmOpenRestore] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const columns = [
    { field: 'createdBy', headerName: 'Person Deleted', width: 200 },
    {
      field: 'createdDate',
      headerName: 'Date Deleted',
      flex: 1,
      minWidth: 190,
    },
    {
      field: 'lot',
      headerName: 'Lot #',
      minWidth: 110,
      flex: 1,
    },
    {
      field: 'variety',
      headerName: 'Variety',
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'selectedRange',
      headerName: 'Selected Range',
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
  ]

  const onPageSizeChange = (event) => {
    setTableConf({ page: 1, pageSize: event })
  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpen(false)
  }

  const ExcuteRestore = async () => {
    try {
      setConfirmOpenRestore(false)

      const dataRecovery = dataRecoveries.find((x) => x.id === select[0])

      if (dataRecovery) {
        const url = `${SAMPLE_QUERY}/DataRecoveryReceivingSummary`
        setIsLoading(true)
        await RequestService.Post(url, history, dataRecovery.id)
        await loaddataRecoveries()
        setIsLoading(false)
      } else {
        setSeverityType('error')
        setAlertMsg('Error Unknown')
        setOpen(true)
      }
    } catch (error) {
      setIsLoading(false)
      setSeverityType('error')
      setAlertMsg(`${error.errorMessage}`)
      setOpen(true)
    }
  }

  const loaddataRecoveries = async () => {
    const url = `${SAMPLE_DELETED_HISTORY_QUERY}?id_facility=${currentFacility.id}`
    const response = await RequestService.Get(url, history)
    setdataRecoveries(
      response?.data.map((item) => {
        return {
          ...item,
          createdDate: convertFormatDateTime(item.createdDate),
          selectedRange: `${
            item.start_Date ? DateTime.fromISO(item.start_Date).toUTC().toFormat('LL/dd/yyyy') : ''
          } - ${item.end_Date ? DateTime.fromISO(item.end_Date).toUTC().toFormat('LL/dd/yyyy') : ''}`,
          lot: lots.find((lot) => item.id_Lot === lot.id)?.name,
          variety: varieties.find((variety) => item.id_Variety === variety.id)?.description,
        }
      }) || []
    )
  }
  const onSelectionModelChange = (params) => {
    setSelection(params)
  }

  const handleCloseDel = () => {
    setConfirmOpenRestore(false)
  }

  const OpenDialogComfirmRestore = () => {
    if (select.length === 0) {
      enqueueSnackbar('Please select an item in the list.', { variant: 'warning' })
      return
    }

    setConfirmOpenRestore(true)
  }

  useEffect(async () => {
    try {
      setIsLoading(true)
      await loaddataRecoveries()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])
  useEffect(() => {
    setRowsResponse(dataRecoveries)
    setRows(dataRecoveries)
  }, [dataRecoveries])

  const handleChangeSearch = (event) => {
    const text = event.target.value
    searchFullColumns(rowsResponse, text, setRows, columns)
  }

  return (
    <>
      <Dialog open={confirmOpenDelete} onClose={() => handleCloseDel()}>
        <DialogTitle>Are you sure you want to restore this Lot?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleCloseDel()}>Cancel</Button>
          <Button onClick={() => ExcuteRestore()}>Restore</Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Button className={classes.closeBtn} onClick={onClose} variant="contained" color="secondary">
            <Icon>clear</Icon>
          </Button>
        </div>
        <Typography className={classes.currentLabel} component="h1" variant="h6" color="primary">
          Data Recovery:
        </Typography>
        <div className={classes.btnContainer}>
          <Button
            className={classes.startButton}
            variant="contained"
            disabled={isLoading}
            onClick={OpenDialogComfirmRestore}
            color="secondary"
          >
            Restore
          </Button>
          <div className={classes.searchBox}>
            <SearchBar onChange={handleChangeSearch} />
          </div>
        </div>
        <div style={{ height: '60vh', width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={tableConf.pageSize}
            rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
            loading={isLoading}
            onPageSizeChange={onPageSizeChange}
            onSelectionModelChange={onSelectionModelChange}
          />
        </div>
        <Alert onClose={handleAlertClose} open={openAlert} severity={severityType}>
          {alertMsg}
        </Alert>
      </div>
    </>
  )
})

const mapStateToProps = (state) => ({
  currentFacility: state.facility.currentFacility,
})

export default connect(mapStateToProps)(Recovery)
