import FacilityActionTypes from './facility.types'

const INITIAL_STATE = {
  currentFacilityId: 0,
  currentFacility: [],
  facilities: [],
  moreThanOneFacility: false,
}

// eslint-disable-next-line default-param-last
const facilityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FacilityActionTypes.SET_CURRENT_FACILITY_ID:
      return {
        ...state,
        currentFacilityId: action.payload,
      }
    case FacilityActionTypes.SET_FACILITIES:
      return {
        ...state,
        facilities: action.payload,
      }
    case FacilityActionTypes.SET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: action.payload,
      }
    case FacilityActionTypes.SET_MORE_THAN_ONE_FACILITY:
      return {
        ...state,
        moreThanOneFacility: action.payload,
      }
    case FacilityActionTypes.RESET_FACILITY_STATE:
      return {
        ...INITIAL_STATE,
      }
    default:
      return state
  }
}

export default facilityReducer
