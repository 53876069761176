import growerActionTypes from './grower.types'

const INITIAL_STATE = {
  growerName: '',
}

// eslint-disable-next-line default-param-last
const growerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case growerActionTypes.SET_CURRENT_GROWER_NAME:
      return {
        ...state,
        growerName: action.payload,
      }
    default:
      return state
  }
}

export default growerReducer
