import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { ColorPicker, createColor } from 'material-ui-color'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import MultipleSelect from '../../components/multi-select/multi-select'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'
import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { ACTIONS_COLUMN, DEFAULT_ROW_DATA, DEFAULT_TABLE_CONFIGURATION } from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { COLOR_QUERY, VARIETIES_COLORS_QUERY } from '../../shared/constants/queries'
import useStyles from './color.styles'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'
import useColorQuery from '../../shared/hooks/useColorQuery'
import useVerietyQuery from '../../shared/hooks/useVerietyQuery'

const Color = ({ userEmail }) => {
  const classes = useStyles()
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)

  const [showActive, setShowActive] = useState(true)
  const [openVarietiesDialog, setOpenVarietiesDialog] = useState(false)
  const [selectedVarieties, setSelectedVarieties] = useState([])

  const { executeInactive, loadingInactive } = useHttpInactive(COLOR_QUERY)

  const colorQuery = useColorQuery({ showActive })
  const verietyQuery = useVerietyQuery()
  const isLoadingQuery = colorQuery.isFetching || verietyQuery.isFetching
  // columns definition for table
  const columns = [
    { field: 'name', headerName: 'Name', minWidth: 115, flex: 1 },
    { field: 'description', headerName: 'Description', minWidth: 150, flex: 1 },
    {
      field: 'hex',
      headerName: 'Hex',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className={classes.previewBox} style={{ backgroundColor: params.row.hex }} />
            <div>{params.row.hex}</div>
          </>
        )
      },
    },
    {
      field: 'active',
      headerName: 'Status',
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Active' : 'Inactive'),
      flex: 1,
    },
    {
      field: 'varieties',
      headerName: 'Varieties',
      flex: 1,
      minWidth: 135,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id, hex: params.row.hex })
            setOpenVarietiesDialog(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Varieties
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_COLOR_PAGE, { email: userEmail })
  }, [])

  // insert and edit
  const insertEditData = async (method, data) => {
    try {
      setLoading(true)
      setOpen(false)

      let url = `${COLOR_QUERY}`
      let response
      if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
      if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

      // Created stem condition id
      const { id } = response.data

      const colorVarietiesPayload = buildPayload(id, selectedVarieties, null, true)
      url = `${VARIETIES_COLORS_QUERY}?filterByFirst=false`
      await RequestService.Put(url, history, colorVarietiesPayload, { isBlankInstance: true })
      setLoading(false)
      colorQuery.refetch()
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    if (type === 'Edit') {
      const colorsVarieties = await getManyToManyData(
        `${VARIETIES_COLORS_QUERY}?id=${params.id}&filterByFirst=false`,
        history,
        verietyQuery.data
      )

      setSelectedVarieties(colorsVarieties)

      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        hex: createColor(params.hex),
      })
    } else {
      setRowData({ ...DEFAULT_ROW_DATA, hex: createColor('#000000') })
      setSelectedVarieties([])
    }
    setModalType(type)
    setOpen(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData, hex: `#${rowData.hex.hex}` }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      hex: params.hex,
      active: !params.active,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      colorQuery.refetch()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleColorChange = (event) => {
    setRowData({
      ...rowData,
      hex: event,
    })
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Colors
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={colorQuery.data}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive((val) => !val)}
        showActive={showActive}
        loading={isLoadingQuery}
      />
      <MultipleSelect
        open={openVarietiesDialog}
        onClose={() => {
          setOpenVarietiesDialog(false)
        }}
        title="Varieties"
        getUrl="variety"
        getRelationUrl="varietiescolors"
        useLast
        mainId={rowData.id}
        lefttoright
      />
      <FormDialog
        title={`${modalType} color`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label="Name"
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label="Description"
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <FormControlLabel
          className={classes.colorPickerLabel}
          control={
            <ColorPicker
              className={classes.colorPicker}
              id="hex"
              hideTextfield
              name="hex"
              value={rowData.hex}
              onChange={handleColorChange}
            />
          }
          label="Hexadecimal * "
          labelPlacement="start"
        />
        <ManyToManyInput
          inputLabel="Varieties"
          name="id_variety"
          selectedValues={selectedVarieties}
          allValues={verietyQuery.data}
          setSelectedValues={setSelectedVarieties}
        />

        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title="Deactivate color"
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {DEFAULT_COMFIRM_INACTIVE_MESSAGE} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Color)
