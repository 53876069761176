import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const ReportsRowSizeHeaderRow = (props) => {
  const { classes } = props

  return (
    <TableRow key="header">
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel}`} align="center">
        Row Size
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellUnders}`} align="center">
        Unders
      </TableCell>
      <TableCell
        className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellCorrect}`}
        align="center"
      >
        Correct
      </TableCell>
      <TableCell className={`${classes.tableCell} ${classes.tableCellLabel} ${classes.tableCellOvers}`} align="center">
        Overs
      </TableCell>
    </TableRow>
  )
}

export default ReportsRowSizeHeaderRow
