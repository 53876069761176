import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { delay, isServiceBusError, ServiceBusClient } from '@azure/service-bus'
import { DateTime } from 'luxon'
import { useSnackbar } from 'notistack'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import Switch from '@material-ui/core/Switch'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import DashboardContext from '../../context/dashboard/DashboardContext'
import PrimaryButton from '../../components/button/button.component'
import DashboardCard from '../../components/dashboard-card/dashboard-card.component'
import DashboardBar from '../../components/dashboard-bar/dashboard-bar.component'
import InLineChart from '../../components/inline-chart/inline-chart.component'
import PackOutChart from '../../components/packout-chart/packout-chart.component'
import SizeChart from '../../components/size-chart/size-chart.component'
import LotChange from '../../components/lot-change/lot-change.component'
import Clock from '../../components/clock/clock-component'
import SimpleBackdrop from '../../components/simple-backdrop/simple-backdrop.component'
import JoyrideContainerComponent from '../../components/joyride/joyride-container.component'

import RequestService from '../../services/request/request-service'
import UserService from '../../services/user/user-service'
import { setCurrentGrowerNameAction } from '../../redux/grower/grower.actions'
import {
  setCurrentAlertInformationAction,
  setCurrentDashboardInformationAction,
  setCurrentNotificationInformationAction,
} from '../../redux/subscription/subscription.actions'
import { updateCurrentDashboardData, setCurrentMarketingAction } from '../../redux/dashboard/dashboard.acions'
import { setCurrentNotificationAction } from '../../redux/notification/notification-pusher.action'
import { setCurrentMachineAction } from '../../redux/machine/machine.action'
import { resetLotStateAction } from '../../redux/lots/lots.actions'
import {
  AB_SUBSCRIPTION_QUERY,
  GROWER_QUERY,
  LOCATION_QUERY,
  LOT_QUERY,
  VARIETY_QUERY,
  DASHBOARDV2_QUERY,
  DAILY_AVERAGE_DASHBOARD_QUERY,
} from '../../shared/constants/queries'
import {
  ALERT_SUBSCRIPTION,
  DASHBOARD_STEPS,
  DASHBOARD_SUBSCRIPTION,
  DEFAULT_ACTIVE,
  INTERVAL_STATUS_IDLE,
  INTERVAL_STATUS_RUNNING,
} from '../../shared/constants/general'
import { ROLES_ALLOWED_CURRENTLY_PACKING } from '../../shared/constants/roles'
import { LOG_SUBSCRIPTION_ACTIONS } from '../../shared/constants/logging'
import useInterval from '../../shared/hooks/useInterval'
import useFacilityLocationQuery from '../../shared/hooks/useFacilityLocationQuery'
import { TEMPLATES_LOC } from '../../shared/constants/template-location'

const Dashboard = ({
  currentLot,
  setCurrentLotDispatch,
  currentFacilityId,
  currentFacility,
  moreThanOneFacility,
  updateCurrentGrowerNameDispatch,
  growerName,
  updateCurrentAlertInformationDispatch,
  alertInformation,
  updateCurrentDashboardInformationDispatch,
  updateCurrentDashboardDataDispatch,
  dashboardInformation,
  notificationInformation,
  userEmail,
  currentMachine,
  updateCurrentNotificationInformationDispatch,
  setNotificationPusher,
  marketing,
  setMarketing,
  updateCurrentMachineDispatch,
  resetLotStateActionDispatch,
}) => {
  const history = useHistory()
  const {
    alertClient,
    setAlertClient,
    alertSubscription,
    setAlertSubscription,
    closeAlertSubscription,
    alertReceiver,
    setAlertReceiver,
    closeAlertReceiver,
    deleteSubscription,
    cleanUpDashboardContext,
    cleanUpAlertContext,
  } = useContext(DashboardContext)
  const { trackEvent } = useContext(AppInsightsTrackingContext)
  const { enqueueSnackbar } = useSnackbar()
  const [locations, setLocations] = useState({})
  const [isDrawerOpen, setDrawer] = useState(false)
  const [sizeData, setSizeData] = useState({})
  const [averageData, setAverageData] = useState([
    { name: 'In-Line', value: [0, 0] },
    { name: 'Finished Box', value: [0, 0] },
    { name: 'G.I.B.', value: 0 },
  ])
  const [receiveUpstreamData, setReceiveUpstreamData] = useState({
    receivingAverage: 0,
    upstreamAverage: 0,
    estimatedPackOut: 0,
  })
  const [gibData, setGibData] = useState([
    { name: 'G.I.B.', value: 0 },
    { name: 'D/S', value: 0 },
  ])
  const [gipData, setGipData] = useState([{ name: 'G.I.P.', value: 0 }])
  const [inlineData, setInlineData] = useState({})
  const [finishBoxData, setFinishBoxData] = useState([
    { name: 'Minor Defect', value: 0 },
    { name: 'Major Defect', value: 0 },
  ])
  const [totalRec, setTotalRec] = useState(0)
  const [totalUps, setTotalUps] = useState(0)
  const [loading, setLoading] = useState(true)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [activeLotId, setActiveLotId] = useState(0)
  const [activeVarietyId, setActiveVarietyId] = useState(0)
  const [activeLot, setActiveLot] = useState(DEFAULT_ACTIVE)
  const [activeVariety, setActiveVariety] = useState(DEFAULT_ACTIVE)
  const [showCurrentlyPackingData, setShowCurrentlyPackingData] = useState(false)
  const [lots, setLots] = useState([])
  const [varieties, setVarieties] = useState([])
  const [intervalStatus, setIntervalStatus] = useState(INTERVAL_STATUS_IDLE)
  const [dashboard, setDashboard] = useState(null)
  const [dashboardPusher, setDashboardPusher] = useState(null)
  const [firmness, setFirmness] = useState([])
  const [dailyAverageDashboard, setDailyAverageDashboard] = useState(null)
  const [desTemplates, setDesTemplates] = useState({})
  const [emptyDashboard, setEmptyDashboard] = useState(null)
  const typeMessage = {
    ALERTS: 1000,
    NOTIFICATION: 3000,
  }
  const facilityLocationQuery = useFacilityLocationQuery({
    filter: { id: currentFacilityId },
  })
  const isLoadingQuery = facilityLocationQuery.isFetching

  useEffect(() => {
    let newDesTempalte = {}
    if (facilityLocationQuery.data) {
      Object.keys(TEMPLATES_LOC).forEach((key) => {
        const templates = facilityLocationQuery.data.filter((item) => item.second.template === TEMPLATES_LOC[key])
        if (templates.length) {
          newDesTempalte = { ...newDesTempalte, [key]: templates[templates.length - 1].second.description }
        }
      })
    }
    setDesTemplates(newDesTempalte)
  }, [facilityLocationQuery.data])

  useEffect(async () => {
    if (!currentLot.advancedChecked) {
      currentLot.startDate = DateTime.now().startOf('day').toISO({ includeOffset: false })
      currentLot.endDate = DateTime.now().endOf('day').toISO({ includeOffset: false })
    }
    const resUserRole = await UserService.getUserRole()
    // Only subscribe to alert subscription if allowed role.
    if (!alertClient) await createAlertClient()
    if (ROLES_ALLOWED_CURRENTLY_PACKING.includes(resUserRole)) {
      setShowCurrentlyPackingData(ROLES_ALLOWED_CURRENTLY_PACKING.includes(resUserRole))
    }
  }, [])

  useEffect(() => {
    if (
      dashboard &&
      dashboardPusher &&
      dashboardPusher.dashboard &&
      ((dashboardPusher.id_lot === currentLot.lot_number && dashboardPusher.id_variety === currentLot.id_variety) ||
        marketing?.isViewAll)
    ) {
      if (currentMachine.id === 0 || currentMachine.id === dashboardPusher.id_machine) {
        if (activeLotId !== 0 && dashboardPusher.id_lot !== activeLotId) {
          clearDataDashboard()
          syntheticDashboard(dashboardPusher.dashboard, emptyDashboard)
        } else {
          syntheticDashboard(dashboardPusher.dashboard, dashboard)
        }
      } else syntheticDashboardReceiving(dashboardPusher.dashboard, dashboard)

      if (dashboardPusher?.dashboard?.grade_dashboard?.receiving_defect?.average_firmness !== null) {
        const arrFirm = dashboardPusher?.dashboard?.grade_dashboard?.receiving_defect?.average_firmness
        setFirmness((firmness) => [...firmness, ...arrFirm])
      }
    }
    syntheticDailyAverageDashboard(dailyAverageDashboard, dashboardPusher?.dashboard?.grade_dashboard)
    if (
      dashboard &&
      dashboardPusher &&
      dashboardPusher.is_currently_packing &&
      (currentMachine.id === 0 || currentMachine.id === dashboardPusher.id_machine)
    ) {
      setActiveLotId(dashboardPusher.id_lot)
      setActiveVarietyId(dashboardPusher.id_variety)
    }
  }, [dashboardPusher])

  useEffect(() => {
    if (dashboard) {
      dashboardDataCalculate(dashboard)
    }
  }, [dashboard])

  useEffect(async () => {
    const loadData = async () => {
      setLoading(true)
      try {
        if (currentLot.lot_number) {
          const url = `${GROWER_QUERY}?id=${currentLot.id_grower}`
          const response = await RequestService.Get(url, history)
          const { description, name } = response.data[0]
          updateCurrentGrowerNameDispatch(description === '' ? name : description)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    if (currentLot.lot_number || marketing?.isViewAll) {
      loadData()
      setLoading(true)
      await loadDashboardData(currentMachine.id)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [currentLot, currentMachine])

  useEffect(() => {
    setActiveLotId(0)
    setActiveVarietyId(0)
  }, [currentMachine])

  useEffect(() => {
    if (marketing?.isViewAll) {
      updateCurrentMachineDispatch({})
      resetLotStateActionDispatch({})
      updateCurrentGrowerNameDispatch('')
    } else {
      clearDataDashboard()
    }
  }, [marketing])

  useEffect(async () => {
    const loadData = async () => {
      setLoading(true)
      try {
        setIsInitialLoad(false)
        // First we use facility ID to get locations from
        // that specific facility.
        const url = `${LOCATION_QUERY}?id_facility=${currentFacilityId}`
        const resLoction = await RequestService.Get(url, history)

        const locations = {}
        resLoction.data.forEach((item) => {
          if (!locations[item.name]) {
            locations[item.name] = item.id
          }
        })
        setLocations(locations)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (currentFacilityId !== 0) {
      clearDataDashboard()
      setActiveLot(DEFAULT_ACTIVE)
      setActiveVariety(DEFAULT_ACTIVE)
      if (isInitialLoad) await loadData()
    } else {
      setLoading(false)
    }
  }, [currentFacilityId])

  useEffect(async () => {
    if (alertClient !== null && currentFacilityId !== 0) {
      await createTopicAndSubscriptionAlert()
    }
  }, [alertClient, currentFacilityId])

  useEffect(async () => {
    if (alertClient !== null && alertInformation.subscriptionName !== null && alertInformation.topicName !== null) {
      if (alertReceiver) await closeAlertReceiver()
      await createAlertReceiver()
    }
  }, [alertClient, alertInformation])

  useEffect(async () => {
    if (alertClient !== null && alertReceiver !== null) {
      if (alertSubscription) await closeAlertSubscription()
      await startAlertSubscribe()
    }
  }, [alertClient, alertReceiver])

  useEffect(async () => {
    if (activeLotId !== 0 && activeVarietyId !== 0) {
      setLoading(true)
      try {
        if (lots.length === 0) {
          const url = `${LOT_QUERY}?includeInactive=true&all=true`
          const response = await RequestService.Get(url, history)
          setLots(response.data)

          setActiveLot(response.data.filter((lot) => lot.id === activeLotId)[0])
        } else {
          setActiveLot(lots.filter((lot) => lot.id === activeLotId)[0])
        }
        if (varieties.length === 0) {
          const url = `${VARIETY_QUERY}?includeInactive=true&all=true`
          const response = await RequestService.Get(url, history)
          setVarieties(response.data)
          setActiveVariety(response.data.filter((variety) => variety.id === activeVarietyId)[0])
        } else {
          setActiveVariety(varieties.filter((variety) => variety.id === activeVarietyId)[0])
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    } else {
      setActiveLot(DEFAULT_ACTIVE)
      setActiveVariety(DEFAULT_ACTIVE)
    }
  }, [activeLotId, activeVarietyId])

  useEffect(() => {
    if (dailyAverageDashboard) {
      setAverageData([
        {
          name: desTemplates.TEMPLATE_LOC_INL || 'In-Line',
          value: [
            Math.round(
              quanlityToPercent(
                dailyAverageDashboard.inline_defect.defect_minor,
                dailyAverageDashboard.inline_defect.total_count
              ) * 100
            ),
            Math.round(
              quanlityToPercent(
                dailyAverageDashboard.inline_defect.defect_major,
                dailyAverageDashboard.inline_defect.total_count
              ) * 100
            ),
          ],
        },
        {
          name: desTemplates.TEMPLATE_LOC_FIN || 'Finished Box',
          value: [
            Math.round(
              quanlityToPercent(
                dailyAverageDashboard.finished_box_defect.defect_minor,
                dailyAverageDashboard.finished_box_defect.total_count
              ) * 100
            ),
            Math.round(
              quanlityToPercent(
                dailyAverageDashboard.finished_box_defect.defect_major,
                dailyAverageDashboard.finished_box_defect.total_count
              ) * 100
            ),
          ],
        },
        {
          name: 'G.I.B.',
          value: Math.round(
            quanlityToPercent(dailyAverageDashboard.culls.good, dailyAverageDashboard.culls.total_count) * 100
          ),
        },
      ])
    }
  }, [dailyAverageDashboard, desTemplates])

  useInterval(
    async () => {
      await createTopicAndSubscriptionAlert()
    },
    intervalStatus === INTERVAL_STATUS_RUNNING ? 60000 : null
  )

  const loadDashboardData = async (machineId) => {
    if ((currentLot.lot_number > 0 && currentLot.id_variety > 0) || marketing?.isViewAll) {
      const startDateNow = DateTime.now().toISO({ includeOffset: false })
      const endDateNow = DateTime.now().endOf('day').toISO({ includeOffset: false })
      loadDailyAverageDashboardData(machineId)
      const _params = [
        `id_facility=${currentFacilityId}`,
        `id_lot=${currentLot.lot_number}`,
        `id_variety=${currentLot.id_variety}`,
        `start_date=${currentLot?.startDate === undefined ? startDateNow : currentLot?.startDate}`,
        `end_date=${currentLot.endDate === undefined ? endDateNow : currentLot?.endDate}`,
        `id_machine=${machineId}`,
      ]
      const url = `${DASHBOARDV2_QUERY}?${_params.join('&')}`
      const resDashboard = await RequestService.Get(url, history)
      setDashboard(resDashboard.data)
      setEmptyDashboard(resDashboard.data)
      if (resDashboard?.data?.grade_dashboard?.receiving_defect?.average_firmness !== null) {
        setFirmness(resDashboard.data?.grade_dashboard?.receiving_defect?.average_firmness)
      }
      await updateCurrentDashboardDataDispatch(resDashboard.data)
    }
  }

  const loadDailyAverageDashboardData = async (machineId) => {
    if (currentFacilityId && currentFacilityId > 0) {
      const startDateNow = DateTime.now().toISO({ includeOffset: false })
      const endDateNow = DateTime.now().endOf('day').toISO({ includeOffset: false })
      const _params = [
        `id_facility=${currentFacilityId}`,
        `start_date=${currentLot?.startDate === undefined ? startDateNow : currentLot?.startDate}`,
        `end_date=${currentLot.endDate === undefined ? endDateNow : currentLot?.endDate}`,
        `id_machine=${machineId}`,
      ]
      const url = `${DAILY_AVERAGE_DASHBOARD_QUERY}?${_params.join('&')}`
      const resDailyAverage = await RequestService.Get(url, history)
      setDailyAverageDashboard(resDailyAverage.data)
    }
  }

  const createTopicAndSubscriptionAlert = async () => {
    try {
      await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
      const url = `${AB_SUBSCRIPTION_QUERY}?id_facility=${currentFacilityId}&topic_name=1000`
      const response = await RequestService.Post(url, history, null, { isBlankInstance: true })
      await closeAlertSubscription()
      await closeAlertReceiver()
      if (response !== '') {
        setIntervalStatus(INTERVAL_STATUS_IDLE)
        await updateCurrentAlertInformationDispatch({
          topicName: response.data.topic_name,
          subscriptionName: response.data.subscription_name,
        })
      } else {
        enqueueSnackbar('No data to show on Dashboard for given facility, lot and variety. Will retry in 60 seconds.', {
          variant: 'warning',
        })
        setIntervalStatus(INTERVAL_STATUS_RUNNING)
        await updateCurrentAlertInformationDispatch({
          topicName: null,
          subscriptionName: null,
        })
      }
    } catch (error) {
      await closeAlertSubscription()
      await closeAlertReceiver()
      await updateCurrentAlertInformationDispatch({
        topicName: null,
        subscriptionName: null,
      })
      console.error(error)
    }
  }

  const startAlertSubscribe = async () => {
    if (!alertReceiver._isClosed) {
      await setAlertSubscription(
        alertReceiver.subscribe({
          processError: async (args) => errorHandler(args, ALERT_SUBSCRIPTION),
          processMessage: async (message) => alertMessageHandler(message),
        })
      )
      trackEvent(LOG_SUBSCRIPTION_ACTIONS.CREATED_ALERT_SUBSCRIPTION_SUCCESSFULLY, {
        email: userEmail,
        topicName: alertInformation.topicName,
        subscriptionName: alertInformation.subscriptionName,
      })
    }
    setLoading(false)
  }

  const errorHandler = async (args, type = DASHBOARD_SUBSCRIPTION) => {
    if (isServiceBusError(args.error)) {
      switch (args.error.code) {
        case 'MessagingEntityDisabled':
        case 'MessagingEntityNotFound':
        case 'UnauthorizedAccess':
          console.log(`An unrecoverable error occurred. Stopping processing. ${args.error.code}`, args.error)
          if (type === ALERT_SUBSCRIPTION) {
            await cleanUpAlertContext()
            await deleteSubscription(alertInformation.topicName, alertInformation.subscriptionName, ALERT_SUBSCRIPTION)
            await updateCurrentAlertInformationDispatch({
              topicName: null,
              subscriptionName: null,
            })
          } else if (type === DASHBOARD_SUBSCRIPTION) {
            await cleanUpDashboardContext()
            await deleteSubscription(
              dashboardInformation.topicName,
              dashboardInformation.subscriptionName,
              DASHBOARD_SUBSCRIPTION
            )
            await updateCurrentDashboardInformationDispatch({
              topicName: null,
              subscriptionName: null,
            })
          } else {
            await deleteSubscription(notificationInformation.topicName, notificationInformation.subscriptionName)
            await updateCurrentNotificationInformationDispatch({
              topicName: null,
              subscriptionName: null,
            })
          }

          break
        case 'MessageLockLost':
          console.log(`Message lock lost for message`, args.error)
          break
        case 'ServiceBusy':
          // choosing an arbitrary amount of time to wait.
          await delay(1000)
          break
        default:
          console.log(args.error, args.error.code)
      }
    }
  }

  const createAlertReceiver = async () => {
    await setAlertReceiver(
      alertClient.createReceiver(alertInformation.topicName, alertInformation.subscriptionName, {
        receiveMode: 'receiveAndDelete',
      })
    )
  }

  const clearDataDashboard = () => {
    setSizeData({})
    setTotalRec(0)
    setTotalUps(0)
    setReceiveUpstreamData({
      receivingAverage: 0,
      upstreamAverage: 0,
      estimatedPackOut: 0,
    })
    setGibData([
      { name: 'G.I.B.', value: 0 },
      { name: 'D/S', value: 0 },
    ])
    setInlineData({})
    setFinishBoxData([
      { name: 'Minor Defect', value: 0 },
      { name: 'Major Defect', value: 0 },
    ])
    setAverageData([
      { name: desTemplates.TEMPLATE_LOC_INL || 'In-Line', value: [0, 0] },
      {
        name: desTemplates.TEMPLATE_LOC_FIN || 'Finished Box',
        value: [0, 0],
      },
      { name: 'G.I.B.', value: 0 },
    ])
  }

  const createAlertClient = async () => {
    await setAlertClient(new ServiceBusClient(process.env.REACT_APP_SERVICE_BUS_CONNECTION_STRING))
  }

  const dashboardDataCalculate = (data) => {
    const gradeDashboard = parseDashboard(data.grade_dashboard)
    setGradeDashboardData(gradeDashboard)

    setSizeData({
      result: data.size_dashboard,
    })

    data.size_dashboard.forEach((item) => {
      if (item.id_lot === currentLot.lot_number && item.id_sampletype === 1) {
        if (item.template_location === 'LOC_REC') {
          setTotalRec(item.sample_total)
        }
        if (item.template_location === 'LOC_UPS') {
          setTotalUps(item.sample_total)
        }
      }
    })
    setLoading(false)
  }

  const setGradeDashboardData = (dashboardData) => {
    const {
      averagedefectsforinlineandfinishedboxbylot,
      averagedefectsforreceivingandupstreambylot,
      averagegoodinbadanddoublespursbylot,
      averagegoodinpeddlerbylot,
    } = dashboardData

    if (averagedefectsforreceivingandupstreambylot) {
      // GET RECEIVING AND UPSTREAM
      setReceiveUpstreamData({
        receivingAverage: Math.round(averagedefectsforreceivingandupstreambylot.average_receiving * 100),
        upstreamAverage: Math.round(averagedefectsforreceivingandupstreambylot.average_upstream * 100),
        estimatedPackOut: Math.round(averagedefectsforreceivingandupstreambylot.estimated_packout * 100),
      })
    }

    if (averagedefectsforinlineandfinishedboxbylot) {
      // GET INLINE AND FINISH BOX BY LOT
      setInlineData({
        major: Math.round(averagedefectsforinlineandfinishedboxbylot.defect_major_inline * 100),
        minor: Math.round(averagedefectsforinlineandfinishedboxbylot.defect_minor_inline * 100),
      })
      setFinishBoxData([
        {
          name: 'Minor Defect',
          value: Math.round(averagedefectsforinlineandfinishedboxbylot.defect_minor_finishedbox * 100),
        },
        {
          name: 'Major Defect',
          value: Math.round(averagedefectsforinlineandfinishedboxbylot.defect_major_finishedbox * 100),
        },
      ])
    }

    if (averagegoodinbadanddoublespursbylot) {
      // GET GIB AND DOUBLE SPURS BY LOT
      setGibData([
        { name: 'G.I.B.', value: Math.round(averagegoodinbadanddoublespursbylot.gib * 100) },
        { name: 'D/S', value: Math.round(averagegoodinbadanddoublespursbylot.ds * 100) },
      ])
    }
    if (averagegoodinpeddlerbylot) {
      // GET GIP BY LOT
      setGipData([{ name: 'G.I.P.', value: Math.round(averagegoodinpeddlerbylot.gip * 100) }])
    }
  }

  const parseDashboard = (data) => {
    const averageReceiving =
      data.receiving_defect.total_count > 0
        ? 1 - quanlityToPercent(data.receiving_defect.defect, data.receiving_defect.total_count)
        : 0
    const averageUpstream =
      data.upstream_defect.total_count > 0
        ? 1 - quanlityToPercent(data.upstream_defect.defect, data.upstream_defect.total_count)
        : 0
    const divider = averageReceiving > 0 && averageUpstream > 0 ? 2 : 1
    const estimatedPackOut = (averageReceiving + averageUpstream) / divider

    const defectMajorInline = quanlityToPercent(data.inline_defect.defect_major, data.inline_defect.total_count)
    const defectMinorInline = quanlityToPercent(data.inline_defect.defect_minor, data.inline_defect.total_count)
    const defectMajorFinishedbox = quanlityToPercent(
      data.finished_box_defect.defect_major,
      data.finished_box_defect.total_count
    )
    const defectMinorFinishedbox = quanlityToPercent(
      data.finished_box_defect.defect_minor,
      data.finished_box_defect.total_count
    )

    return {
      averagedefectsforreceivingandupstreambylot: {
        average_receiving: averageReceiving,
        average_upstream: averageUpstream,
        estimated_packout: estimatedPackOut,
      },
      averagedefectsforinlineandfinishedboxbylot: {
        defect_major_inline: defectMajorInline,
        defect_minor_inline: defectMinorInline,
        defect_major_finishedbox: defectMajorFinishedbox,
        defect_minor_finishedbox: defectMinorFinishedbox,
      },
      averagegoodinbadanddoublespursbylot: {
        gib: quanlityToPercent(data.culls.good, data.culls.total_count),
        ds: quanlityToPercent(data.culls.doble_spurs, data.culls.total_count),
      },
      averagegoodinpeddlerbylot: {
        gip: quanlityToPercent(data.peddler.good, data.peddler.total_count),
      },
    }
  }
  const quanlityToPercent = (quantity, totalCount) => {
    if (totalCount === 0) return 0
    return quantity / totalCount
  }

  const alertMessageHandler = async (message) => {
    if (message && message.body) {
      const bodyMessage = message.body
      switch (bodyMessage.type_message) {
        case typeMessage.NOTIFICATION:
          setNotificationPusher(bodyMessage)
          break
        default:
          setDashboardPusher(bodyMessage)
          break
      }
    }
  }

  const syntheticDashboardReceiving = async (dashboardPusher, dashboardOld) => {
    let dashboard = {}
    dashboard = JSON.parse(JSON.stringify(dashboardOld))
    dashboard.grade_dashboard.receiving_defect.defect += dashboardPusher.grade_dashboard.receiving_defect.defect
    dashboard.grade_dashboard.receiving_defect.total_count +=
      dashboardPusher.grade_dashboard.receiving_defect.total_count

    const sizeRec = dashboardPusher.size_dashboard.find((x) => x.template_location === 'LOC_REC')
    if (sizeRec) {
      const index = dashboard.size_dashboard.findIndex((x) => x.template_location === 'LOC_REC')

      if (index !== -1) {
        dashboard.size_dashboard[index].sample_total += sizeRec.sample_total
        const sample_dataCs = sizeRec.sample_data
        const sample_dataIs = dashboard.size_dashboard[index].sample_data
        const data = [...sample_dataCs, ...sample_dataIs]
        const result = []
        data.forEach((curr) => {
          const sample_dataI = result.find((item) => item.id === curr.id)
          if (sample_dataI) {
            const index = result.findIndex((item) => item.id === curr.id)
            result[index].quantity = curr.quantity + sample_dataI.quantity
            result[index].undersize = curr.undersize + sample_dataI.undersize
            result[index].oversize = curr.oversize + sample_dataI.oversize
          } else {
            result.push(curr)
          }
        })
        dashboard.size_dashboard[index].sample_data = result
      } else {
        dashboard.size_dashboard.push(sizeRec)
      }
    }
    setDashboard(dashboard)
    await updateCurrentDashboardDataDispatch(dashboard)
  }

  const syntheticDashboard = async (dashboardPusher, dashboard) => {
    const resSyn = {
      grade_dashboard: {
        receiving_defect: {
          defect:
            dashboard.grade_dashboard.receiving_defect.defect + dashboardPusher.grade_dashboard.receiving_defect.defect,
          total_count:
            dashboard.grade_dashboard.receiving_defect.total_count +
            dashboardPusher.grade_dashboard.receiving_defect.total_count,
        },
        upstream_defect: {
          defect:
            dashboard.grade_dashboard.upstream_defect.defect + dashboardPusher.grade_dashboard.upstream_defect.defect,
          total_count:
            dashboard.grade_dashboard.upstream_defect.total_count +
            dashboardPusher.grade_dashboard.upstream_defect.total_count,
        },
        inline_defect: {
          defect_minor:
            dashboard.grade_dashboard.inline_defect.defect_minor +
            dashboardPusher.grade_dashboard.inline_defect.defect_minor,
          defect_major:
            dashboard.grade_dashboard.inline_defect.defect_major +
            dashboardPusher.grade_dashboard.inline_defect.defect_major,
          total_count:
            dashboard.grade_dashboard.inline_defect.total_count +
            dashboardPusher.grade_dashboard.inline_defect.total_count,
        },
        finished_box_defect: {
          defect_minor:
            dashboard.grade_dashboard.finished_box_defect.defect_minor +
            dashboardPusher.grade_dashboard.finished_box_defect.defect_minor,
          defect_major:
            dashboard.grade_dashboard.finished_box_defect.defect_major +
            dashboardPusher.grade_dashboard.finished_box_defect.defect_major,
          total_count:
            dashboard.grade_dashboard.finished_box_defect.total_count +
            dashboardPusher.grade_dashboard.finished_box_defect.total_count,
        },
        culls: {
          good: dashboard.grade_dashboard.culls.good + dashboardPusher.grade_dashboard.culls.good,
          bad: dashboard.grade_dashboard.culls.bad + dashboardPusher.grade_dashboard.culls.bad,
          doble_spurs: dashboard.grade_dashboard.culls.doble_spurs + dashboardPusher.grade_dashboard.culls.doble_spurs,
          total_count: dashboard.grade_dashboard.culls.total_count + dashboardPusher.grade_dashboard.culls.total_count,
        },
        peddler: {
          good: dashboard.grade_dashboard.peddler.good + dashboardPusher.grade_dashboard.peddler.good,
          bad: dashboard.grade_dashboard.peddler.bad + dashboardPusher.grade_dashboard.peddler.bad,
          peddler: dashboard.grade_dashboard.peddler.peddler + dashboardPusher.grade_dashboard.peddler.peddler,
          total_count:
            dashboard.grade_dashboard.peddler.total_count + dashboardPusher.grade_dashboard.peddler.total_count,
        },
      },
      size_dashboard: syntheticSizingData(dashboard.size_dashboard, dashboardPusher.size_dashboard),
    }
    setDashboard(resSyn)
    await updateCurrentDashboardDataDispatch(resSyn)
  }

  const CalAverageFirmness = (firmness) => {
    if (firmness && firmness.length > 0) {
      const sum = firmness.reduce((a, b) => a + b, 0)
      const avgFirmness = Math.round(sum / firmness.length || 0)
      return avgFirmness
    }
    return 0
  }

  const syntheticSizingData = (sizesOld, sizesNew) => {
    let containers = []
    let items = []
    if (sizesOld.length > sizesNew.length) {
      containers = JSON.parse(JSON.stringify(sizesOld))
      items = JSON.parse(JSON.stringify(sizesNew))
    } else {
      containers = JSON.parse(JSON.stringify(sizesNew))
      items = JSON.parse(JSON.stringify(sizesOld))
    }
    for (let i = 0; i < containers.length; i++) {
      let container = {}
      container = containers[i]
      const item = items.find(
        (x) =>
          x.id_facility === container.id_facility &&
          x.id_variety === container.id_variety &&
          x.template_location === container.template_location &&
          x.id_lot === container.id_lot &&
          x.id_sampletype === container.id_sampletype
      )
      if (item) {
        container.sample_total += item.sample_total
        const sample_dataCs = container.sample_data
        const sample_dataIs = item.sample_data
        const data = [...sample_dataCs, ...sample_dataIs]
        const result = []
        data.forEach((curr) => {
          const sample_dataI = result.find((item) => item.id === curr.id)
          if (sample_dataI) {
            const index = result.findIndex((item) => item.id === curr.id)
            result[index].quantity = curr.quantity + sample_dataI.quantity
            result[index].undersize = curr.undersize + sample_dataI.undersize
            result[index].oversize = curr.oversize + sample_dataI.oversize
          } else {
            result.push(curr)
          }
        })
        container.sample_data = result
      }
    }

    const notContain = items.filter(
      (x) =>
        !containers.some(
          (z) =>
            x.id_facility === z.id_facility &&
            x.id_variety === z.id_variety &&
            x.template_location === z.template_location &&
            x.id_lot === z.id_lot &&
            x.id_sampletype === z.id_sampletype
        )
    )
    if (notContain && notContain.length > 0) {
      containers = [...containers, ...notContain]
    }

    return containers
  }

  const syntheticDailyAverageDashboard = (dailyAverageOld, dailyAverageNew) => {
    if (!dailyAverageOld || !dailyAverageNew) return

    const dailyAverage = {
      inline_defect: {
        defect_minor:
          (dailyAverageOld?.inline_defect?.defect_minor ?? 0) + (dailyAverageNew?.inline_defect?.defect_minor ?? 0),
        defect_major:
          (dailyAverageOld?.inline_defect?.defect_major ?? 0) + (dailyAverageNew?.inline_defect?.defect_major ?? 0),
        total_count:
          (dailyAverageOld?.inline_defect?.total_count ?? 0) + (dailyAverageNew?.inline_defect?.total_count ?? 0),
      },
      finished_box_defect: {
        defect_minor:
          (dailyAverageOld?.finished_box_defect?.defect_minor ?? 0) +
          (dailyAverageNew?.finished_box_defect?.defect_minor ?? 0),
        defect_major:
          (dailyAverageOld?.finished_box_defect?.defect_major ?? 0) +
          (dailyAverageNew?.finished_box_defect?.defect_major ?? 0),
        total_count:
          (dailyAverageOld?.finished_box_defect?.total_count ?? 0) +
          (dailyAverageNew?.finished_box_defect?.total_count ?? 0),
      },
      culls: {
        good: (dailyAverageOld?.culls?.good ?? 0) + (dailyAverageNew?.culls?.good ?? 0),
        total_count: (dailyAverageOld?.culls?.total_count ?? 0) + (dailyAverageNew?.culls?.total_count ?? 0),
      },
    }
    setDailyAverageDashboard(dailyAverage)
  }

  console.log(dashboard, 'day la gi')

  console.log(dashboardPusher, 'gia tri dashboardpusher')
  return (
    <>
      <JoyrideContainerComponent
        show={
          currentLot.lot_number === '' &&
          currentLot.lot_name === '' &&
          currentLot.id_variety === '' &&
          currentLot.variety === ''
        }
        steps={DASHBOARD_STEPS}
      />
      <Drawer variant="temporary" anchor="right" open={isDrawerOpen}>
        <LotChange onClose={() => setDrawer(false)} />
      </Drawer>

      <Box pt={2}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={showCurrentlyPackingData ? 4 : 6}>
            <Typography component="h1" variant="h5" color="primary">
              {currentFacility.description !== '' ? currentFacility.description : currentFacility.name}
              {currentMachine.id > 0 ? (
                <Typography component="h1" variant="subtitle2" color="primary">
                  {currentMachine.name ? currentMachine.name : currentMachine.description}
                </Typography>
              ) : null}
              <Clock />
            </Typography>
          </Grid>
          {showCurrentlyPackingData ? (
            <Grid item xs={12} sm={5} style={{ textAlign: 'right' }}>
              <Typography component="h1" variant="h6" color="primary">
                Currently Packing at Facility:
              </Typography>
              <Typography component="h1" variant="subtitle1" color="primary">
                Lot #: {activeLot?.name ?? activeLot?.description}
              </Typography>
              <Typography component="h1" variant="subtitle1" color="primary">
                Variety: {activeVariety?.description ?? activeVariety?.name}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={showCurrentlyPackingData ? 3 : 6} style={{ textAlign: 'right' }}>
            <div
              style={{
                textAlign: 'right',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Typography component="h1" variant="subtitle1" color="primary">
                View Data
              </Typography>
              <Switch
                onChange={(event) => {
                  setMarketing({ isViewAll: event.target.checked })
                }}
              />
            </div>
            <PrimaryButton id="lot-change-button" type="button" onClick={() => setDrawer(true)}>
              {moreThanOneFacility ? 'Facility &' : ''} Lot Change
            </PrimaryButton>
          </Grid>
        </Grid>
      </Box>
      <Box pb={1} pt={1}>
        <div style={{ border: '1px solid #eee' }} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography component="h1" variant="subtitle1" color="primary">
            Currently Viewing:{' '}
            {currentLot.advancedChecked
              ? `${DateTime.fromISO(currentLot.startDate).toFormat('MMM-dd-yyyy')} to ${
                  currentLot.endDate.slice(0, 10) !== DateTime.now().toISO().slice(0, 10)
                    ? DateTime.fromISO(currentLot.endDate).toFormat('MMM-dd-yyyy')
                    : 'Today'
                }`
              : 'Today'}
          </Typography>
          <PackOutChart grower={growerName} value={89} averageFirmness={CalAverageFirmness(firmness)} />
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'grid', alignContent: 'space-between' }}>
          <DashboardBar
            title={`${desTemplates.TEMPLATE_LOC_REC || 'Receiving'}`}
            value={receiveUpstreamData.receivingAverage}
          />
          <DashboardBar
            title={`${desTemplates.TEMPLATE_LOC_UPS || 'Upstream'}`}
            value={receiveUpstreamData.upstreamAverage}
          />
          <DashboardBar title="Estimated Pack-out1" value={receiveUpstreamData.estimatedPackOut} />
        </Grid>
        <Grid item xs={12} sm={4} style={{ display: 'grid', alignContent: 'space-between' }}>
          <Typography component="h1" variant="subtitle1" color="primary">
            {desTemplates.TEMPLATE_LOC_INL || 'In-Line'}
          </Typography>
          <InLineChart value1={inlineData.minor} name1="Minor Defect" value2={inlineData.major} name2="Major Defect" />
        </Grid>
      </Grid>
      <Box pt={1}>
        <Grid container>
          <Grid item sm={3} xs={12}>
            <DashboardCard
              title={`${desTemplates.TEMPLATE_LOC_FIN || 'Finished Box'}`}
              icon="layers"
              data={finishBoxData}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <DashboardCard title="G.I.P." icon="texture" data={gipData} />
          </Grid>
          <Grid item sm={2} xs={12}>
            <DashboardCard title="G.I.B." icon="texture" data={gibData} />
          </Grid>
          <Grid item sm={5} xs={12}>
            <DashboardCard title="Daily Average" icon="calendar_today" data={averageData} />
          </Grid>
        </Grid>
      </Box>
      <Box pt={1}>
        <Grid container>
          <Grid item xs={12}>
            <SizeChart
              newData={sizeData}
              locations={locations}
              totalRec={totalRec}
              totalUps={totalUps}
              desTemplates={desTemplates}
            />
          </Grid>
        </Grid>
      </Box>
      <SimpleBackdrop
        open={loading || isLoadingQuery}
        onClick={() => {
          setLoading(false)
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
  currentFacilityId: state.facility.currentFacilityId,
  currentFacility: state.facility.currentFacility,
  facilities: state.facility.facilities,
  moreThanOneFacility: state.facility.moreThanOneFacility,
  growerName: state.grower.growerName,
  dashboardInformation: state.subscription.dashboardInformation,
  notificationInformation: state.subscription.notificationInformation,
  alertInformation: state.subscription.alertInformation,
  userEmail: state.user.email,
  currentMachine: state.machine,
  marketing: state.dashboard.marketing,
})

const mapDispatchToProps = (dispatch) => ({
  updateCurrentGrowerNameDispatch: (growerName) => dispatch(setCurrentGrowerNameAction(growerName)),
  updateCurrentDashboardInformationDispatch: (dashboardInformation) =>
    dispatch(setCurrentDashboardInformationAction(dashboardInformation)),
  updateCurrentDashboardDataDispatch: (dashboardData) => dispatch(updateCurrentDashboardData(dashboardData)),
  updateCurrentAlertInformationDispatch: (alertInformation) =>
    dispatch(setCurrentAlertInformationAction(alertInformation)),
  updateCurrentNotificationInformationDispatch: (notificationInformation) =>
    dispatch(setCurrentNotificationInformationAction(notificationInformation)),
  setNotificationPusher: (notificationPush) => dispatch(setCurrentNotificationAction(notificationPush)),
  setMarketing: (marketing) => dispatch(setCurrentMarketingAction(marketing)),
  resetLotStateActionDispatch: () => dispatch(resetLotStateAction()),
  updateCurrentMachineDispatch: (machine) => dispatch(setCurrentMachineAction(machine)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
