const ReportsActionTypes = {
  SET_SELECTED_LOT: 'SET_SELECTED_LOT',
  SET_SELECTED_LOT_NAME: 'SET_SELECTED_LOT_NAME',
  SET_SELECTED_VARIETY: 'SET_SELECTED_VARIETY',
  SET_SELECTED_VARIETY_NAME: 'SET_SELECTED_VARIETY_NAME',
  SET_SELECTED_GROWER: 'SET_SELECTED_GROWER',
  SET_SELECTED_GROWER_NAME: 'SET_SELECTED_GROWER_NAME',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_START_DATE_ISO: 'SET_START_DATE_ISO',
  SET_END_DATE_ISO: 'SET_END_DATE_ISO',
  SET_SELECTED_MACHINE: 'SET_SELECTED_MACHINE',
  SET_SELECTED_SEASON: 'SET_SELECTED_SEASON',
}

export default ReportsActionTypes
