import lotsActionTypes from './lots.types'

export const setCurrentLotAction = (lotData) => ({
  type: lotsActionTypes.SET_CURRENT_LOT,
  payload: lotData,
})

export const setSavedLotAction = (lotData) => ({
  type: lotsActionTypes.SET_SAVED_LOT,
  payload: lotData,
})

export const resetLotStateAction = () => ({
  type: lotsActionTypes.RESET_LOT_STATE,
})

export const setCurrentLotTimeAction = (lotData) => ({
  type: lotsActionTypes.SET_CURRENT_LOTTIME,
  payload: lotData,
})
