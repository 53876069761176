import React, { useState } from 'react'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import SingleData from '../singledata/singledata.component'

import useStyles from './packout-chart.styles'

const PackOutChart = ({ currentLot, ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.lotText} color="primary" component="h5" variant="h5">
            Lot #{currentLot.lot_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SingleData title="Variety" value={currentLot.variety} />
        </Grid>
        <Grid item xs={12}>
          <SingleData title="Grower" value={props.grower} />
        </Grid>
        <Grid item xs={12}>
          <SingleData title="Firmness" value={props.averageFirmness} />
        </Grid>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select the variety of the lot</DialogTitle>
        <DialogContent>
          <form>
            <FormControl fullWidth>
              <InputLabel id="demo-dialog-select-label">Variety</InputLabel>
              <Select labelId="demo-dialog-select-label" id="demo-dialog-select" input={<Input />}>
                <MenuItem value={10}>Sweetheart</MenuItem>
                <MenuItem value={20}>Bing</MenuItem>
                <MenuItem value={30}>Rainier</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentLot: state.lots.currentLot,
})

export default connect(mapStateToProps)(PackOutChart)
