import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import userReducer from './user/user.reducer'
import drawerReducer from './drawer/drawer.reducer'
import lotReducer from './lots/lots.reducer'
import reportsReducer from './reports/reports.reducer'
import facilityReducer from './facility/facility.reducer'
import growerReducer from './grower/grower.reducer'
import subscriptionReducer from './subscription/subscription.reducer'
import dashboardReducer from './dashboard/dashboard.reducer'
import machineReducer from './machine/machine.reducer'
import notificationReducer from './notification/notification-pusher.reducer'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['user', 'lots', 'subscription', 'facility', 'machine', 'lottime'], // will persist
}

const rootReducer = combineReducers({
  user: userReducer,
  drawer: drawerReducer,
  lots: lotReducer,
  reports: reportsReducer,
  facility: facilityReducer,
  grower: growerReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardReducer,
  machine: machineReducer,
  notificationPusher: notificationReducer,
})

export default persistReducer(persistConfig, rootReducer)
