import React, { useEffect, useState } from 'react'
import { Select, MenuItem, Button, OutlinedInput, FormControl, InputLabel, TextField, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { LOCATION_QUERY, GET_SAMPLE_FIELD, COLOR_QUERY, DEFECT_QUERY } from '../../../shared/constants/queries'
import RequestService from '../../../services/request/request-service'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 500,
  },
  input: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    height: '40px !important',
    '& > div > div': {
      paddingLeft: '14px',
      margin: '0 !important',
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus-within': {
      borderWidth: '2px',
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  textField: {
    height: '40px !important',
    '& > div': {
      height: '40px !important',
    },
    '& > label': {
      marginLeft: '5px',
      top: '-10px',
      backgroundColor: '#fff',
      padding: '0 9px',
    },
  },
}))

export default function RulesForm(props) {
  const { rowSelected, isNext, setHandleNext, submitForm } = { ...props }
  const [rowData, setRowData] = useState(rowSelected)
  const [rows, setRows] = useState(rowSelected.rules || [])
  const [locations, setLocations] = useState([])
  const [fields, setFields] = useState([])
  const [idLocation, setIdLocation] = useState(1)
  const [idSampleType, setIdSampleType] = useState(1)
  const { enqueueSnackbar } = useSnackbar()
  const [colors, setColors] = useState([])
  const [defects, setDefects] = useState([])
  const classes = useStyles()

  const sampleTypes = [
    { id: 1, name: 'Size', description: 'Size' },
    { id: 2, name: 'Grade', description: 'Grade' },
  ]
  const operatorTypes = [
    { id: 1, name: 'LessThan', description: 'Less Than (<)' },
    { id: 2, name: 'GreaterThan', description: 'Greater Than (>)' },
    { id: 3, name: 'LessThanOrEqual', description: 'Less Than Or Equal (≤)' },
    { id: 4, name: 'GreaterThanOrEqual', description: 'Greater Than Or Equal (≥)' },
  ]
  const fieldGradeTypes = [
    { id: 1, name: 'Minor', description: 'Minor' },
    { id: 2, name: 'Major', description: 'Major' },
  ]
  const fieldsReceiving = [
    { name: 'Minor', description: 'Minor' },
    { name: 'Major', description: 'Major' },
    { name: 'Firmness', description: 'Firmness' },
    { name: 'Estimated Pack-Out', description: 'Estimated Pack-Out' },
  ]
  const fieldsCull = [
    { name: 'Good', description: 'Good' },
    { name: 'Bad', description: 'Bad' },
    { name: 'Doubles/Spurs', description: 'Doubles/Spurs' },
  ]
  const fieldsPeddler = [
    { id: 1, name: 'Good', description: 'Good' },
    { id: 2, name: 'Bad', description: 'Bad' },
    { id: 3, name: 'Peddler', description: 'Peddler' },
  ]
  const locationsCullAndPed = ['LOC-CUL', 'LOC-PED']
  const locationReceiving = 'LOC-REC'
  const locationUpstream = 'LOC-UPS'

  const handleChange = (id, name, value) => {
    rows.forEach((x) => {
      if (x.id === id) {
        const a = { [name]: value }
        const returnedTarget = Object.assign(x, a)
        setRowData({
          ...rowData,
          returnedTarget,
        })
      }
    })
  }
  const history = useHistory()
  const makeId = (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  useEffect(async () => {
    let url = `${COLOR_QUERY}?startRowIndex=0&pageSize=10000`
    const tskColor = RequestService.Get(url, history)

    url = `${DEFECT_QUERY}?startRowIndex=0&pageSize=10000`
    const tskDefect = RequestService.Get(url, history)

    const [resColor, resDefect] = await Promise.all([tskColor, tskDefect])

    setColors(resColor.data)
    setDefects(resDefect.data)
  }, [])

  useEffect(async () => {
    async function fetchData() {
      try {
        const loadLocation = new Promise(() => {
          const locationURL = `${LOCATION_QUERY}?startRowIndex=0&pageSize=10000`
          RequestService.Get(locationURL, history).then((locationReponse) => {
            setLocations(locationReponse.data)
          })
        })

        Promise.all([loadLocation])
      } catch (error) {
        console.error(error)
      }
    }

    await fetchData()
  }, [rowSelected])

  useEffect(async () => {
    if (isNext) {
      if (rows.length > 0) {
        await submitForm('rules', rows)
        setHandleNext(true)
      } else {
        enqueueSnackbar('Please add rule to config alert', { variant: 'warning' })
        setHandleNext(false)
      }
    }
  }, [isNext, rows])

  useEffect(async () => {
    async function fetchData() {
      if (idLocation !== 0 && idSampleType === 1)
        try {
          const loadSampleField = new Promise(() => {
            // eslint-disable-next-line max-len
            const fieldSampleURL = `${GET_SAMPLE_FIELD}?id_variety=3&id_location=${idLocation}&sample_type=${idSampleType}`
            RequestService.Get(fieldSampleURL, history).then((response) => {
              setFields(response.data.sample_data)
            })
          })

          Promise.all([loadSampleField])
        } catch (error) {
          console.error(error)
        }
    }

    await fetchData()
  }, [idLocation, idSampleType])

  const defaultRule = { id: makeId(5), field: '', id_sample_type: 2, id_location: 1, operator: null, value: null }
  const handleEditRowsModelChange = React.useCallback((model) => {
    const id = Object.keys(model)[0]
    if (Object.values(model)[0]?.value?.value === null) {
      rows.forEach((x) => {
        if (x.id === id) {
          const a = { value: 0 }
          const returnedTarget = Object.assign(x, a)
          setRowData({
            ...rowData,
            returnedTarget,
          })
        }
      })
    } else {
      rows.forEach((x) => {
        if (x.id === id) {
          const a = { value: Object.values(model)[0]?.value?.value }
          const returnedTarget = Object.assign(x, a)
          setRowData({
            ...rowData,
            returnedTarget,
          })
        }
      })
    }
  }, [])
  const handleClick = () => {
    setRows((prevState) => [...prevState, defaultRule])
  }
  const handleDelete = (rowId) => {
    const newrows = rows.map((el) => el)
    const index = newrows.map((e) => e.id).indexOf(rowId)
    if (index > -1) {
      newrows.splice(index, 1)
    }
    setRows(newrows)
  }
  const columns = [
    {
      field: 'id_location',
      headerName: 'Location',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => {
        return (
          <FormControl fullWidth size="small" variant="outlined" margin="none">
            <Select
              id="demo-simple-select"
              value={params.value}
              onChange={(event) => {
                handleChange(params.id, params.field, event.target.value)
                event.stopPropagation()
                setIdLocation(event.target.value)
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              required
            >
              {locations.map((item, index) => (
                <MenuItem key={`cats-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
    },
    {
      field: 'id_sample_type',
      headerName: 'Sample Type',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => {
        return (
          <FormControl fullWidth size="small" variant="outlined" margin="none">
            <Select
              id="demo-simple-select"
              value={params.value}
              onChange={(event) => {
                handleChange(params.id, params.field, event.target.value)
                event.stopPropagation()
                setIdSampleType(event.target.value)
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              required
            >
              {(locations.some((x) => locationsCullAndPed.includes(x.name) && x.id === params.row.id_location)
                ? sampleTypes.filter((x) => x.id === 2)
                : sampleTypes
              ).map((item) => (
                <MenuItem key={`loc-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
    },
    {
      field: 'field',
      headerName: 'Field',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => {
        const _options =
          params.row.id_sample_type === 2
            ? locations.some((x) => x.id === params.row.id_location && x.name === locationsCullAndPed[0])
              ? fieldsCull
              : locations.some((x) => x.id === params.row.id_location && x.name === locationsCullAndPed[1])
              ? fieldsPeddler
              : locations.some((x) => x.id === params.row.id_location && x.name === locationReceiving)
              ? [...fieldsReceiving, ...defects, ...colors]
              : locations.some((x) => x.id === params.row.id_location && x.name === locationUpstream)
              ? [...fieldGradeTypes, { name: 'Estimated Pack-Out' }, ...defects]
              : [...fieldGradeTypes, ...defects]
            : fields
        return (
          <FormControl fullWidth size="small" variant="outlined" margin="none">
            <Autocomplete
              disabled={idLocation === 0 && idSampleType === 0}
              value={_options.find((x) => x.name === params.value) ?? 0}
              onChange={(event, newValue) => {
                event.stopPropagation()
                handleChange(params.id, params.field, newValue?.name)
              }}
              options={_options}
              renderInput={(paramss) => <TextField required className={classes.textField} {...paramss} />}
              getOptionLabel={(option) => {
                return option.name
              }}
              className={classes.input}
              noOptionsText="No results"
            />
          </FormControl>
        )
      },
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 275,
      renderCell: (params) => {
        return (
          <FormControl fullWidth size="small" variant="outlined" margin="none">
            <Select
              id="demo-simple-select"
              value={params.value}
              onChange={(event) => {
                handleChange(params.id, params.field, event.target.value)
                event.stopPropagation()
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              required
            >
              {operatorTypes.map((item) => (
                <MenuItem key={`operator-${item.id}`} value={item.id}>
                  {item.description ? item.description : item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      },
    },
    {
      field: 'value',
      headerName: 'Value',
      minWidth: 115,
      flex: 1,
      renderCell: (params) => {
        return (
          <TextField
            margin="none"
            id="value"
            variant="outlined"
            fullWidth
            defaultValue={params?.value}
            type="number"
            required
            // inputProps={{
            //   min: 0,
            //   max: 100,
            //   inputMode: 'numeric',
            //   pattern: 'd*',
            // }}
            onChange={(event) => {
              handleChange(params.id, params.field, parseFloat(event.target.value))
              event.stopPropagation()
            }}
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault()
              }
            }}
          />
        )
      },
    },
    {
      field: 'id',
      headerName: 'Action',
      width: 115,
      renderCell: (params) => {
        return <Button color="primary" startIcon={<DeleteIcon />} onClick={() => handleDelete(params.id)} />
      },
    },
  ]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add rule
        </Button>
      </Grid>

      <Grid item xs={12} style={{ height: '500px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          onEditRowsModelChange={handleEditRowsModelChange}
          hideFooterPagination
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  )
}
