import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import DialogAlert from '../../components/alert/alert.component'
import TableContainer from '../../components/table-container/table-container'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'
import useHttpInactive from '../../shared/hooks/useHttpInactive'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_DEPENDENCIES_ERROR_MESSAGE, DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { CATEGORY_QUERY, CATEGORY_TYPE_QUERY } from '../../shared/constants/queries'
import { DELETE_METHOD, POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'

const CategoryType = ({ userEmail }) => {
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [deletable, setDeletable] = useState(true)
  const [showActive, setShowActive] = useState(true)
  const { executeInactive, loadingInactive } = useHttpInactive(CATEGORY_TYPE_QUERY)
  // columns definition for table
  const columns = [
    ...BASE_TABLE_COLUMNS,
    {
      ...ACTIONS_COLUMN,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  useEffect(() => {
    trackEvent(LOG_PAGE_LOADS.LOAD_CATEGORY_TYPE_PAGE, { email: userEmail })
  }, [])

  // load data on mount and on pagination changes
  useEffect(() => {
    loadData()
  }, [tableConf, showActive])

  const loadData = async () => {
    try {
      setLoading(true)
      setRows([])
      const url = `${CATEGORY_TYPE_QUERY}?${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setRows(response.data)
    } catch (error) {
      console.error(error)
      setSeverity(ALERT_TYPES.error)
      setOpenAlert(true)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let id = ''
    if (method === DELETE_METHOD) {
      id = `?id=${data.id}`
    }
    const url = `${CATEGORY_TYPE_QUERY}${id}`
    let response
    if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
    if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)
    if (method === DELETE_METHOD) response = await RequestService.Delete(url, history)
    if (response?.hasError) {
      console.error(response.errorMessage)
      setSeverity(ALERT_TYPES.error)
    } else {
      setSeverity(ALERT_TYPES.success)
      setTableConf({ ...tableConf })
    }
    setOpenAlert(true)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => () => {
    if (type === 'Edit') {
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_category_type: params.id_category_type,
      })
    } else {
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType === 'Edit' ? PUT_METHOD : POST_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({ id: params.id, name: params.name, description: params.description, active: !params.active })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Category types
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={rows}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <FormDialog
        title={`${modalType} category`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label="Name"
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label="Description"
          type="text"
          fullWidth
          onChange={handleChange}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title="Deactivate category type"
        okButton="Deactivate"
        loading={loadingInactive}
        open={openDelDialog}
        onClose={() => {
          setDelOpen(false)
          setDeletable(true)
        }}
        onOk={onClickDelConfirmation}
      >
        {DEFAULT_COMFIRM_INACTIVE_MESSAGE} {rowData.name}?
        {!deletable && (
          <Alert style={{ marginTop: 10 }} severity="error">
            {DEFAULT_DEPENDENCIES_ERROR_MESSAGE}
          </Alert>
        )}
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(CategoryType)
