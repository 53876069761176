import ReportsActionTypes from './reports.types'

export const updateSelectedLot = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_LOT,
  payload: value,
})

export const updateSelectedLotName = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_LOT_NAME,
  payload: value,
})

export const updateSelectedVariety = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_VARIETY,
  payload: value,
})

export const updateSelectedVarietyName = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_VARIETY_NAME,
  payload: value,
})

export const updateSelectedGrower = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_GROWER,
  payload: value,
})

export const updateSelectedGrowerName = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_GROWER_NAME,
  payload: value,
})

export const updateSelectedStartDate = (value) => ({
  type: ReportsActionTypes.SET_START_DATE,
  payload: value,
})

export const updateSelectedStartDateISO = (value) => ({
  type: ReportsActionTypes.SET_START_DATE_ISO,
  payload: value,
})

export const updateSelectedEndDate = (value) => ({
  type: ReportsActionTypes.SET_END_DATE,
  payload: value,
})

export const updateSelectedEndDateISO = (value) => ({
  type: ReportsActionTypes.SET_END_DATE_ISO,
  payload: value,
})

export const updateSelectedMachine = (value) => ({
  type: ReportsActionTypes.SET_SELECTED_MACHINE,
  payload: value,
})
