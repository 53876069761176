import { DateTime } from 'luxon'
import { EMAIL_REGEX_PATTERN, OBJECT_ID_ZERO } from '../constants/general'
import RequestService from '../../services/request/request-service'

export const validateEmail = (email) => {
  return EMAIL_REGEX_PATTERN.test(String(email).toLowerCase())
}

export const getManyToManyData = async (queryUrl = '', history = () => {}, allElements = [], filterByFirst = false) => {
  if (
    typeof queryUrl === 'string' &&
    typeof filterByFirst === 'boolean' &&
    typeof history === 'object' &&
    Array.isArray(allElements)
  ) {
    const data = []
    const elements = await RequestService.Get(queryUrl, history)
    if (elements && elements?.data) {
      allElements.forEach((elem) => {
        elements.data.forEach((el) => {
          if (!filterByFirst) {
            if (elem.id === el.first.id) {
              data.push(elem)
            }
          } else if (elem.id === el.second.id) {
            data.push(elem)
          }
        })
      })
    }
    return data
  }
  return null
}

export const buildPayload = (
  id,
  selectedValues,
  additionalData = null,
  useLast = false,
  deleteRelationKey = null,
  getAdditionalData = null
) => {
  let payload = []
  let additionalDataKeyName
  let additionalDataValue
  let mainId = {
    id,
  }

  // Get additional data from additionalData prop and build mainId.
  if (additionalData) {
    // eslint-disable-next-line prefer-destructuring
    additionalDataKeyName = Object.keys(additionalData)[0]
    additionalDataValue = additionalData[additionalDataKeyName]
    mainId = {
      ...mainId,
      [additionalDataKeyName]: additionalDataValue,
    }
  }

  // If none selected, assume relationships need to be removed.
  if (selectedValues.length === 0) {
    // If not useLast, assume first needs to be considered.
    if (!useLast) {
      const payloadItem = {
        first: { ...mainId },
        second: { ...OBJECT_ID_ZERO },
      }
      // If deleteRelationKey has been passed, add it to payload to delete correctly.
      if (deleteRelationKey) {
        payload.push({
          ...payloadItem,
          second: { ...payloadItem.second, [deleteRelationKey]: 0 },
        })
      } else {
        // If not passed, use default payload.
        payload.push(payloadItem)
      }
    } else {
      const payloadItem = {
        first: { ...OBJECT_ID_ZERO },
        second: { ...mainId },
      }
      // If useLast passed, flip object.
      // If deleteRelationKey has been passed, add it to payload to delete correctly.
      if (deleteRelationKey) {
        payload.push({
          ...payloadItem,
          first: { ...payloadItem.first, [deleteRelationKey]: 0 },
        })
      } else {
        // If not passed, use default payload.
        payload.push(payloadItem)
      }
    }
  } else {
    payload = selectedValues.map((item) => {
      let itemId = { id: item.id }
      // If getAdditionalData, get data from item using key as key.
      if (getAdditionalData) {
        itemId = {
          ...itemId,
          [getAdditionalData]: item[getAdditionalData],
        }
      }
      // Determine what needs to be flipped.
      if (!useLast) {
        return {
          first: { ...mainId },
          second: { ...itemId },
        }
      }
      return {
        first: { ...itemId },
        second: { ...mainId },
      }
    })
  }
  return payload
}

export const numberToPercentage = (number) => {
  if (number) return `${number}%`
  return 0
}

export const formatSizesToReadable = (size) => {
  const values = [115, 105, 95, 85]
  if (typeof size === 'number') {
    if (values.indexOf(size) > -1) return size / 10
    return size
  }
  return null
}

export const formatReadableToSizes = (size) => {
  const values = [11.5, 10.5, 9.5, 8.5]
  if (typeof size === 'number') {
    if (values.indexOf(size) > -1) return size * 10
    return size
  }
  return null
}

export const getPercentage = (total, quantity) => {
  if (typeof total === 'number' && typeof quantity === 'number') {
    if (total > 0) return Math.round((quantity * 100) / total)
  }
  return 0
}

export const getPercentageTri = (item, type) => {
  if (typeof item.oversize === 'number' && typeof item.quantity === 'number' && typeof item.oversize === 'number') {
    const total = item.undersize + item.quantity + item.oversize
    if (total > 0) {
      const value = {
        0: item.undersize,
        1: item.quantity,
        2: item.oversize,
      }[type]
      return Math.round((value * 100) / total)
    }
  }
  return 0
}

/**
 * Receive milliseconds and convert to readable format.
 * @param {number} milli
 * @returns {string} - Milliseconds in '{minutes} min. ${seconds} sec.' format.
 */
export const convertMillisecondsToMinutesAndSeconds = (milli) => {
  const averageInMinutes = milli / 1000 / 60
  const minutes = averageInMinutes - (averageInMinutes % 1)
  const seconds = Math.round((averageInMinutes % 1) * 60)
  return `${minutes} min. ${seconds} sec.`
}

export const convertMillisecondsToHoursAndMinutes = (milli) => {
  const averageInMinutes = milli / 1000 / 60
  const minutes = averageInMinutes - (averageInMinutes % 1)
  const seconds = Math.round((averageInMinutes % 1) * 60)
  return `${Math.floor(minutes / 60)} hr. ${minutes % 60} mins.`
}

export const parseSummaryDefectDataToPDFData = (summaryDefectArray) => {
  if (summaryDefectArray.length > 0) {
    return summaryDefectArray.map((defect) => {
      return [
        { text: defect.name, style: 'tableLabel' },
        { text: `${numberToPercentage(defect.percentage)}`, style: 'tableLabelValue' },
      ]
    })
  }
  return [
    [
      { text: '', style: 'tableLabel' },
      { text: '', style: 'tableLabelValue' },
    ],
  ]
}

export const searchFullColumns = (dataSource, textSearch, setRows, columns) => {
  if (document.executeSearch) clearTimeout(document.executeSearch)
  document.executeSearch = setTimeout(() => {
    const result = dataSource.filter((item) => {
      let filterResult = false
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i]
        if (!(column.hide ?? false) && column && column.field && !column.field.toLowerCase().includes('id')) {
          const valueOfField = item[column.field] ?? ''
          if (Object.keys(valueOfField).length > 0 && typeof valueOfField === 'object') {
            const objKeys = Object.keys(valueOfField)
            for (let j = 0; j < objKeys.length; j++) {
              const objField = objKeys[j]
              const valueOfObj = valueOfField[objField] ?? ''
              filterResult = valueOfObj.toString().toLowerCase().includes(textSearch.toLowerCase())
              if (filterResult) {
                break
              }
            }
          } else {
            filterResult = valueOfField.toString().toLowerCase().includes(textSearch.toLowerCase())
          }
          if (filterResult) {
            break
          }
        }
      }
      return filterResult
    })
    setRows(result)
  }, 500)
}

export const convertFormatDateTime = (dateISO, toLocal = false) => {
  if (typeof dateISO === 'string') {
    let dateTime = DateTime.fromISO(dateISO).toUTC()
    if (toLocal) {
      const minuteTimeZone = new Date().getTimezoneOffset()
      dateTime = dateTime.plus(minuteTimeZone * -1 * 60 * 1000)
    }
    return dateTime.toFormat('MM/dd/yyyy hh:mm a').toLowerCase()
  }
  return dateISO
}
