import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'

import useStyles from './dashboard-card.styles'

const DoubleValue = ({ value1, value2 }) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.doubleValueContainer}>
        <Typography className={`${classes.value} ${classes.value1}`} color="primary" variant="h4">
          {value1}%
        </Typography>
        <Typography className={classes.value} color="primary" variant="h4">
          {value2}%
        </Typography>
      </div>
      <div className={classes.defectsContainer}>
        <Typography style={{ fontSize: 10, color: '#2a3858' }}>Minor</Typography>
        <Typography style={{ fontSize: 10, color: '#2a3858' }}>Major</Typography>
      </div>
    </>
  )
}

const DashboardCard = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <div className={classes.container}>
          <Icon className={classes.icon}>{props.icon}</Icon>
          <Typography variant="subtitle1" color="primary">
            {props.title}
          </Typography>
        </div>
        <div className={classes.valuesContainer}>
          {props.data.map((item) => (
            <div key={item.name}>
              {Array.isArray(item.value) ? (
                <DoubleValue value1={item.value[0]} value2={item.value[1]} />
              ) : (
                <Typography color="primary" variant="h4" className={classes.value}>
                  {item.value}%
                </Typography>
              )}

              {item.subname && (
                <Typography color="primary" variant="caption">
                  {item.subname}
                </Typography>
              )}
              <Typography style={{ marginTop: item.value.length > 1 ? 16 : 18 }} color="primary" variant="body2">
                {item.name}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default DashboardCard
