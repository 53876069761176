import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import FormDialog from '../../components/form-dialog/form-dialog.component'
import ConfirmDialog from '../../components/confirm-dialog/confirm-dialog.component'
import ActionButtons from '../../components/action-buttons/action-buttons.component'
import Alert from '../../components/alert/alert.component'
import TableContainer from '../../components/table-container/table-container'
import MultipleSelect from '../../components/multi-select/multi-select'
import ActiveToggle from '../../components/active-toggle/active-toggle.component'
import SearchableInput from '../../components/searchable-input/searchable-input.component'
import ManyToManyInput from '../../components/many-many-input/many-many-input.component'

import AppInsightsTrackingContext from '../../context/app-insights-tracking/AppInsightsTrackingContext'
import RequestService from '../../services/request/request-service'

import { buildPayload, getManyToManyData } from '../../shared/utils/utils'

import {
  ACTIONS_COLUMN,
  BASE_TABLE_COLUMNS,
  DEFAULT_ROW_DATA,
  DEFAULT_TABLE_CONFIGURATION,
} from '../../shared/constants/table'
import { DEFAULT_COMFIRM_INACTIVE_MESSAGE } from '../../shared/constants/messages'
import { POST_METHOD, PUT_METHOD } from '../../shared/constants/requests'
import { ALERT_TYPES, INCLUDE_INACTIVE_FLAG } from '../../shared/constants/general'
import { SIZE_QUERY, UNIT_QUERY, UNIT_TYPE_QUERY, UNITS_SIZES_QUERY } from '../../shared/constants/queries'
import { LOG_PAGE_LOADS } from '../../shared/constants/logging'
import useHttpInactive from '../../shared/hooks/useHttpInactive'

const Unit = ({ userEmail }) => {
  const history = useHistory()
  const { trackEvent } = useContext(AppInsightsTrackingContext)

  const [openDialog, setOpen] = useState(false)
  const [openDelDialog, setDelOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSizes, setOpenSizes] = useState(false)
  const [modalType, setModalType] = useState('Add new')
  const [rowData, setRowData] = useState(DEFAULT_ROW_DATA)
  const [rows, setRows] = useState([])
  const [tableConf, setTableConf] = useState(DEFAULT_TABLE_CONFIGURATION)
  const [openAlert, setOpenAlert] = useState(false)
  const [severity, setSeverity] = useState(ALERT_TYPES.success)
  const [types, setTypes] = useState([])
  const [filterValue, setFilterValue] = useState(0)
  const [showActive, setShowActive] = useState(true)
  const [selectedSizes, setSelectedSizes] = useState([])
  const [sizes, setSizes] = useState([])
  const { executeInactive, loadingInactive } = useHttpInactive(UNIT_QUERY)
  // columns definition for table
  const columns = [
    ...BASE_TABLE_COLUMNS,
    {
      field: 'unit',
      headerName: 'Sizes',
      flex: 1,
      minWidth: 115,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => {
            setRowData({ id: params.row.id, id_unit_type: params.row.id_unit_type })
            setOpenSizes(true)
          }}
          style={{ textTransform: 'none' }}
        >
          See Sizes
        </Button>
      ),
    },
    {
      ...ACTIONS_COLUMN,
      renderCell: (params) => ActionButtons(params.row, handleClickOpen, handleClickDelOpen),
    },
  ]

  // load unit types
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true)
        let url = `${UNIT_TYPE_QUERY}?startRowIndex=0&pageSize=10000`
        let response = await RequestService.Get(url, history)
        setTypes(response.data)

        url = `${SIZE_QUERY}?startRowIndex=0&pageSize=10000&removeOverUnderSizeOptions=false`
        response = await RequestService.Get(url, history)
        setSizes(response.data)
      } catch (error) {
        console.error(error)
        setSeverity(ALERT_TYPES.error)
        setOpenAlert(true)
      } finally {
        setLoading(false)
      }
    }
    loadData()
    trackEvent(LOG_PAGE_LOADS.LOAD_UNIT_PAGE, { email: userEmail })
  }, [])

  // load data
  useEffect(() => {
    loadData()
  }, [tableConf, filterValue, showActive])

  const onError = (errorMessage) => {
    console.error(errorMessage)
    setOpenAlert(true)
    setSeverity(ALERT_TYPES.error)
    setLoading(false)
  }

  const loadData = async () => {
    try {
      setLoading(true)
      setRows([])
      const filterQuery = filterValue === 0 ? '' : `id_unit_type=${filterValue}&`
      const url = `${UNIT_QUERY}?${filterQuery}${!showActive ? INCLUDE_INACTIVE_FLAG : ''}&startRowIndex=${
        tableConf.page - 1
      }&pageSize=10000`
      const response = await RequestService.Get(url, history)
      setRows(response.data)
    } catch (error) {
      console.error(error)
      setSeverity(ALERT_TYPES.error)
      setOpenAlert(true)
    } finally {
      setLoading(false)
    }
  }

  // insert and edit
  const insertEditData = async (method, data) => {
    setLoading(true)
    setOpen(false)

    let url = `${UNIT_QUERY}`
    let response
    if (method === POST_METHOD) response = await RequestService.Post(url, history, data)
    if (method === PUT_METHOD) response = await RequestService.Put(url, history, data)

    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    let id
    let id_unit_type
    if (method === PUT_METHOD) {
      id = response.data.id
      id_unit_type = response.data.id_unit_type
    }
    if (method === POST_METHOD) {
      id = response.data.id
      id_unit_type = response.data.id_unit_type
    }

    const unitsSizesPayload = buildPayload(id, selectedSizes, { id_unit_type })
    url = `${UNITS_SIZES_QUERY}`
    response = await RequestService.Put(url, history, unitsSizesPayload, { isBlankInstance: true })
    if (response?.hasError) {
      onError(response.errorMessage)
      return
    }

    setTableConf({ ...tableConf })
    setSeverity(ALERT_TYPES.success)
    setSelectedSizes([])
    setOpenAlert(true)
    setLoading(false)
  }

  // Form Dialog handler
  const handleClickOpen = (type, params) => async () => {
    setLoading(true)
    if (type === 'Edit') {
      const unitsSizes = await getManyToManyData(`${UNITS_SIZES_QUERY}?id=${params.id}`, history, sizes, true)
      setSelectedSizes(unitsSizes)
      setRowData({
        id: params.id,
        name: params.name,
        description: params.description,
        active: params.active,
        id_unit_type: params.id_unit_type,
      })
    } else {
      setSelectedSizes([])
      setRowData(DEFAULT_ROW_DATA)
    }
    setModalType(type)
    setOpen(true)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = { ...rowData }
    const method = modalType !== 'Edit' ? POST_METHOD : PUT_METHOD
    insertEditData(method, data)
  }

  // Confirm Dialog handlers
  const handleClickDelOpen = (params) => () => {
    setRowData({
      id: params.id,
      name: params.name,
      description: params.description,
      active: !params.active,
      id_unit_type: params.id_unit_type,
    })
    setDelOpen(true)
  }

  const onClickDelConfirmation = async () => {
    const { success } = await executeInactive(rowData.id)
    if (success) {
      setDelOpen(false)
      loadData()
    }
  }

  // Form on change
  const handleChange = (event) => {
    const { name, value } = event.target
    setRowData({
      ...rowData,
      [name]: value,
    })
  }

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpenAlert(false)
  }

  return (
    <div>
      <Box pt={5} pb={3}>
        <Typography component="h1" variant="h5" color="primary">
          Units
        </Typography>
      </Box>
      <TableContainer
        haveSearchInput="true"
        filterValues={types}
        filterValue={filterValue}
        filter="Unit Type"
        filterChange={(event) => {
          setFilterValue(event.target.value)
        }}
        handleClickOpen={() => handleClickOpen('Add new', null)}
        columns={columns}
        rows={rows}
        tableConfiguration={tableConf}
        setTableConf={setTableConf}
        onToggleClick={() => setShowActive(!showActive)}
        showActive={showActive}
        loading={loading}
      />
      <MultipleSelect
        open={openSizes}
        onClose={() => {
          setOpenSizes(false)
        }}
        title="Sizes"
        value={sizes}
        getRelationUrl="unitssizes"
        additionalData={{
          id_unit_type: rowData.id_unit_type,
        }}
        mainId={rowData.id}
        lefttoright
      />
      <FormDialog
        title={`${modalType} unit`}
        open={openDialog}
        onClose={() => {
          setOpen(false)
        }}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <TextField
          defaultValue={rowData.name}
          autoFocus
          variant="outlined"
          margin="normal"
          name="name"
          label="Name"
          type="text"
          fullWidth
          onChange={handleChange}
          required
        />
        <TextField
          defaultValue={rowData.description}
          variant="outlined"
          margin="normal"
          name="description"
          label="Description"
          type="text"
          fullWidth
          onChange={handleChange}
        />
        <SearchableInput
          value={types.find((gr) => gr.id === rowData.id_unit_type) ?? 0}
          options={types}
          onChange={(event) => handleChange(event)}
          label="Unit type"
          id="id_unit_type"
          name="id_unit_type"
        />
        <ManyToManyInput
          inputLabel="Sizes"
          name="id_size"
          selectedValues={selectedSizes}
          allValues={sizes}
          setSelectedValues={setSelectedSizes}
        />
        {modalType === 'Edit' && (
          <ActiveToggle value={rowData.active} onToggle={() => setRowData({ ...rowData, active: !rowData.active })} />
        )}
      </FormDialog>
      <ConfirmDialog
        title="Deactivate unit"
        okButton="Deactivate"
        open={openDelDialog}
        loading={loadingInactive}
        onClose={() => {
          setDelOpen(false)
        }}
        onOk={onClickDelConfirmation}
      >
        {DEFAULT_COMFIRM_INACTIVE_MESSAGE} {rowData.name}?
      </ConfirmDialog>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userEmail: state.user.email,
})

export default connect(mapStateToProps, null)(Unit)
